import React, { useEffect, useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { FaArchive, FaHome } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { isSubscribedAccountant } from "../../utils";

const Sops = () => {

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <Container fluid>
            <Row className="mx-3">
                <Slider {...settings}>
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/sops/sales" exact>
                                    <FaHome className="me-1" /> Sales
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>                    </div>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/sops/finance">
                                    <TbReportSearch className="me-1" /> Finance
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">


                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/sops/marketing">
                                    <TbReportSearch className="me-1" /> Marketing
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                  
                
                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/sops/operations">
                                    <FaArchive className="me-1" /> Operations
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                    <div className="slider-item">

                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/sops/informationtechnology">
                                    <TbReportSearch className="me-1" /> Information Technology
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="slider-item">


                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/sops/humanresources">
                                    <FaArchive className="me-1" /> Human Resources
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                </Slider>
            </Row>
        </Container>
    );
};

export default Sops;
