import React, { useEffect, useState } from "react";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const OneOnOne = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Accountabilitypagename: "",
        Seatsingular: "",
        Seatsplural: "",
        SeatHellersingular: "",
        SeutHoldersPlural: "",
        Responsibilities: "",
        Visionary: "",
        Vitegrator: "",
        Masting: "",
        Manager: "",
        Analyze: "",
        GWC: "",
        Gets: "",
        Wants: "",
        Capacity: "",
        LMA: "",
        Leadership: "",
        Management: "",
    });


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
          const response = await axios.post(
            `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_1_On_1}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
    
          if (response.data.status === 'success') {
     
            const data = response.data.data[0];
            setFormData({
                Accountabilitypagename: data.page_name || "",
                Seatsingular: data.conversation_jungutar || "",
                Seatsplural: data.curversations_plural || "",
                SeatHellersingular: data.quarterly_conversation_singular || "",
                SeutHoldersPlural: data.quarterly_conversations_plural || "",
                Responsibilities: data.annual_review_ungdar || "",
                Visionary: data.annual_reviews_plural || "",
                vitegrator: data.manage || "",
                Manager: data.manager || "",
                Masting: data.masting || "",
                Analyze: data.people_analyze || "",
                GWC: data.GWC_max_length_4 || "",
                Gets: data.gets_it || "",
                Wants: data.wants_it || "",
                Capacity: data.capacity_to_do_it || "",
                LMA: data.LMA_max_Iength_4 || "",
                Leadership: data.assessment || "",
                Management: data.management_assessment || "",
             
            });
          } else {
            toast.error(response.data.message);
                }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.Accountabilitypagename);
        formDataToSend.append('conversation_jungutar', formData.Seatsingular);
        formDataToSend.append('curversations_plural', formData.Seatsplural);
        formDataToSend.append('quarterly_conversation_singular', formData.SeatHellersingular);
        formDataToSend.append('quarterly_conversations_plural', formData.SeutHoldersPlural);
        formDataToSend.append('annual_review_ungdar', formData.Responsibilities);
        formDataToSend.append('annual_reviews_plural', formData.Visionary);
        formDataToSend.append('manage', formData.Vitegrator);
        formDataToSend.append('manager', formData.Manager);
        formDataToSend.append('masting', formData.Masting);
        formDataToSend.append('people_analyze', formData.Analyze);
        formDataToSend.append('GWC_max_length_4', formData.GWC);
        formDataToSend.append('gets_it', formData.Gets);
        formDataToSend.append('wants_it', formData.Wants);
        formDataToSend.append('capacity_to_do_it', formData.Capacity);
        formDataToSend.append('LMA_max_Iength_4', formData.LMA);
        formDataToSend.append('assessment', formData.Leadership);
        formDataToSend.append('management_assessment', formData.Management);

        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_1_On_1}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
      
          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
            console.error("Error during task submission:", error);

        }
    };
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="fa fa-send-o mx-2"></i>1-on-1</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Page Name"
                                    name="Accountabilitypagename"
                                    value={formData.Accountabilitypagename}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Conversation Jungutar</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Conversation Jungutar"
                                    name="Seatsingular"
                                    value={formData.Seatsingular}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Curversations (plural)</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Curversations (plural)"
                                    name="Seatsplural"
                                    value={formData.Seatsplural}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Quarterly Conversation (singular)</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Quarterly Conversation (singular)"
                                    name="SeatHellersingular"
                                    value={formData.SeatHellersingular}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Quarterly Conversations (plural)</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Quarterly Conversations (plural)"
                                    name="SeutHoldersPlural"
                                    value={formData.SeutHoldersPlural}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Annual Review (ungdar)</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Annual Review (ungdar)"
                                    name="Responsibilities"
                                    value={formData.Responsibilities}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Annual Reviews (plural)
                                </b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Annual Reviews (plural)                                        "
                                    name="Visionary"
                                    value={formData.Visionary}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Manage</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Manage"
                                    name="Vitegrator"
                                    value={formData.Vitegrator}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Manager</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Manager"
                                    name="Manager"
                                    value={formData.Manager}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Masting</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Masting"
                                    name="Masting"
                                    value={formData.Masting}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>People Analyze</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter People Analyze"
                                    name="Analyze"
                                    value={formData.Analyze}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>GWC (Max length 4)</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter GWC (Max length 4)"
                                    name="GWC"
                                    value={formData.GWC}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Gets it</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Gets it"
                                    name="Gets"
                                    value={formData.Gets}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Wants it</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Wants it"
                                    name="Wants"
                                    value={formData.Wants}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Capacity to do it</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Capacity to do it"
                                    name="Capacity"
                                    value={formData.Capacity}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>LMA (Max Iength: 4)</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter LMA (Max Iength: 4)"
                                    name="LMA"
                                    value={formData.LMA}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Leadership Assessment</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Leadership Assessment"
                                    name="Leadership"
                                    value={formData.Leadership}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Management Assessment</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Management Assessment"
                                    name="Management"
                                    value={formData.Management}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default OneOnOne;