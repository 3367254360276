import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import API from "../../../utils";
import { barChartOptions } from './monthlychartdata';
import MypuffLoader from "../../common/MypuffLoader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AccountsPayable = () => {
    const uid = localStorage.getItem("user_id");
    const [loading, setLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [years] = useState([2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015]);
    const [scorecardsData, setScorecardsData] = useState({
        labels: [],
        datasets: [],
    });

    const monthNames = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const fetchScorecards = async (year) => {
        setLoading(true);
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('year', year);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_accounts_payble}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const scorecardData = response.data.data;
                const labels = Object.values(scorecardData).map(item => monthNames[item.month_id - 1]);
                const data = Object.values(scorecardData).map(item => item.month_count ? parseInt(item.month_count, 10) : 0);

                const formattedChartData = {
                    labels: labels,
                    datasets: [
                        {
                            label: `Accounts Payable for ${year}`,
                            data: data,
                            backgroundColor: "#007bff",
                            borderColor: "#007bff",
                            borderWidth: 2,
                        },
                    ],
                };

                setScorecardsData(formattedChartData);
            } else {
                setScorecardsData({ labels: [], datasets: [] });
            }
        } catch (error) {
            setScorecardsData({ labels: [], datasets: [] });
        } finally {
            setLoading(false);
        }
    };

    const handleYearChange = (e) => {
        const selectedYear = e.target.value;
        setSelectedYear(selectedYear);
        fetchScorecards(selectedYear);
    };

    useEffect(() => {
        fetchScorecards(selectedYear);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <h5>Accounts Payable</h5>
                                    </div>
                                    <div>
                                        <select
                                            className="form-control"
                                            value={selectedYear}
                                            onChange={handleYearChange}
                                        >
                                            {years.map(year => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body chart-block">
                                {loading ? (
                                    <div className="d-flex justify-content-center my-3">
                                        <MypuffLoader active={loading} />
                                    </div>
                                ) : scorecardsData.datasets.length === 0 ? (
                                    <div className="text-center my-3">
                                        <p>No data available for the selected year.</p>
                                    </div>
                                ) : (
                                    <Bar data={scorecardsData} options={barChartOptions} width={778} height={300} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountsPayable;
