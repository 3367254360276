import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function OperationsAssessment() {
    const [selectedOption, setSelectedOption] = useState('');
    const [cultureOption, setCultureOption] = useState('');
    const [planningOption, setPlanningOption] = useState('');
    const [performanceOption, setPerformanceOption] = useState('');
    const [feedbackOption, setFeedbackOption] = useState('');
    const [valuesOption, setValuesOption] = useState('');
    const [innovationOption, setInnovationOption] = useState('');
    const [embeddingOption, setEmbeddingOption] = useState('');
    const [leadershipOption, setLeadershipOption] = useState('');
    const [rewardsOption, setRewardsOption] = useState('');
    const [rewardsStrategyOption, setRewardsStrategyOption] = useState('');
    const [TechnologyDigitalContextPlatform, setTechnologyDigitalContextPlatform] = useState('');
    const [TechnologyDigitalContextPace, setTechnologyDigitalContextPace] = useState('');
    // const [rewardsStrateTechnologyDigital, setrewardsStrateTechnologyDigital] = useState('');
    const uid = localStorage.getItem("user_id");


    const updateTask = async (formDataToSend) => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_operation_assesment}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: API.AUTHORIZATION_TOKEN,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while updating data.");
        }
    };


    const handleChange = (setter) => (event) => {
        setter(event.target.value);
    };

    useEffect(() => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('Capability_Strategy', selectedOption);
        formDataToSend.append('Capability_Culture', cultureOption);
        formDataToSend.append('Capability_Planning', planningOption);
        formDataToSend.append('Performance_Management', performanceOption);
        formDataToSend.append('Performance_Management_Feedback', feedbackOption);
        formDataToSend.append('Performance_Management_Values', valuesOption);
        formDataToSend.append('Innovation', innovationOption);
        formDataToSend.append('Embedding_Innovation', embeddingOption);
        formDataToSend.append('Rewards', rewardsOption);
        formDataToSend.append('Rewards_Strategy', rewardsStrategyOption);
        formDataToSend.append('Leadership', TechnologyDigitalContextPlatform);
        formDataToSend.append('Rewards_Strategy', TechnologyDigitalContextPace);

        // Only update if any field has a value
        if (selectedOption || cultureOption || planningOption || performanceOption || feedbackOption || valuesOption || innovationOption || embeddingOption || leadershipOption || rewardsOption || rewardsStrategyOption || TechnologyDigitalContextPlatform || TechnologyDigitalContextPace) {
            updateTask(formDataToSend);
        }
    }, [selectedOption, cultureOption, planningOption, performanceOption, feedbackOption, valuesOption, innovationOption, embeddingOption, leadershipOption, rewardsOption, rewardsStrategyOption, TechnologyDigitalContextPace, TechnologyDigitalContextPlatform]);


    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_operation}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // const data = response.data.data || {};

                // setCheckedValue({
                //     people_assesment: data.people_assesment 
                // });
                const jsonData = response.data.data.operation_assesment;

                // Decode the JSON string into an object
                if (jsonData) {
                    let data;
                    try {
                        data = JSON.parse(jsonData);

                        // Update the state with the decoded values
                        setSelectedOption(data.Capability_Strategy);
                        setCultureOption(data.Capability_Culture);
                        setPlanningOption(data.Capability_Planning);
                        setPerformanceOption(data.Performance_Management);
                        setFeedbackOption(data.Performance_Management_Feedback);
                        setValuesOption(data.Performance_Management_Values);
                        setInnovationOption(data.Innovation);
                        setEmbeddingOption(data.Embedding_Innovation);
                        setRewardsOption(data.Rewards);
                        setRewardsStrategyOption(data.Rewards_Strategy);
                        setTechnologyDigitalContextPlatform(data.Rewards_Strategy);
                        setTechnologyDigitalContextPace(data.Rewards_Strategy);
                        // setrewardsStrateTechnologyDigital(data.Rewards_Strategy );

                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                }
            }
        } catch (error) {
            console.error('Fetch meeting types error', error);
        }
    };

    const weights = {
        Leading: 7.7,
        Established: 5.13,
        Developing: 2.56,
    };

    const calculatePercentage = () => {
        const totalQuestions = 13; // Total number of questions
        let totalScore = 0;

        const options = [
            selectedOption,
            cultureOption,
            planningOption,
            performanceOption,
            feedbackOption,
            valuesOption,
            innovationOption,
            embeddingOption,
            leadershipOption,
            rewardsOption,
            rewardsStrategyOption
        ];

        options.forEach(option => {
            if (option in weights) {
                totalScore += weights[option];
            }
        });

        const percentage = (totalScore / (totalQuestions * Math.max(...Object.values(weights)))) * 100;
        return percentage.toFixed(2);

    };

    const percentage = calculatePercentage();

    useEffect(() => {
        // Calculate and store the percentage
        const percentage = calculatePercentage();

        // Set the percentage in local storage
        localStorage.setItem('opretionCompletionPercentage', percentage);

    }, [
        selectedOption,
        cultureOption,
        planningOption,
        performanceOption,
        feedbackOption,
        valuesOption,
        innovationOption,
        embeddingOption,
        leadershipOption,
        rewardsOption,
        rewardsStrategyOption,
    ]); // Dependencies to recalculate when any option changes


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="col-sm-12">
            <div className="card mt-4">
                <div className="card-header">
                    <div className='justify-content-between align-items-center d-flex'>
                        <div className=' align-items-center d-flex'>
                            <h5>Operations Assessment</h5>
                        </div>

                        <div className='ms-3 align-items-center d-flex ms-3'>
                            <div className='m-r-15'>
                                <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                            </div>
                            <CircularProgressbar className='assessmentprogress'
                                value={percentage}
                                text={`${Math.round(percentage)}%`}
                                styles={{
                                    path: {
                                        stroke: 'green', // Change the stroke color to black
                                    },
                                    text: {
                                        fill: 'black', // Change the text color to black
                                        fontSize: '16px', // Optional: Adjust font size
                                    },
                                    trail: {
                                        stroke: '#d6d6d6', // Optional: Change the trail color (if you want)
                                    },
                                }}
                            />

                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Capability - Strategy</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={selectedOption === 'Leading'}
                                        onChange={handleChange(setSelectedOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    • The business is strongly purpose-driven and focused on long-term value creation.
                                </p>
                                <p className="textnots-text">
                                    • The Operations Plan plays a fundamental role in strategy and business performance by focusing on high performing teams, an agile workforce, diversity, and technology enablement.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={selectedOption === 'Established'}
                                        onChange={handleChange(setSelectedOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    • Human resources are focused on meeting future deadlines and business objectives.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={selectedOption === 'Developing'}
                                        onChange={handleChange(setSelectedOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    • Human resources are focused on meeting current deadlines and business objectives.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Capability - Strategy</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={cultureOption === 'Leading'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    • The business is strongly purpose-driven and focused on long-term value creation.
                                </p>
                                <p className="textnots-text">
                                    • The Operations Plan plays a fundamental role in strategy and business performance by focusing on high performing teams, an agile workforce, diversity, and technology enablement.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={cultureOption === 'Established'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    • Human resources are focused on meeting future deadlines and business objectives.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={cultureOption === 'Developing'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    • Human resources are focused on meeting current deadlines and business objectives.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Capability - Culture</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        // checked={planningOption === 'Leading'}
                                        // onChange={handleChange(setPlanningOption)}
                                        checked={cultureOption === 'Leading'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Diversity, inclusiveness, agility and teamwork are embedded in the culture of the business and essential to achieving business goals.                                </p>
                                <p className="textnots-text">
                                    •	Diversity, inclusiveness, agility and teamwork are embedded in the culture of the business and essential to achieving business goals.                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        // checked={planningOption === 'Established'}
                                        // onChange={handleChange(setPlanningOption)}
                                        checked={cultureOption === 'Established'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Management and processes drive innovation and strong teamwork within and across the business.                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        // checked={planningOption === 'Developing'}
                                        // onChange={handleChange(setPlanningOption)}
                                        checked={cultureOption === 'Developing'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The organizational and behavioral culture required to achieve business objectives is clearly understood.                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Capability - Planning </strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        // checked={performanceOption === 'Leading'}
                                        // onChange={handleChange(setPerformanceOption)}
                                        checked={planningOption === 'Leading'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Human resources and workforce planning is highly forward-looking, proactive and embraces technology and robotics to optimize productivity.
                                </p>
                                <p className="textnots-text">
                                    •	The future of work trends are actively being monitored and strategies are designed and adapted to meet the changing needs and to seize opportunities they create for the business.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"

                                        checked={planningOption === 'Leading'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Skill gaps are minimized through a combination of outsourcing, offshoring and flexible working.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"

                                        checked={planningOption === 'Developing'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •   Individuals are clear about their personal roles and objectives.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Capability - Planning </strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        // checked={feedbackOption === 'Leading'}
                                        // onChange={handleChange(setFeedbackOption)}
                                        checked={performanceOption === 'Leading'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Human resources and workforce planning is highly forward-looking, proactive and embraces technology and robotics to optimize productivity.
                                </p>
                                <p className="textnots-text">
                                    •	The future of work trends are actively being monitored and strategies are designed and adapted to meet the changing needs and to seize opportunities they create for the business.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        // checked={feedbackOption === 'Established'}
                                        // onChange={handleChange(setFeedbackOption)}
                                        checked={performanceOption === 'Established'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Skill gaps are minimized through a combination of outsourcing, offshoring and flexible working.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"

                                        checked={performanceOption === 'Developing'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •   Individuals are clear about their personal roles and objectives.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Performance Management </strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        // checked={valuesOption === 'Leading'}
                                        // onChange={handleChange(setValuesOption)}
                                        checked={feedbackOption === 'Leading'}
                                        onChange={handleChange(setFeedbackOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	High performance is a fundamental value.
                                </p>
                                <p className="textnots-text">
                                    •	The business uses analytics to measure activity and outcomes of both the team and the individual.
                                </p>
                                <p className="textnots-text">
                                    •	Performance is embedded into the organization's culture and tied to reward, development and career opportunities.                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"


                                        checked={feedbackOption === 'Established'}
                                        onChange={handleChange(setFeedbackOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is a framework for aligning performance management with business strategy.
                                </p>
                                <p className="textnots-text">
                                    •   Cascading business objectives and goals are linked to rewards.                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={feedbackOption === 'Developing'}
                                        onChange={handleChange(setFeedbackOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Performance is managed on an individual basis.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Performance Management - Feedback</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        // checked={innovationOption === 'Leading'}
                                        // onChange={handleChange(setInnovationOption)}
                                        checked={valuesOption === 'Leading'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Continuous feedback is provided throughout the year on performance, rewards and succession planning.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        // checked={innovationOption === 'Established'}
                                        // onChange={handleChange(setInnovationOption)}
                                        checked={valuesOption === 'Established'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Feedback is provided throughout the year through a structured review framework.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        // checked={innovationOption === 'Developing'}
                                        // onChange={handleChange(setInnovationOption)}
                                        checked={valuesOption === 'Developing'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Managers provide ad hoc feedback to employees.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Performance Management - Values</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        // checked={embeddingOption === 'Leading'}
                                        // onChange={handleChange(setEmbeddingOption)}
                                        checked={innovationOption === 'Leading'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Values are understood by all. Behaviors are aligned to performance and linked to rewards.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        // checked={embeddingOption === 'Established'}
                                        // onChange={handleChange(setEmbeddingOption)}
                                        checked={innovationOption === 'Established'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Values are embedded in performance management.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={innovationOption === 'Developing'}
                                        onChange={handleChange(setInnovationOption)}
                                    // checked={embeddingOption === 'Developing'}
                                    // onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Values exist but lack a clear link to performance management.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Innovation</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={embeddingOption === 'Leading'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •  Innovation is tied to performance-managed rewards and succession; leaders are champions of innovation.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        // checked={leadershipOption === 'Established'}
                                        // onChange={handleChange(setLeadershipOption)}
                                        checked={embeddingOption === 'Established'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business grows by exploiting the full market potential of existing products or services. It capitalizes on previous innovations.
                                </p>

                                <p className="textnots-text">
                                    •	A focus on commercialization and operations drives significant changes in the organization, its culture and its workforce.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"

                                        // checked={leadershipOption === 'Developing'}
                                        // onChange={handleChange(setLeadershipOption)}
                                        checked={embeddingOption === 'Developing'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business is oriented toward innovation and looks to disrupt established market dynamics.                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Innovation - Embedding Innovation</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"

                                        // checked={rewardsOption === 'Leading'}
                                        // onChange={handleChange(setRewardsOption)}
                                        checked={leadershipOption === 'Leading'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Entrepreneurship is embedded in the business' culture (safe to take risks and celebrate lessons from failure).
                                </p>
                                <p className="textnots-text">
                                    •	The business gives employees time to explore novel concepts or ideas.
                                </p>
                                <p className="textnots-text">
                                    •	The company may have groups or pods devoted to innovation.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"

                                        // checked={rewardsOption === 'Established'}
                                        // onChange={handleChange(setRewardsOption)}
                                        checked={leadershipOption === 'Established'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The organization develops innovation as a competency. It selects/rewards employees who demonstrate innovation.                                </p>



                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"


                                        checked={leadershipOption === 'Developing'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business attracts talent to innovate and disrupt the market dynamics.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Leadership </strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={rewardsOption === 'Leading'}
                                        onChange={handleChange(setRewardsOption)}
                                    // checked={rewardsStrategyOption === 'Leading'}
                                    // onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Leadership is passionate and inspiring, sets a clear purpose and vision and leads by execution.
                                </p>
                                <p className="textnots-text">
                                    •	Leaders are perceived as Operations leaders and not operational managers.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={rewardsOption === 'Established'}
                                        onChange={handleChange(setRewardsOption)}
                                    // checked={rewardsStrategyOption === 'Established'}
                                    // onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The company clearly understands the leadership competencies, experience and skillsets required to take the business to a next level of growth.
                                </p>
                                <p className="textnots-text">
                                    •	The company pro-actively attracts, selects, develops and retains future leaders.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={rewardsOption === 'Developing'}
                                        onChange={handleChange(setRewardsOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Leadership understands and is passionate about the business strategy and motivates Operations to achieve performance targets
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Rewards</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={rewardsStrategyOption === 'Leading'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    // checked={TechnologyDigitalContextPlatform === 'Established'}
                                    // onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The focus is on total flexible rewards, not just compensation, with a strong understanding of the total cost and value of short and long-term incentive programs.
                                </p>
                                <p className="textnots-text">
                                    •	Behavior toward risk management is embedded in rewards structures.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={rewardsStrategyOption === 'Established'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    // checked={TechnologyDigitalContextPlatform === 'Established'}
                                    // onChange={handleChange(setTechnologyDigitalContextPlatform)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is visible evidence of links between high performance, rewards and the achievement of business objectives.                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={rewardsStrategyOption === 'Developing'}
                                        onChange={handleChange(setRewardsStrategyOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Bonuses are used to reward high performance.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Operations - Rewards - Strategy</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={TechnologyDigitalContextPlatform === 'Leading'}
                                        onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    // checked={TechnologyDigitalContextPace === 'Leading'}
                                    // onChange={handleChange(setTechnologyDigitalContextPace)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Behaviors, as well as outcomes, are rewarded for both the team and the individual.
                                </p>
                                <p className="textnots-text">
                                    •	A strong employer brand is used to attract, motivate and retain high performers and teams.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={TechnologyDigitalContextPlatform === 'Established'}
                                        onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    // checked={TechnologyDigitalContextPace === 'Established'}
                                    // onChange={handleChange(setTechnologyDigitalContextPace)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There are various incentives and total rewards programs segmented to specific employee populations.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={TechnologyDigitalContextPlatform === 'Developing'}
                                        onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    // checked={TechnologyDigitalContextPace === 'Developing'}
                                    // onChange={handleChange(setTechnologyDigitalContextPace)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Rewards are provided to attract and retain individuals and to meet business objectives.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OperationsAssessment;
