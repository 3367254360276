import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import API from '../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

const MBTI_DESCRIPTIONS = {
    ISTJ: 'Quiet, serious, earn success by being thorough and dependable. Practical, matter-of-fact, realistic, and responsible. Decide logically what should be done and work toward it steadily, regardless of distractions. Take pleasure in making everything orderly and organized—their work, their home, their life. Value traditions and loyalty.',
    ISFJ: 'Quiet, friendly, responsible, and conscientious. Committed and steady in meeting their obligations. Thorough, painstaking, and accurate. Loyal, considerate, notice and remember specifics about people who are important to them, concerned with how others feel. Strive to create an orderly and harmonious environment at work and at home.',
    INFJ: 'Seek meaning and connection in ideas, relationships, and material possessions. Want to understand what motivates people and are insightful about others. Conscientious and committed to their firm values. Develop a clear vision about how best to serve the common good. Organized and decisive in implementing their vision.',
    INTJ: 'Have original minds and great drive for implementing their ideas and achieving their goals. Quickly see patterns in external events and develop long-range explanatory perspectives. When committed, organize a job and carry it through. Skeptical and independent, have high standards of competence and performance—for themselves and others.',
    ISTP: 'Tolerant and flexible, quiet observers until a problem appears, then act quickly to find workable solutions. Analyze what makes things work and readily get through large amounts of data to isolate the core of practical problems. Interested in cause and effect, organize facts using logical principles, value efficiency.',
    ISFP: 'Quiet, friendly, sensitive, and kind. Enjoy the present moment, what\'s going on around them. Like to have their own space and to work within their own time frame. Loyal and committed to their values and to people who are important to them. Dislike disagreements and conflicts; don\'t force their opinions or values on others.',
    INFP: 'Idealistic, loyal to their values and to people who are important to them. Want to live a life that is congruent with their values. Curious, quick to see possibilities, can be catalysts for implementing ideas. Seek to understand people and to help them fulfill their potential. Adaptable, flexible, and accepting unless a value is threatened.',
    INTP: 'Seek to develop logical explanations for everything that interests them. Theoretical and abstract, interested more in ideas than in social interaction. Quiet, contained, flexible, and adaptable. Have unusual ability to focus in depth to solve problems in their area of interest. Skeptical, sometimes critical, always analytical.',
    ESTP: 'Flexible and tolerant, take a pragmatic approach focused on immediate results. Bored by theories and conceptual explanations; want to act energetically to solve the problem. Focus on the here and now, spontaneous, enjoy each moment they can be active with others. Enjoy material comforts and style. Learn best through doing.',
    ESFP: 'Outgoing, friendly, and accepting. Exuberant lovers of life, people, and material comforts. Enjoy working with others to make things happen. Bring common sense and a realistic approach to their work and make work fun. Flexible and spontaneous, adapt readily to new people and environments. Learn best by trying a new skill with other people.',
    ENFP: 'Warmly enthusiastic and imaginative. See life as full of possibilities. Make connections between events and information very quickly, and confidently proceed based on the patterns they see. Want a lot of affirmation from others, and readily give appreciation and support. Spontaneous and flexible, often rely on their ability to improvise and their verbal fluency.',
    ENTP: 'Quick, ingenious, stimulating, alert, and outspoken. Resourceful in solving new and challenging problems. Adept at generating conceptual possibilities and then analyzing them strategically. Good at reading other people. Bored by routine, will seldom do the same thing the same way, apt to turn to one new interest after another.',
    ESTJ: 'Practical, realistic, matter-of-fact. Decisive, quickly move to implement decisions. Organize projects and people to get things done, focus on getting results in the most efficient way possible. Take care of routine details. Have a clear set of logical standards, systematically follow them and want others to also. Forceful in implementing their plans.',
    ESFJ: 'Warmhearted, conscientious, and cooperative. Want harmony in their environment, work with determination to establish it. Like to work with others to complete tasks accurately and on time. Loyal, follow through even in small matters. Notice what others need in their day-to-day lives and try to provide it. Want to be appreciated for who they are and for what they contribute.',
    ENFJ: 'Warm, empathetic, responsive, and responsible. Highly attuned to the emotions, needs, and motivations of others. Find potential in everyone, want to help others fulfill their potential. May act as catalysts for individual and group growth. Loyal, responsive to praise and criticism. Sociable, facilitate others in a group, and provide inspiring leadership.',
    ENTJ: 'Frank, decisive, assume leadership readily. Quickly see illogical and inefficient procedures and policies, develop and implement comprehensive systems to solve organizational problems. Enjoy long-term planning and goal setting. Usually well informed, well read, enjoy expanding their knowledge and passing it on to others. Forceful in presenting their ideas.'
};

const Metrics = () => {
    const uid = localStorage.getItem("user_id");
    const [description, setDescription] = useState('');

    const [formData, setFormData] = useState({
        m_b_type_indicator: '',
        additional_notes: '',
        fact_finder: '',
        disk_strengths: '',
        strength_finder: '',
        follow_thru: '',
        quick_start: '',
        implementer: '',
        additional_notes_2: '',
        additional_notes_3: '',
        top_strength: [], // Ensure this is an array
        botton_strength: [] // Ensure this is an array
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (uid) {
            fetchData();
        }
    }, [uid]);

    useEffect(() => {
        if (formData.m_b_type_indicator) {
            setDescription(MBTI_DESCRIPTIONS[formData.m_b_type_indicator] || '');
        } else {
            setDescription('');
        }
    }, [formData.m_b_type_indicator]);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_personal_profile_metrice}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const data = response.data.data[0];
                setFormData({
                    ...data,
                    id: data.id,
                    top_strength: Array.isArray(data.top_strength) && data.top_strength.length > 0 ? data.top_strength : [''],
                    botton_strength: Array.isArray(data.botton_strength) && data.botton_strength.length > 0 ? data.botton_strength : [''],
                });
                setErrors({});
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            // toast.error('An error occurred while fetching the data.');
        }
    };

    const handleAddTopStrength = () => {
        // Check if the last item in top_strength is filled
        if (formData.top_strength.length < 5 && formData.top_strength[formData.top_strength.length - 1] !== '') {
            setFormData(prevFormData => ({
                ...prevFormData,
                top_strength: [...prevFormData.top_strength, '']  // Add a new empty input field
            }));
        }
    };
    const handleAddBottomStrength = () => {
        // Check if the last item in botton_strength is filled
        if (formData.botton_strength.length < 5 && formData.botton_strength[formData.botton_strength.length - 1] !== '') {
            setFormData(prevFormData => ({
                ...prevFormData,
                botton_strength: [...prevFormData.botton_strength, '']  // Add a new empty input field
            }));
        }
    };
    const handleDeleteTopStrength = async (id, value) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', id);
            formDataToSend.append('top_strength', value);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.personal_strengths_top}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
        }
    };

    const handleDeleteBottomStrength = async (id, value) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', id);
            formDataToSend.append('botton_strength', value);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.personal_strengths_bottom}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while deleting the data.");
            console.error(error);
        }
    };


    const handleChange = (name, value, index) => {
        setFormData(prevState => {
            const updatedStrengths = [...prevState[name]]; // Create a copy of the array
            updatedStrengths[index] = value; // Update the specific index
            return {
                ...prevState,
                [name]: updatedStrengths
            };
        });
        debouncedSubmit({ ...formData, [name]: [...formData[name], value] }); // Ensure to send the updated array
    };


    const debouncedSubmit = debounce(async (formData) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('m_b_type_indicator', formData.m_b_type_indicator);
        formDataToSend.append('additional_notes', formData.additional_notes);
        formDataToSend.append('fact_finder', formData.fact_finder);
        formDataToSend.append('disk_strengths', formData.disk_strengths);
        formDataToSend.append('strength_finder', formData.strength_finder);
        formDataToSend.append('follow_thru', formData.follow_thru);
        formDataToSend.append('quick_start', formData.quick_start);
        formDataToSend.append('implementer', formData.implementer);
        formDataToSend.append('additional_notes_2', formData.additional_notes_2);
        formDataToSend.append('additional_notes_3', formData.additional_notes_3);

        // Append top_strength items individually
        (Array.isArray(formData.top_strength) ? formData.top_strength : []).forEach((top_strength, index) => {
            formDataToSend.append(`top_strength[${index}]`, top_strength);
        });

        // Append botton_strength items individually
        (Array.isArray(formData.botton_strength) ? formData.botton_strength : []).forEach((botton_strength, index) => {
            formDataToSend.append(`botton_strength[${index}]`, botton_strength);
        });

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.personal_profile_metrice}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("User Profile update failed.");
            console.error(error);
        }
    }, 1000); // Adjust debounce delay as needed

    return (
        <form>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="align-items-center justify-content-between d-flex">
                            <h5>Myers-Briggs Type Indicator® Results</h5>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="assessment-form mb-3">
                            <div className="m_b_type_indicator-section mb-3">
                                <label><b>Myers-Briggs Type Indicator Results</b></label>
                                <select
                                    className="form-control"
                                    value={formData.m_b_type_indicator}
                                    onChange={(e) => handleChange('m_b_type_indicator', e.target.value)}
                                >
                                    <option value="">Select Myers-Briggs Type</option>
                                    {Object.keys(MBTI_DESCRIPTIONS).map(type => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>


                                {errors.m_b_type_indicator && <small className="text-danger">{errors.m_b_type_indicator}</small>}
                            </div>
                            {description && (
                                <Alert color="danger">
                                    <h4 className="alert-heading">{"Additional Notes :"}</h4>
                                    <p>{description}</p>
                                </Alert>
                            )}
                        </div>


                    </div>
                </div>
            </div>

            <div className="card mt-4">
                <div className="card-header">
                    <h5>DISC Test Results</h5>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='form-group'>
                                <label><b>DISC Test Results</b></label>
                                <textarea
                                    rows={4}
                                    className="form-control"
                                    placeholder='Disk Strengths'
                                    name='disk_strengths'
                                    value={formData.disk_strengths}
                                    onChange={(e) => handleChange('disk_strengths', e.target.value)}
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="card mt-4">
                <div className="card-header">
                    <h5>Strength Finders Test Results</h5>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='form-group '>
                                <label><b>Strength Finders Test Results</b></label>
                                <textarea
                                    rows={4}
                                    className="form-control"
                                    placeholder='Strength Finder'
                                    name='strength_finder'
                                    value={formData.strength_finder}
                                    onChange={(e) => handleChange('strength_finder', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-4">
                <div className="card-header">
                    <h5> Kolbe Index Results</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="assessment-form">
                            <div className="form-group">
                                <label><b>Fact Finder</b></label>
                                <select
                                    className="form-control"
                                    value={formData.fact_finder}
                                    onChange={(e) => handleChange('fact_finder', e.target.value)}
                                >
                                    <option value="">Select Fact Finder</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                {errors.fact_finder && <small className="text-danger">{errors.fact_finder}</small>}
                            </div>
                            <div className="form-group mt-2">
                                <label><b>Follow Thru</b></label>
                                <select
                                    className="form-control"
                                    value={formData.follow_thru}
                                    onChange={(e) => handleChange('follow_thru', e.target.value)}
                                >
                                    <option value="">Select Follow Thru</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>

                                {errors.follow_thru && <small className="text-danger">{errors.follow_thru}</small>}
                            </div>
                            <div className="form-group mt-2">
                                <label><b>Quick Start</b></label>
                                <select
                                    className="form-control"
                                    value={formData.quick_start}
                                    onChange={(e) => handleChange('quick_start', e.target.value)}
                                >
                                    <option value="">Select Quick Start</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </select>
                                {errors.quick_start && <small className="text-danger">{errors.quick_start}</small>}
                            </div>
                            <div className="form-group mt-2">
                                <label><b>Implementer</b></label>
                                <select
                                    className="form-control"
                                    value={formData.implementer}
                                    onChange={(e) => handleChange('implementer', e.target.value)}
                                >
                                    <option value="">Select Implementer</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                                {errors.implementer && <small className="text-danger">{errors.implementer}</small>}
                            </div>
                            <div className='form-group mt-2'>
                                <label><b>Additional Notes</b></label>
                                <textarea
                                    rows={6}
                                    className="form-control"
                                    value={formData.additional_notes_2}
                                    onChange={(e) => handleChange('additional_notes_2', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-4">
                <div className="card-header">
                    <h5>Clifton Strengths Results</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label><b>Top 5 Strengths</b></label>
                                {formData.top_strength.map((strength, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className={`form-control mt-2 ${errors[`top_strength${index}`] ? 'is-invalid' : ''}`}
                                            value={strength}
                                            onChange={(e) => handleChange('top_strength', e.target.value, index)}
                                        />
                                        {formData.top_strength.length > 1 && (
                                            <button
                                                type="button"
                                                className=" mt-2 ml-2 btn-danger ms-3"
                                                onClick={() => handleDeleteTopStrength(formData.id, strength)}
                                            >
                                                <MdDelete className='CliftonStrengths-svg ' />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                {errors.top_strength && <small className="text-danger">{errors.top_strength}</small>}
                            </div>
                            <Button
                                variant="primary"
                                className="mt-2"
                                onClick={handleAddTopStrength}
                                disabled={formData.top_strength.length >= 5}
                            >
                                Add Top Strength
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label><b>Bottom 5 Strengths</b></label>
                                {formData.botton_strength.map((strength, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className={`form-control mt-2 ${errors[`botton_strength${index}`] ? 'is-invalid' : ''}`}
                                            value={strength}
                                            onChange={(e) => handleChange('botton_strength', e.target.value, index)}
                                        />
                                        {formData.botton_strength.length > 1 && (
                                            <button
                                                type="button"
                                                className=" mt-2 ml-2 btn-danger ms-3"
                                                onClick={() => handleDeleteBottomStrength(formData.id, strength)} // Ensure `formData.id` is correctly set
                                            >
                                                <MdDelete className='CliftonStrengths-svg' />
                                            </button>
                                        )}
                                    </div>
                                ))}
                                {errors.botton_strength && <small className="text-danger">{errors.botton_strength}</small>}
                            </div>
                            <Button
                                variant="primary"
                                className="mt-2"
                                onClick={handleAddBottomStrength}
                                disabled={formData.botton_strength.length >= 5}
                            >
                                Add Bottom Strength
                            </Button>
                        </div>
                        <div className='form-group mt-4'>
                            <label><b>Additional Notes</b></label>
                            <textarea
                                rows={6}
                                className="form-control"
                                value={formData.additional_notes_3}
                                onChange={(e) => handleChange('additional_notes_3', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-end mt-3">
                {/* <Button type="submit" variant="success">Save</Button> */}
            </div>
        </form>
    );
};

export default Metrics;
