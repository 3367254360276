import React from "react";
import Totalvenue from "./totalrevenue";
import AccountPayable from "./accountspayable";

const Reportingdata = () => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6">
                    <Totalvenue />

                </div>
                <div className="col-sm-6">
                    <AccountPayable />
                </div>
            </div>
        </div >
    );
};

export default Reportingdata;
