import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function TechnologyAssessment() {
    const [strategyOption, setStrategyOption] = useState('');
    const [cultureOption, setCultureOption] = useState('');
    const [planningOption, setPlanningOption] = useState('');
    const [performanceOption, setPerformanceOption] = useState('');
    const [feedbackOption, setFeedbackOption] = useState('');
    const [valuesOption, setValuesOption] = useState('');
    const [innovationOption, setInnovationOption] = useState('');
    const [embeddingOption, setEmbeddingOption] = useState('');
    const [rewardsOption, setRewardsOption] = useState('');
    const [rewardsStrategyOption, setRewardsStrategyOption] = useState('');
    const [TechnologyDigitalContextPlatform, setTechnologyDigitalContextPlatform] = useState('');
    const [TechnologyDigitalContextPace, setTechnologyDigitalContextPace] = useState('');
    const [rewardsStrateTechnologyDigital, setrewardsStrateTechnologyDigital] = useState('');

    const uid = localStorage.getItem("user_id");

    const updateTask = async (formData) => {
        try {

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_technology_assesment}`,
                formData,
                {
                    headers: {
                        Authorization: API.AUTHORIZATION_TOKEN,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while updating data.");
        }
    };


    useEffect(() => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('Digital_Enterprise', strategyOption || '');
        formDataToSend.append('Enterprise_Technology', cultureOption || '');
        formDataToSend.append('Digital_Innovation', planningOption || '');
        formDataToSend.append('Digital_Experience', performanceOption || '');
        formDataToSend.append('Data_Analytics', feedbackOption || '');
        formDataToSend.append('Data_Analytics_Data_Analytics', valuesOption || '');
        formDataToSend.append('Data_Analytics_Data_Quality', innovationOption || '');
        formDataToSend.append('Security_Privacy', embeddingOption || '');
        formDataToSend.append('Security_Privacy_Scope', rewardsOption || '');
        formDataToSend.append('Security_Privacy_Focus', rewardsStrategyOption || '');
        formDataToSend.append('Technology_Digital_Context_Platform', TechnologyDigitalContextPlatform || '');
        formDataToSend.append('Technology_Digital_Context_Pace', TechnologyDigitalContextPace || '');
        formDataToSend.append('Technology_Digital_Context_Cloud', rewardsStrateTechnologyDigital || '');

        if (strategyOption || cultureOption || planningOption || performanceOption || feedbackOption || valuesOption || innovationOption || embeddingOption || rewardsOption || rewardsStrategyOption || TechnologyDigitalContextPlatform || TechnologyDigitalContextPace || rewardsStrateTechnologyDigital) {
            updateTask(formDataToSend);
        }
    }, [strategyOption, cultureOption, planningOption, performanceOption, feedbackOption, valuesOption, innovationOption, embeddingOption, rewardsOption, rewardsStrategyOption, TechnologyDigitalContextPlatform, TechnologyDigitalContextPace, rewardsStrateTechnologyDigital]);

    const handleChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_technology}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_assesment;

                if (jsonData) {
                    let data;
                    try {
                        data = JSON.parse(jsonData);
                        setStrategyOption(data.Digital_Enterprise || '');
                        setCultureOption(data.Enterprise_Technology || '');
                        setPlanningOption(data.Digital_Innovation || '');
                        setPerformanceOption(data.Digital_Experience || '');
                        setFeedbackOption(data.Data_Analytics || '');
                        setValuesOption(data.Data_Analytics_Data_Analytics || '');
                        setInnovationOption(data.Data_Analytics_Data_Quality || '');
                        setEmbeddingOption(data.Security_Privacy || '');
                        setRewardsOption(data.Security_Privacy_Scope || '');
                        setRewardsStrategyOption(data.Security_Privacy_Focus || '');
                        setTechnologyDigitalContextPlatform(data.Technology_Digital_Context_Platform || '');
                        setTechnologyDigitalContextPace(data.Technology_Digital_Context_Pace || '');
                        setrewardsStrateTechnologyDigital(data.Technology_Digital_Context_Platform || '');

                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                }
            }
        } catch (error) {
            console.error('Fetch meeting types error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const calculateCompletionPercentage = () => {
        const weights = {
            Leading: 7.7,
            Established: 5.13,
            Developing: 2.56
        };

        const totalQuestions = 13;
        const totalWeight = Object.values(weights).reduce((acc, value) => acc + value, 0);
        const score = [
            strategyOption,
            cultureOption,
            planningOption,
            performanceOption,
            feedbackOption,
            valuesOption,
            innovationOption,
            embeddingOption,
            rewardsOption,
            rewardsStrategyOption,
            TechnologyDigitalContextPlatform,
            TechnologyDigitalContextPace,
            rewardsStrateTechnologyDigital,
        ].reduce((acc, option) => acc + (weights[option] || 0), 0);

        return (score / (totalWeight * totalQuestions)) * 100;
    };

    const completionPercentage = calculateCompletionPercentage(0);

    useEffect(() => {
        const completionPercentage = calculateCompletionPercentage();

        localStorage.setItem('tecnologyCompletionPercentage', completionPercentage);

    }, [
        strategyOption,
        cultureOption,
        planningOption,
        performanceOption,
        feedbackOption,
        valuesOption,
        innovationOption,
        embeddingOption,
        rewardsOption,
        rewardsStrategyOption,
        TechnologyDigitalContextPlatform,
        TechnologyDigitalContextPace,
        rewardsStrateTechnologyDigital,
    ]);

    return (
        <div className="col-sm-12">
            <div className="card mt-4">
                <div className="card-header">
                    <div className='justify-content-between align-items-center d-flex'>
                        <div className=' align-items-center d-flex'>
                            <h5>Technology Assessment</h5>
                        </div>

                        <div className='ms-3 align-items-center d-flex ms-3'>
                            <div className='m-r-15'>
                                <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                            </div>
                            <CircularProgressbar className='assessmentprogress'
                                value={completionPercentage}
                                text={`${Math.round(completionPercentage)}%`}
                                styles={{
                                    path: {
                                        stroke: 'green',
                                    },
                                    text: {
                                        fill: 'black',
                                        fontSize: '16px',
                                    },
                                    trail: {
                                        stroke: '#d6d6d6',
                                    },
                                }}
                            />

                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Technology - Digital Enterprise </strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={strategyOption === 'Leading'}
                                        onChange={handleChange(setStrategyOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Emerging technologies are embraced and adopted within the business in an agile way.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        name='Established'

                                        checked={strategyOption === 'Established'}
                                        onChange={handleChange(setStrategyOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The organization waits to adopt new and emerging technologies until they are proven.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={strategyOption === 'Developing'}
                                        onChange={handleChange(setStrategyOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The enterprise is slow to adopt new technology.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Technology - Enterprise Technology </strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={cultureOption === 'Leading'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Emerging technologies are embraced and adopted within the business in an agile way.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={cultureOption === 'Established'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The organization waits to adopt new and emerging technologies until they are proven.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={cultureOption === 'Developing'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The enterprise is slow to adopt new technology.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Digital Innovation</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={planningOption === 'Leading'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital innovation is widely adopted within the organization, which enhances the core capabilities of the enterprise and opens up new market opportunities.
                                </p>
                                <p className="textnots-text">
                                    •	The IT function is perceived as a strategic business partner. It drives digital technologies to effectively enable new business models.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={planningOption === 'Established'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital innovation is being more widely adopted but is limited to certain business functions.
                                </p>
                                <p className="textnots-text">
                                    •	The IT function is mature and pro-active. It drives digital technologies to effectively support the existing business model.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={planningOption === 'Developing'}
                                        onChange={handleChange(setPlanningOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is limited digital innovation with new business models or emerging technologies.
                                </p>
                                <p className="textnots-text">
                                    •	The IT function operates mainly reactive and drives digital applications in response to business requirements.                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Digital Experience</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={performanceOption === 'Leading'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital is at the heart of the way the organization does business, the products and services it offers, and how it interacts with customers, vendors, employees and other stakeholders.
                                </p>
                                <p className="textnots-text">
                                    •	The digital experience is a true differentiator.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={performanceOption === 'Established'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business has a clear view on what the digital experience of all key stakeholders should be and has plans in place to deliver on itsintend.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={performanceOption === 'Developing'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is limited thinking on what the digital experience should be for all stakeholders and how it should be structured.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Data and Analytics </strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={feedbackOption === 'Leading'}
                                        onChange={handleChange(setFeedbackOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The enterprise highly values the data within and outside the organization and has advanced processes and techniques in place to capture, store, analyze and activate data.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={feedbackOption === 'Established'}
                                        onChange={handleChange(setFeedbackOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The enterprise values the data within the organization but uses external data in a restricted way.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={feedbackOption === 'Developing'}
                                        onChange={handleChange(setFeedbackOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is limited use of external data.
                                </p>
                                <p className="textnots-text">
                                    •	Data management is ad hoc throughout the organization, and there is limited capability to handle big data (unstructured, large volumes).
                                </p>
                                <p className="textnots-text">
                                    •	The organization has challenges getting value from its own data.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Data and Analytics - Data Analytics</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={valuesOption === 'Leading'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business successfully leverages advanced analytics techniques (predictive, machine learning, artificial intelligence) and big data (unstructured, large volumes) to drive competitive advantage.
                                </p>
                                <p className="textnots-text">
                                    •	There is a culture and approach to experimentation through analytics across the business (e.g. operations, customer experience, tax and finance).
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={valuesOption === 'Established'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Analytics is starting to be used in various parts of the business to improve decision making and business processes.
                                </p>
                                <p className="textnots-text">
                                    •	There is limited experimentation with big data. Analytics tends to be a traditional business intelligence technique.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={valuesOption === 'Developing'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The focus of analytics is on reporting historical views of data and limited use of 'what-if' analysis.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Data and Analytics - Data Quality </strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={innovationOption === 'Leading'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The consistently high quality levels of both internal and external data drives real business value and insights.
                                </p>
                                <p className="textnots-text">
                                    •	Enterprise data management, data governance and processes are highly effective.
                                </p>
                                <p className="textnots-text">
                                    •	The business has a data-driven culture where the use of techniques that fully leverage the internal and external data within the organization drives decision making.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={innovationOption === 'Established'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business recognizes the importance of quality data and has deployed tools and techniques to leverage its own internal data to drive business insight.
                                </p>
                                <p className="textnots-text">
                                    •	The business has started to implement enterprise data, governance and processes, with the appropriate systems to support.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={innovationOption === 'Developing'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business spends a lot of time fixing data quality issues in a tactical way and has limited time to generate real business value and insight from its own data.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Security and Privacy</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={embeddingOption === 'Leading'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Security is viewed as a critical component of the company's overall risk management via strong "tone at the top".
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={embeddingOption === 'Established'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Security is viewed as an IT issue, but has oversight from, and regular reporting to, non-IT stakeholders.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={embeddingOption === 'Developing'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Security and privacy issues are driven 'bottom up' in the organization.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Security and Privacy - Scope</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={rewardsOption === 'Leading'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Security efforts follow an "active defense" approach with proactive identification of issues driven from inside the company and consumption of external, sector-relevant threat intelligence.
                                </p>
                                <p className="textnots-text">
                                    •	Security and privacy strategies are driven by protection of the most critical digital assets and support of business growth drivers.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={rewardsOption === 'Established'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Security architecture is in place that supports a 'defense-in-depth' strategy. This provides layers of deterrence that limits attackers' ability to access critical digital assets.
                                </p>
                                <p className="textnots-text">
                                    •	The security function recognizes and regularly evaluates the 'ecosystem' including reliance on third parties and business partners.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={rewardsOption === 'Developing'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Compliance with regulatory requirements drives security efforts; focus on the importance of digital assets to corporate strategy is limited.
                                </p>
                                <p className="textnots-text">
                                    •	Security issues are identified and addressed reactively.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Security and Privacy - Focus</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={rewardsStrategyOption === 'Leading'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The focus is on operating a resilient security and privacy capability.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={rewardsStrategyOption === 'Established'}

                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The company recognizes and balances investment and operational effort between preventative controls and a strong monitoring and response capability.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={rewardsStrategyOption === 'Developing'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The security focus is on building and or maintaining perimeter controls and legacy end-point controls (e.g. anti-virus).
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Technology - Technology in a Digital Context - Platform</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={TechnologyDigitalContextPlatform === 'Leading'}
                                        onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital platforms are fully established and driven from an externally focused (customer and ecosystem) multi-channel perspective.
                                </p>
                                <p className="textnots-text">
                                    •	The technology function drives change in an agile way using the best skills and resources within and outside of the organization.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={TechnologyDigitalContextPlatform === 'Established'}
                                        onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital platforms are driven from the company's perspective.
                                </p>
                                <p className="textnots-text">
                                    •	The technology function drives change using internal staff or long-term established partners, with some experimentation with newer technology vendors or service providers.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={TechnologyDigitalContextPlatform === 'Developing'}
                                        onChange={handleChange(setTechnologyDigitalContextPlatform)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital platforms have been established but are not integrated with other platforms and rely on legacy platform infrastructure.
                                </p>
                                <p className="textnots-text">
                                    •	The technology function drives change using only internal staff or long-term established partners.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Technology - Technology in a Digital Context - Pace</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={TechnologyDigitalContextPace === 'Leading'}
                                        onChange={handleChange(setTechnologyDigitalContextPace)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Technology is adopted rapidly, experimentation is encouraged, and failure is acceptable.
                                </p>
                                <p className="textnots-text">
                                    •	Technology investment is proactive and agile to meet the strategic objectives of being a digital business.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={TechnologyDigitalContextPace === 'Established'}
                                        onChange={handleChange(setTechnologyDigitalContextPace)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Technology is adopted conservatively; development methods are slow and process-laden. There are limited adoptions of agile methods.
                                </p>
                                <p className="textnots-text">
                                    •	Legacy persists and acts as a drain on resources, investment and focus.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={TechnologyDigitalContextPace === 'Developing'}
                                        onChange={handleChange(setTechnologyDigitalContextPace)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital initiatives are experimental, conservative and limited in scope.
                                </p>
                                <p className="textnots-text">
                                    •	Technology deployment is structured, slow and risk averse.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>
                                    <strong>Technology - Technology in a Digital Context - Cloud</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        name='Leading'
                                        checked={rewardsStrateTechnologyDigital === 'Leading'}
                                        onChange={handleChange(setrewardsStrateTechnologyDigital)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Emerging technologies such as RPA, AI and machine learning are adopted throughout the organization to increase efficiency and drive down costs.
                                </p>
                                <p className="textnots-text">
                                    •	Cloud is the standard deployment choice for new applications. Legacy technology platforms are retired quickly.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        name='Established'
                                        checked={rewardsStrateTechnologyDigital === 'Established'}
                                        onChange={handleChange(setrewardsStrateTechnologyDigital)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Digital is considered on an initiative-by-initiative basis and is 'bolt-on' vs. embedded. Cloud is acceptable for applications, but it is not the strategic preference.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        name='Developing'
                                        checked={rewardsStrateTechnologyDigital === 'Developing'}
                                        onChange={handleChange(setrewardsStrateTechnologyDigital)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Use of emerging technology as a business enabler and or driver of innovation is limited. There is some use of cloud.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default TechnologyAssessment;

