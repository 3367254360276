import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";
import debounce from "lodash/debounce";
import { BsEmojiAngry } from "react-icons/bs";
import { BsEmojiLaughing } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const AnnualPlaning = () => {
  const uid = localStorage.getItem("user_id");
  const [annualOutcomes, setAnnualOutcomes] = useState([]);
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    setFormData((prevState) => {
      if (type === "checkbox") {
        const updatedAgenda = checked
          ? [...prevState.agenda, value]
          : prevState.agenda.filter((item) => item !== value);

        return {
          ...prevState,
          agenda: updatedAgenda,
        };
      }

      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleModalSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const handleSave = async () => {
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        const value =
          key === "agenda"
            ? JSON.stringify(
              formData[key]
                .filter((item) => item.status === "1")
                .map((item) => item.agenda)
            )
            : formData[key];
        formDataToSend.append(key, value);
      });
      formDataToSend.append("user_id", uid);
      formDataToSend.append("meeting_m_c_id", "4");

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_meeting_main_sidebar}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Meeting Added Successfully");
        fetchTasks();
        setFormData({
          meeting_name: "",
          meeting_type: "",
          meeting_date: "",
          status: "",
          agenda: [],
          address: "",
        });
        setName("");
        agendafetchData();
        setShowModal(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_meeting_type}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setMeetingTypes(response.data.info);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Fetch meeting types error", error);
    }
  };

  useEffect(() => {
    agendafetchData();
    fetchData();
  }, []);

  const agendafetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_meeting_agenda}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setAnnualOutcomes(response.data.info);
        setFormData((prevFormData) => ({
          ...prevFormData,
          agenda: response.data.info,
        }));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Fetch meeting types error", error);
    }
  };

  const [formData, setFormData] = useState({
    meeting_name: "",
    meeting_type: "",
    meeting_date: "",
    status: "",
    agenda: [],
    address: "",
  });

  const [gratitudeEntries, setGratitudeEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    fetchTasks();
  }, [activeTab]);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("meeting_m_c_id", "4");
    formDataToSend.append("filter", activeTab);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.check_meetings_filter}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const tasksWithParsedAgenda = response.data.tasks.map((task) => ({
          ...task,
          agenda: JSON.parse(task.agenda),
        }));

        tasksWithParsedAgenda.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setGratitudeEntries(tasksWithParsedAgenda);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task fetching:", error);
    }
  };

  const filteredEntries = gratitudeEntries.filter(
    (entry) =>
      (entry.meeting_name || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()) ||
      (entry.meeting_type || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()) ||
      (entry.meeting_date || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()) ||
      (entry.status || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase())
  );

  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const { goalid, field, value } = task;
      const formDataToSend = new FormData();
      formDataToSend.append("meeting_id", goalid);
      formDataToSend.append("filed", field);

      if (field === "agenda") {
        formDataToSend.append("text", value);
      } else {
        formDataToSend.append("text", value);
      }

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.update_meeting_sidebar_data}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          toast.success("Meeting Updating Successfully");
          fetchTasks();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error during update:", error);
      }
    }, 2000),
    []
  );

  const handleEditInputChange = (e, index, field, goalid) => {
    const { value, checked } = e.target;
    const updatedOutcomes = [...gratitudeEntries];

    if (field === "agenda") {
      const agendaItem = value;

      setUpdatedTask({ field, value: agendaItem, goalid });
    } else {
      updatedOutcomes[index][field] = value;
      setUpdatedTask({ field, value, goalid });
    }

    setGratitudeEntries(updatedOutcomes);
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const handleCheckboxChange = async (taskid, isChecked) => {
    const newTasks = gratitudeEntries.map((task) =>
      task.id === taskid
        ? { ...task, is_complete: isChecked ? "1" : "0" }
        : task
    );

    setGratitudeEntries(newTasks);
    const formDataToSend = new FormData();
    formDataToSend.append("meeting_id", taskid);
    formDataToSend.append("is_checked", isChecked ? "1" : "0");

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.check_meetings}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchTasks();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during checkbox status update:", error);
      toast.error("An error occurred while updating the checkbox status.");
    }
  };

  const [selectedRowId, setSelectedRowId] = useState(null);

  const toggleAgendaModal = (id) => {
    setSelectedRowId(id);
    setShowModal((prevShowModal) => !prevShowModal);
  };
  const columns = [
    {
      name: "Completed",
      selector: (row) => row.is_complete,
      cell: (row) => (
        <input
          type="checkbox"
          id={`chk-ani${row.id}`}
          className="checkbox_animated"
          checked={row.status === "completed"}
          onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
        />
      ),
      sortable: true,
    },
    {
      name: "Meetings Name",
      selector: (row) => row.meeting_name,
      cell: (row, index) => (
        <div className="meeting-name">
          <input
            type="text"
            value={row.meeting_name}
            className="form-control"
            onChange={(e) =>
              handleEditInputChange(e, index, "meeting_name", row.id)
            }
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Meetings Type",
      selector: (row) => row.meeting_type,
      cell: (row, index) => (
        <div className="meeting-type">
          <select
            value={row.meeting_type}
            className="form-control"
            onChange={(e) =>
              handleEditInputChange(e, index, "meeting_type", row.id)
            }
          >
            <option value="">Select Meeting Type Name</option>
            {meetingTypes.map((type, index) => (
              <option key={index} value={type.name}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Meeting Date",
      selector: (row) => row.meeting_date,
      cell: (row, index) => {
        const today = new Date().toISOString().split("T")[0];
        const isUpcoming = row.meeting_date > today;
        return (
          <div className="meeting-date d-flex align-items-center">
            <span className="icon">
              {isUpcoming ? (
                <BsEmojiLaughing className="emijeyepass" />
              ) : (
                <BsEmojiAngry className="emijeyeupcommint" />
              )}
            </span>
            <input
              type="date"
              value={row.meeting_date}
              className="form-control"
              onChange={(e) =>
                handleEditInputChange(e, index, "meeting_date", row.id)
              }
              min={minDate}
              max={maxDate}
            />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Agenda",
      selector: (row) => row.agenda,
      cell: (row, index) => (
        <div>
          <button className="p-2" onClick={() => toggleAgendaModal(row.id)}>
            {showModal && selectedRowId === row.id
              ? "Hide Agenda"
              : "Show Agenda"}
          </button>

          <Modal
            show={showModal && selectedRowId === row.id}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Agenda List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="metting-select-agenda-colums">
                {annualOutcomes && annualOutcomes.length > 0 ? (
                  annualOutcomes.map((type, idx) => (
                    <div key={idx} className="mt-2">
                      <input
                        type="checkbox"
                        className="checkbox_animated"
                        value={type.agenda}
                        checked={row.agenda.includes(type.agenda)}
                        onChange={(e) =>
                          handleEditInputChange(e, index, "agenda", row.id)
                        }
                      />
                      {type.agenda}
                    </div>
                  ))
                ) : (
                  <p>
                    <b>No agenda items available.</b>
                  </p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row, index) => (
        <div className="meeting-Status">
          <select
            type="text"
            value={row.status}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "status", row.id)}
            style={{
              backgroundColor:
                row.status === "completed"
                  ? "#39e026"
                  : row.status === "scheduled"
                    ? "#bababa"
                    : "",
            }}
          >
            <option value="completed">Completed</option>
            <option value="scheduled">Scheduled</option>
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      cell: (row, index) => (
        <div className="meeting-Address">
          <textarea
            type="textarea"
            rows={1}
            value={row.address}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "address", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
  ];

  const today = new Date();
  const minDate = today.toISOString().split("T")[0];

  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + 30);
  const maxDate = futureDate.toISOString().split("T")[0];

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputagendaChange = (e) => {
    const { value } = e.target;
    setName(value);

    if (value.trim()) {
      setValidationErrors({});
    } else {
      setValidationErrors({ name: "Agenda Name is required." });
    }
  };

  const handleBlur = async () => {
    if (!isFocused && name.trim()) {
      setLoading(true);

      const lines = name.split("\n");

      try {
        for (const line of lines) {
          if (line.trim()) {
            const formDataToSend = new FormData();
            formDataToSend.append("user_id", localStorage.getItem("user_id"));
            formDataToSend.append("agenda", line.trim());

            const response = await axios.post(
              `${API.BASE_URL}${API.ENDPOINTS.add_meeting_agenda}`,
              formDataToSend,
              {
                headers: {
                  Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
              }
            );

            if (response.data.status === "success") {
              // toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          }
        }
        agendafetchData();
        fetchTasks();
        fetchData();
      } catch (error) {
        console.error("Error during submission:", error);
        toast.error("An error occurred while adding the agenda.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };
  const navigate = useNavigate();
  const buttonClick = () => {
    navigate("/meeting/meeting-type");
  };

  const handleAgendaList = async (event, agendaItem) => {
    const { checked } = event.target;
    const status = checked ? "1" : "0";

    try {
      const formDataToSend = new FormData();
      // formDataToSend.append('user_id', uid);
      formDataToSend.append("agenda_id", agendaItem);
      formDataToSend.append("status", status);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.edit_meeting_agenda}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // fetchTasks();
        agendafetchData();
        //toast.success("Agenda status updated successfully.");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating agenda status", error);
      toast.error("An error occurred while updating the agenda status.");
    }
  };
  const isSaveButtonEnabled = () => formData.meeting_name.trim() !== "";

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="align-items-center justify-content-between d-flex">
                  <h5>Annually Planning Session</h5>
                  <button onClick={buttonClick}>Add Meeting Type</button>{" "}
                </div>
              </div>
            </div>

            <form className="m-3">
              <div className="row">
                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                  <label>
                    <b>Meeting Name</b>
                  </label>
                  <input
                    className="form-control"
                    onChange={handleModalSelectChange}
                    name="meeting_name"
                    value={formData.meeting_name}
                    placeholder="Meeting Name"
                  ></input>
                </div>
                <div className="form-group mt-2 mb-3 col-lg-4 col-md-6">
                  <label>
                    <b>Meeting Type</b>
                  </label>
                  <select
                    className="form-control"
                    name="meeting_type"
                    onChange={handleInputChange}
                    value={formData.meeting_type}
                  >
                    <option value="">Select Meeting Type Name</option>
                    {meetingTypes.map((type, index) => (
                      <option key={index} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-2 mb-3 form-group col-lg-4 col-md-6">
                  <label>
                    <b>Meeting Date</b>
                  </label>
                  <input
                    className="form-control"
                    name="meeting_date"
                    type="date"
                    value={formData.meeting_date}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mt-2 mb-3 form-group col-lg-4 col-md-6">
                  <label>
                    <b>Status</b>
                  </label>
                  <select
                    type="text"
                    name="status"
                    className="form-control"
                    value={formData.status}
                    onChange={handleInputChange}
                    placeholder="status"
                  >
                    <option value="">Select status</option>
                    <option value="completed">Completed</option>
                    <option value="scheduled">Scheduled</option>
                  </select>
                </div>
                <div className="mt-2 mb-3 form-group col-lg-4 col-md-6">
                  <label>
                    <b>Address</b>
                  </label>
                  <textarea
                    type="textarea"
                    rows={1}
                    name="address"
                    className="form-control"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Address"
                  />
                </div>

                <div className="mt-2 mb-3 form-group col-lg-4 col-md-12">
                  <div>
                    <div className="mb-4">
                      <div className="form-group">
                        <label className="col-form-label pt-0" htmlFor="name">
                          <b>Agenda</b>
                        </label>
                        <textarea
                          className={`form-control ${validationErrors.name ? "is-invalid" : ""
                            }`}
                          id="name"
                          name="name"
                          type="textarea"
                          rows={2}
                          placeholder="Enter Agenda Name"
                          value={name}
                          onChange={handleInputagendaChange}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 metting-select-agenda">
                  <label>
                    <b>Select Agenda From List To Your Meeting</b>
                  </label>
                  {annualOutcomes && annualOutcomes.length > 0 ? (
                    <div className="agenda-grid">
                      {annualOutcomes.map((type, index) => (
                        <div className="agenda-item" key={index}>
                          <input
                            type="checkbox"
                            className="checkbox_animated"
                            value={type.agenda}
                            checked={type.status === "1"}
                            onChange={(event) =>
                              handleAgendaList(event, type.id)
                            }
                            name="agenda"
                          />
                          {type.agenda}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No agenda items available.</p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end align-content-center">
                <Button
                  variant="success"
                  disabled={!isSaveButtonEnabled()}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </form>
            <div>
              <DataTable
                columns={columns}
                data={filteredEntries}
                pagination
                persistTableHead={true}
                className="keyrelationship-table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualPlaning;
