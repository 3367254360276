import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import API, { isSubscribedAccountant } from '../../utils';
import MyLoader from '../common/myloader';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
    const uid = localStorage.getItem("user_id");
    const [loading, setLoading] = useState(false);
    const storedName = localStorage.getItem("user_name");
    const storedEmail = localStorage.getItem("email");

    const [formData, setFormData] = useState({
        name: storedName || '',
        email: storedEmail || '',
        message: '',
        request_type: 'Support Question',
    });

    const [errors, setErrors] = useState({});
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const recaptchaRef = useRef(null);  // Create a reference for the ReCAPTCHA component

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm() && captchaValue) {
            setLoading(true);

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('name', formData.name);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('message', formData.message);
            formDataToSend.append('request_type', formData.request_type);
            formDataToSend.append('captcha', captchaValue); // Include captcha value

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.add_contact_support}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    setFormData({ message: '', request_type: 'Support Question' });
                    recaptchaRef.current.reset();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to submit the form. Please try again later.");
            } finally {
                setLoading(false);
            }
        } else if (!captchaValue) {
            toast.error("Please complete the CAPTCHA.");
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'This field is required';
            isValid = false;
        }

        if (!formData.email.trim()) {
            newErrors.email = 'This field is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email address';
            isValid = false;
        }

        if (!formData.message.trim()) {
            newErrors.message = 'This field is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, [navigate]);

    return (
        <MyLoader active={loading}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className=" align-items-center d-flex">
                                <h5>Contact Support</h5>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label"><b>Name</b></label>
                                            <input
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label"><b>Email</b></label>
                                            <input
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                type="text"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>Request Type</b></label>
                                        <div>
                                            {['Support Question', 'Enhancement Request', 'Billing Question', 'Other Request'].map((type) => (
                                                <div key={type} className="form-check">
                                                    <input
                                                        className="radio_animated"
                                                        type="radio"
                                                        name="request_type"
                                                        value={type}
                                                        checked={formData.request_type === type}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label">{type}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>Support Questions</b></label>
                                        <textarea
                                            className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                            rows={4}
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                        {errors.message && <div className="text-danger">{errors.message}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group mb-3">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={API.BASE_RECAPTCHASERT}
                                            onChange={handleCaptchaChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-end">
                                <Button variant="success" type="submit" className="mx-1">Send</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MyLoader>
    );
};

export default ContactForm;
