import React, { Fragment, useState, useEffect } from "react";
import { LogOut } from "react-feather";
import { useNavigate } from "react-router-dom";
import API from '../../../utils';
import axios from 'axios';
import { format } from 'date-fns';
import AdminUserPanel from "../adminSidebar/userPanel";
const Adminmenu = () => {

  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  // const profile = localStorage.getItem("profile");

  // useEffect(() => {
  //   setProfile(localStorage.getItem("profile") || profile  );
  // }, []);

  const role = localStorage.getItem("role")
  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("email");
    localStorage.removeItem("authenticated");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("profile");
    localStorage.removeItem("profile");
    localStorage.removeItem("googlelogin");
    localStorage.removeItem("isSubscriptionValid");
    localStorage.removeItem("googleTask");
    localStorage.removeItem("profileURL");
    localStorage.removeItem("transactionCompletionPercentage");
    localStorage.removeItem("tecnologyCompletionPercentage");
    localStorage.removeItem("risckCompletionPercentage");
    localStorage.removeItem("peplecompletionPercentage");
    localStorage.removeItem("opretionCompletionPercentage");
    localStorage.removeItem("financeCompletionPercentage");
    localStorage.removeItem("customerCompletionPercentage");
    localStorage.removeItem("completionPercentage");
    sessionStorage.removeItem("role");
    localStorage.removeItem("");
    navigate(`/admin`);
  };
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [profile_img, setprofile_img] = useState(null);

  // const [formData, setFormData] = useState({
  //   profile: null,
  //   // profile: profile,
  // });

  useEffect(() => {
    const storedProfileImg = localStorage.getItem('profile_img');
    if (storedProfileImg) {
      setprofile_img(storedProfileImg);
    }
  }, []);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);
    if (role) {
      formDataToSend.append('role', role);

    }
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_user_details}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );
      if (response.data.status === "success") {
        const userData = response.data.user_data;
        setprofile_img(userData.profile);
        const subscriptionDate = userData.subscription_date ? new Date(userData.subscription_date) : null;
        const subscriptionDuration = userData.subscription_duration ? parseInt(userData.subscription_duration, 10) : 0;

        if (subscriptionDate && subscriptionDuration) {
          subscriptionDate.setDate(subscriptionDate.getDate() + subscriptionDuration);
          const formattedExpiryDate = format(subscriptionDate, "eeee, MMMM d, yyyy");
          setExpiryDate(formattedExpiryDate);
          setIsSubscriptionExpired(subscriptionDate < new Date());
        } else {
          setExpiryDate("Not Available");
          setIsSubscriptionExpired(true);
          localStorage.setItem("isSubscriptionValid", "false");
        }

      } else {
        navigate(`/admin`);
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_name");
        localStorage.removeItem("email");
        localStorage.removeItem("authenticated");
        localStorage.removeItem("auth0_profile");
        localStorage.removeItem("profile");
        localStorage.removeItem("profile");
        localStorage.removeItem("googlelogin");
        localStorage.removeItem("isSubscriptionValid");
        localStorage.removeItem("googleTask");
        localStorage.removeItem("profileURL");
        localStorage.removeItem("transactionCompletionPercentage");
        localStorage.removeItem("tecnologyCompletionPercentage");
        localStorage.removeItem("risckCompletionPercentage");
        localStorage.removeItem("peplecompletionPercentage");
        localStorage.removeItem("opretionCompletionPercentage");
        localStorage.removeItem("financeCompletionPercentage");
        localStorage.removeItem("customerCompletionPercentage");
        localStorage.removeItem("completionPercentage");
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user_id]);

  return (
    <Fragment>
      <li className="onhover-dropdown ">
        <div className="d-flex align-items-center">
          {/* <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={authenticated ? auth0_profile.picture : profile} alt="header-user" /> */}
          <img
            className="align-self-center pull-right img-45 rounded-circle blur-up lazyloaded"
            alt=""
            // src={
            //   profile
            //   ? URL.createObjectURL(formData.profile)
            //   : formData.profile
            // }
            src={profile_img}
          />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            {/* <a to={`${process.env.PUBLIC_URL}/users/userEdit`}> */}
            <a href="/admin/profile">
              <AdminUserPanel />
            </a>
          </li>

          {/* <li>
            <a href="/users/userEdit">
              <User />
              {EditProfile}
            </a>

          </li> */}
          <li>
            <a onClick={Logout} >
              <LogOut /> {"Log out"}
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default Adminmenu;
