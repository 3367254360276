import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";

const Step1 = ({ formData, handleChange }) => (
  <>
    <h4>1. Evaluate the current state of your business</h4>
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label><b>What problems do you solve for your customers?</b></label>
          <textarea
            className="form-control"
            name="People"
            value={formData.People}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label><b>What sets your brand apart from the competition?</b></label>
          <textarea
            className="form-control"
            name="Foucs"
            value={formData.Foucs}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label><b>What will you sell?<br />(Service, Product, Subscriptions, etc.)</b></label>
          <textarea
            className="form-control"
            name="Relationship"
            value={formData.Relationship}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label><b>Whom will you sell to? (B2B, Wholesale, Market segment, etc.)</b></label>
          <textarea
            className="form-control"
            name="Part"
            value={formData.Part}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label><b>How will you sell? (manufacture reps, Internal sales team, etc.)</b></label>
          <textarea
            className="form-control"
            name="how"
            value={formData.how}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>

        <div className="form-group">
          <label><b>Where will you sell? (Local, International, A targeted region, etc.)</b></label>
          <textarea
            className="form-control"
            name="where"
            value={formData.where}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>
      </div>
    </div>
  </>
);

const Step2 = ({ formData, handleChange }) => (
  <>
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label><b>What you need to have in place to dominate the above sandbox in the next 3-5 years? Write Your ideas below.</b></label>
          <textarea
            className="form-control"
            name="sandbox"
            value={formData.sandbox}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>
      </div>
    </div>
  </>
);
const Sandbox = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    People: "",
    Foucs: "",
    Relationship: "",
    Part: "",
    Specifically: "",
    important: "",
    Fix: "",
    Nothing: "",
    Steps: "",
    sandbox: "",
    where: "",
    how: ""
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const progress = (step / 2) * 100;

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-4">
              <div className="card-header">
                <h5>Sandbox</h5>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <ProgressBar now={progress} label={`Step ${step} of 2`} className="ProgressBar h-25 mb-3" />
                  {step === 1 && <Step1 formData={formData} handleChange={handleChange} />}
                  {step === 2 && <Step2 formData={formData} handleChange={handleChange} />}

                </div>
                <div className="card-footer text-end">
                  {step > 1 && <button type="button" className="btn btn-secondary mx-1" onClick={handlePrevious}>Previous</button>}
                  {step < 2 && <button type="button" className="btn btn-primary mx-1" onClick={handleNext}>Next</button>}
                  {step === 2 && <button type="submit" className="btn btn-success mx-1">Save</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sandbox;
