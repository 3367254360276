import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import {  FaBusinessTime } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const Indexbusinessmodal = () => {
  
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <Container fluid>
            <Row className="mx-3">
              
                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} to="/businessmodal/businessmodalcanvas" exact>
                                <FaBusinessTime  className="me-1" /> Business Model Canvas
                            </Nav.Link>
                        </Nav.Item>
        
                        
                    </Nav>
                
            </Row>
        </Container>
    );
};

export default Indexbusinessmodal;
