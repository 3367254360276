import React, { useEffect, useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EosToolBox = () => {
    const [errors, setErrors] = useState({});
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Accountabilitypagename: "",
        Seatsingular: "",
        Seatsplural: "",

    });


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_EOS_toolboX}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    Accountabilitypagename: data.page_name || "",
                    Seatsingular: data.tool_sangular || "",
                    Seatsplural: data.tools_plural || "",

                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.Accountabilitypagename);
        formDataToSend.append('tool_sangular', formData.Seatsingular);
        formDataToSend.append('tools_plural', formData.Seatsplural);



        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_EOS_toolboX}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
      
          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
            console.error("Error during task submission:", error);

        }
    };


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="fa fa-graduation-cap mx-2"></i>EOS ToolboX™</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className={`form-control ${errors.Accountabilitypagename ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Page Name"
                                    name="Accountabilitypagename"
                                    value={formData.Accountabilitypagename}
                                    onChange={handleChange}
                                />
                                {errors.Accountabilitypagename && <div className="text-danger">{errors.Accountabilitypagename}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Tool (sangular)</b></label>
                                <input
                                    className={`form-control ${errors.Seatsingular ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Tool (sangular)"
                                    name="Seatsingular"
                                    value={formData.Seatsingular}
                                    onChange={handleChange}
                                />
                                {errors.Seatsingular && <div className="text-danger">{errors.Seatsingular}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Tools (plura)</b></label>
                                <input
                                    className={`form-control ${errors.Seatsplural ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Tools (plura)"
                                    name="Seatsplural"
                                    value={formData.Seatsplural}
                                    onChange={handleChange}
                                />
                                {errors.Seatsplural && <div className="text-danger">{errors.Seatsplural}</div>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EosToolBox;