import React, { useEffect, useState } from "react";
import { FaUnlockAlt } from "react-icons/fa";
import ChangePassword from "../../../pages/changepassword";
import CreateNewCompanyModal from "../modals/createNewCompanyModal";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
const AccountDetail = () => {
  const uid = localStorage.getItem("user_id");
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    PhoneNumber: "",
  });

  const togglePasswordModal = () => {
    setPasswordModalOpen(!passwordModalOpen);
  };

  const toggleCompanyModal = () => {
    setCompanyModalOpen(!companyModalOpen);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_login_info}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const number = response.data.data[0].phone;
        setFormData({ PhoneNumber: number });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <form>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Change Password</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 mb-3">
                <span>
                  We recommend changing your password every Founders OS days to
                  keep your account secure.
                </span>
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={togglePasswordModal}
                >
                  <FaUnlockAlt /> Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ChangePassword isOpen={passwordModalOpen} toggle={togglePasswordModal} />
      <CreateNewCompanyModal
        isOpen={companyModalOpen}
        toggle={toggleCompanyModal}
      />
    </>
  );
};

export default AccountDetail;
