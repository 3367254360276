import { Home, Box,  Airplay,  Cloud} from "react-feather";

export const MENUITEMS = [

  {
    title: "DashBoard",
    icon: Home,
    path: "/",
    type: "link",
    active: false,
  },

  {
    title: "Operations",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      { path: "/my-task/task", title: "My Tasks", type: "link" },
      { path: "/meeting/meetings", title: "Meetings", type: "link" },
      { path: "/project/project-name", title: "Projects", type: "link" },
      { path: "/people/pace", title: "Reporting", type: "link" },

      {
        title: "Departmental Notes and Best Practices",
        type: "sub",
        children: [
          { title: "sales", type: "link", path: "/notes-sales" },
          { title: "marketing", type: "link", path: "/notes-marketing" },
          { title: "finance", type: "link", path: "/notes-finanace" },
          { title: "human-resources", type: "link", path: "/notes-hr" },
          { title: "information-technology", type: "link", path: "/notes-it" },
          { title: "operations", type: "link", path: "/notes-operation" },
        ],
      },
    ],
  },
  {
    title: "Strategy",
    icon: Airplay,
    type: "sub",
    active: false,
    children: [
      { path: "/Strategic-goal/ninty-days-sprints", title: "Strategic Goals", type: "link" },
      { path: "/businessmodal/businessmodalcanvas", title: "Business Model", type: "link" },
      { path: "/corporate/foundationstory", title: "Corporate", type: "link" },
      { path: "/businesstool/people", title: "Business Tools", type: "link" },
      { path: "/strategy-deveopment/seven-strata", title: "Business Strategy", type: "link" },
      { path: "/growthframeworks/foundersOSchecklist", title: "Growth Frameworks", type: "link" },
    ],
  },
  {
    title: "Management",
    icon: Cloud,
    type: "sub",
    active: false,
    children: [
      { path: "/sales", title: "Process Workflows", type: "link" },
      { path: "/clients-name", title: "Clients", type: "link" },
      { path: "/labor-efficiency-ratio", title: "Cash Flow", type: "link" },
      { path: "/execution/personal-selling", title: "Business Execution", type: "link" },
      { path: "/people/face", title: "Business Roles & Responsibilities", type: "link" },
      { path: "/sops/sales", title: "Standard Operating Procedures (SOPs)", type: "link" },
    ],
  },

];
