import React, { useState, useEffect, useCallback } from "react";
import { Container, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../../../utils";
import _ from 'lodash';

const OnePagePersonalPlan = () => {
  const uid = localStorage.getItem("user_id");

  const [tableData, setTableData] = useState({
    faith: { Relationships_fa: "", Achievements_fa: "", Rituals_fa: "", Wealth_fa: "" },
    family: { Relationships_f: "", Achievements_f: "", Rituals_f: "", Wealth_f: "" },
    friends: { Relationships_Start: "", Achievements_Start: "", Rituals_Start: "", Wealth_Start: "" },
    finance: { Relationships_Stop: "", Achievements_Stop: "", Rituals_Stop: "", Wealth_Stop: "" },
  });

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.personal_plan_get}`,
        formDataToSend,
        {
          headers: { Authorization: `${API.AUTHORIZATION_TOKEN}` },
        }
      );

      if (response.data.status === "success") {
        const fetchedData = response.data.data;
        setTableData({
          faith: fetchedData.faith,
          family: fetchedData.family,
          friends: fetchedData.friends,
          finance: fetchedData.finance,
        });
      } else {
        toast.error(response.data.message || 'Failed to fetch data');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [uid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback((e, category, field) => {
    const { value } = e.target;
    const newData = {
      ...tableData,
      [category]: { ...tableData[category], [field]: value },
    };
    setTableData(newData);
    debouncedSave(newData);
  }, [tableData]);

  const saveData = async (updatedData) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('Relationships_fa', updatedData.faith.Relationships_fa);
      formDataToSend.append('Achievements_fa', updatedData.faith.Achievements_fa);
      formDataToSend.append('Rituals_fa', updatedData.faith.Rituals_fa);
      formDataToSend.append('Wealth_fa', updatedData.faith.Wealth_fa);
      formDataToSend.append('Relationships_f', updatedData.family.Relationships_f);
      formDataToSend.append('Achievements_f', updatedData.family.Achievements_f);
      formDataToSend.append('Rituals_f', updatedData.family.Rituals_f);
      formDataToSend.append('Wealth_f', updatedData.family.Wealth_f);
      formDataToSend.append('Relationships_Start', updatedData.friends.Relationships_Start);
      formDataToSend.append('Achievements_Start', updatedData.friends.Achievements_Start);
      formDataToSend.append('Rituals_Start', updatedData.friends.Rituals_Start);
      formDataToSend.append('Wealth_Start', updatedData.friends.Wealth_Start);
      formDataToSend.append('Relationships_Stop', updatedData.finance.Relationships_Stop);
      formDataToSend.append('Achievements_Stop', updatedData.finance.Achievements_Stop);
      formDataToSend.append('Rituals_Stop', updatedData.finance.Rituals_Stop);
      formDataToSend.append('Wealth_Stop', updatedData.finance.Wealth_Stop);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.personal_plan_update}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data.status === "success") {
        fetchData();
        toast.success(response.data.message || 'Data updated successfully');
      } else {
        toast.error(response.data.message || 'Failed to update data');
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error('An error occurred while updating the data.');
    }
  };

  const debouncedSave = useCallback(_.debounce((updatedData) => {
    saveData(updatedData);
  }, 2000), [uid]);

  const tableStyle = { border: "1px solid gray", borderCollapse: "collapse", width: "100%" };
  const thStyle = { border: "1px solid gray", padding: "8px", textAlign: "left", background: "#161b40", color: "white" };
  const tdStyle = { border: "1px solid gray", padding: "8px", textAlign: "left", height: "100px" };
  const textareaStyle = { width: "100%", height: "100%", border: "none", resize: "none" };

  return (
    <Container fluid={true}>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6 align-items-center d-flex">
                  <h5>One-Page Personal Plan (OPPP)</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="text-center"><Spinner animation="border" /></div>
              ) : (
                <form>
                  <div className="mt-4 life-planing-div">
                    <div className="table-responsive">
                      <table className="life-planing-table table table-bordered" style={tableStyle}>
                        <thead>
                          <tr>
                            <th style={thStyle}></th>
                            <th style={thStyle}>Relationships</th>
                            <th style={thStyle}>Achievements</th>
                            <th style={thStyle}>Rituals</th>
                            <th style={thStyle}>Wealth ($)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(tableData).map((category) => (
                            <tr key={category}>
                              <td style={tdStyle} className="vertical-text">{category.charAt(0).toUpperCase() + category.slice(1)}</td>
                              {Object.keys(tableData[category]).map((field) => (
                                <td style={tdStyle} key={field}>
                                  <textarea
                                    rows={10}
                                    value={tableData[category][field] === "undefined" ? "" : tableData[category][field]}
                                    onChange={(e) => handleChange(e, category, field)}
                                    style={textareaStyle}
                                  />

                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OnePagePersonalPlan;
