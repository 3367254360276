import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaEye, FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import DeleteprocessModal from "./modals/deleteprocessModal";
import EditprocessModal from "./modals/editprocessModal";
import ViewprocessModal from "./modals/viewprocessModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
const Meetingtypes = () => {
  const uid = localStorage.getItem("user_id");
  const [searchQuery, setSearchQuery] = useState("");
  const [generalData, setGeneralData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [gratitudeEntries, setGratitudeEntries] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [formData, setFormData] = useState({
    type: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    type: "",
  });

  useEffect(() => {
    fetchData();
  }, []);


    const fetchData = async () => {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
    
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_meeting_type}`, formDataToSend, {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        });
    
        if (response.data.status === "success") {
          const sortedTasks = response.data.info.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setGeneralData(sortedTasks);
          const sortedTaskss = response.data.info.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          // setGratitudeEntries(sortedTaskss); 
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Fetch gratitude error', error);
      }
    };
    


  const handleSave = async (e) => {

    e.preventDefault();
    if (validateForm()) {
      setFormData({ type: "" });

      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('name', formData.type);


      try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_meeting_type}`,
              formDataToSend,
              {
                  headers: {
                      Authorization: `${API.AUTHORIZATION_TOKEN}`
                  }
              });

        if (response.data.status === "success") {
          toast.success("Meeting Type Added Successfully");
          setFormData({ type: "" });
          setValidationErrors({});
          // toggleModal();
          fetchData()
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }


    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.type) {
      errors.type = "This Field is required";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };

  const columns = [
    { name: 'Meeting Type Name', selector: (row) => row.name, sortable: true },
    {
      name: 'Date',
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true
    },
    {
      name: 'Action',
      cell: (row, index) => (
        <>
          <div className="d-lg-flex">
            <Button className="m-1 btn-secondary btn-pill main-edit-view-buttons" onClick={() => handleView(row)}><FaEye /></Button>
            <Button className="m-1 btn-secondary btn-pill main-edit-view-buttons" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
            <Button variant="danger" className="m-1 btn-pill main-delete-buttons" onClick={() => { setIsDeleteModalOpen(true); setSelectedItemId(row.id); }}><MdDelete /></Button>          </div>
        </>
      ),
    },
  ];


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = generalData.filter(entry =>
    entry.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    let errors = { ...validationErrors };

    switch (name) {

      case 'type':
        errors.type = value.trim() ? '' : 'This Field is required';
        break;

      default:
        break;
    }

    setValidationErrors(errors);
  };

  const handleEdit = (task) => {
    setSelectedItemId(task.id);
    setFormData({ type: task.name });
    setShowEditModal(true);
  };

  const handleEditItem = async () => {
    if (validateForm()) {
      const formDataToSend = new FormData();

      formDataToSend.append('mtype_id', selectedItemId);
      formDataToSend.append('name', formData.type);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.edit_meeting_type}`, formDataToSend, {
          headers: { Authorization: `${API.AUTHORIZATION_TOKEN}` },
        });

        if (response.data.status === "success") {
          toast.success("Meeting Type Updating Successfully");
          setFormData({ type: "" });
          setValidationErrors({});
          fetchData();
          setShowEditModal(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('mtype_id', id);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.delete_meeting_type}`, formDataToSend, {
        headers: { Authorization: `${API.AUTHORIZATION_TOKEN}` },
      });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        toast.success("Meeting Type Deleting Successfully");
        setGeneralData(generalData.filter(item => item.id !== id)); // Remove item from the state
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <DeleteprocessModal
          show={isDeleteModalOpen}
          onHide={() => setIsDeleteModalOpen(false)}
          handleDelete={handleDelete}
          itemId={selectedItemId}
        />
        <EditprocessModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          formData={formData}
          validationErrors={validationErrors}
          handleInputChange={handleInputChange}
          handleEditItem={handleEditItem}
        />


        {isViewModalOpen && (

          <div className="card-body btn-showcase">

            <ViewprocessModal
              show={isViewModalOpen}
              onHide={toggleViewModal}
              selectedItem={selectedItem}
              thStyle={thStyle}
            />

          </div>
        )}
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Add New Meeting Agenda</h5>
                  </div>
                </div>


              </div>
              <div className="card-body">
                <form>
                  <div className='d-flex justify-content-between'>
                    <div className="form-group  mb-3 col-lg-4">

                      <label><b>Meeting Type Name </b></label>
                      <input className={`form-control ${validationErrors.type ? 'is-invalid' : ''}`} name="type" value={formData.type} type='text' onChange={handleInputChange} placeholder='Metting Type '>

                      </input>
                      {validationErrors.type && <p className="text-danger">{validationErrors.type}</p>}
                    </div>

                    <div className="d-flex justify-content-end align-content-center">
                      <Button variant="success" style={{ height: 'fit-content' }} className=' m-t-15' onClick={handleSave}>Save</Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <div className="form-group text-end mb-3 ">
                      <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div >
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    pagination
                    persistTableHead={true}

                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meetingtypes;
