import React, { useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function RiskAssessment() {
  const [selectedOption, setSelectedOption] = useState("");
  const [cultureOption, setCultureOption] = useState("");
  const [planningOption, setPlanningOption] = useState("");
  const [performanceOption, setPerformanceOption] = useState("");
  const [feedbackOption, setFeedbackOption] = useState("");
  const [valuesOption, setValuesOption] = useState("");
  const [innovationOption, setInnovationOption] = useState("");
  const [embeddingOption, setEmbeddingOption] = useState("");
  const [leadershipOption, setLeadershipOption] = useState("");
  const [rewardsOption, setRewardsOption] = useState("");
  const [rewardsStrategyOption, setRewardsStrategyOption] = useState("");
  const [RewardsDigitalContextOption, setRewardsDigitalContextOption] =
    useState("");
  const uid = localStorage.getItem("user_id");

  const updateTask = async (formDataToSend) => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_update_risk_assesment}`,
        formDataToSend,
        {
          headers: {
            Authorization: API.AUTHORIZATION_TOKEN,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating data.");
    }
  };

  useEffect(() => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("Funding_Growth_Sources_Capital", selectedOption);
    formDataToSend.append("Funding_Growth_Working_Capital", cultureOption);
    formDataToSend.append("Funding_Growth", planningOption);
    formDataToSend.append("People_Organization_People", performanceOption);
    formDataToSend.append("People_Organization_Organization", feedbackOption);
    formDataToSend.append(
      "Finance_Process_Controls_Finance_Processes",
      valuesOption
    );
    formDataToSend.append(
      "Finance_Process_Controls_Finance_Controls",
      innovationOption
    );
    formDataToSend.append("Data_Technology_Technology", embeddingOption);
    formDataToSend.append("Data_Technology_Data", leadershipOption);
    formDataToSend.append(
      "Stakeholder_Management_Stakeholder_Identification",
      rewardsOption
    );
    formDataToSend.append("Stakeholder_Management", rewardsStrategyOption);
    formDataToSend.append("Rewards_Strategy", RewardsDigitalContextOption);
    if (
      selectedOption ||
      cultureOption ||
      planningOption ||
      performanceOption ||
      feedbackOption ||
      valuesOption ||
      innovationOption ||
      embeddingOption ||
      leadershipOption ||
      rewardsOption ||
      rewardsStrategyOption ||
      RewardsDigitalContextOption
    ) {
      updateTask(formDataToSend);
    }
  }, [
    selectedOption,
    cultureOption,
    planningOption,
    performanceOption,
    feedbackOption,
    valuesOption,
    innovationOption,
    embeddingOption,
    leadershipOption,
    rewardsOption,
    rewardsStrategyOption,
    RewardsDigitalContextOption,
  ]);

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  // useEffect(() => {
  //     const formDataToSend = new FormData();
  //     formDataToSend.append('user_id', uid);
  //     formDataToSend.append('Funding_Growth_Sources_Capital', selectedOption);
  //     formDataToSend.append('Funding_Growth_Working_Capital', cultureOption );
  //     formDataToSend.append('Funding_Growth', planningOption );
  //     formDataToSend.append('People_Organization_People', performanceOption );
  //     formDataToSend.append('People_Organization_Organization', feedbackOption );
  //     formDataToSend.append('Finance_Process_Controls_Finance_Processes', valuesOption );
  //     formDataToSend.append('Finance_Process_Controls_Finance_Controls', innovationOption );
  //     formDataToSend.append('Data_Technology_Technology', embeddingOption );
  //     formDataToSend.append('Data_Technology_Data', leadershipOption );
  //     formDataToSend.append('Stakeholder_Management_Stakeholder_Identification', rewardsOption );
  //     formDataToSend.append('Stakeholder_Management', rewardsStrategyOption );
  //     formDataToSend.append('Rewards_Strategy', RewardsDigitalContextOption );

  //     debouncedUpdateTask(formDataToSend);
  // }, [
  //     selectedOption,
  //     cultureOption,
  //     planningOption,
  //     performanceOption,
  //     feedbackOption,
  //     valuesOption,
  //     innovationOption,
  //     embeddingOption,
  //     leadershipOption,
  //     rewardsOption,
  //     rewardsStrategyOption,
  //     RewardsDigitalContextOption,
  //     debouncedUpdateTask,
  //     uid
  // ]);


  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_growth_risk}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const jsonData = response.data.data.risk_assesment;

        if (jsonData) {
          let data;
          try {
            data = JSON.parse(jsonData);

            setSelectedOption(data.Funding_Growth_Sources_Capital);
            setCultureOption(data.Funding_Growth_Working_Capital);
            setPlanningOption(data.Funding_Growth);
            setPerformanceOption(data.People_Organization_People);
            setFeedbackOption(data.People_Organization_Organization);
            setValuesOption(data.Finance_Process_Controls_Finance_Processes);
            setInnovationOption(data.Finance_Process_Controls_Finance_Controls);
            setEmbeddingOption(data.Data_Technology_Technology);
            setLeadershipOption(data.Data_Technology_Data);
            setRewardsOption(
              data.Stakeholder_Management_Stakeholder_Identification
            );
            setRewardsStrategyOption(data.Stakeholder_Management);
            setRewardsDigitalContextOption(data.Rewards_Strategy);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        }
      }
    } catch (error) {
      console.error("Fetch meeting types error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const calculatePercentage = () => {
    const values = {
      Leading: 8.34,
      Established: 5.56,
      Developing: 2.78,
    };

    const scores = [
      values[selectedOption] || 0,
      values[cultureOption] || 0,
      values[planningOption] || 0,
      values[performanceOption] || 0,
      values[feedbackOption] || 0,
      values[valuesOption] || 0,
      values[innovationOption] || 0,
      values[embeddingOption] || 0,
      values[leadershipOption] || 0,
      values[rewardsOption] || 0,
      values[rewardsStrategyOption] || 0,
      values[RewardsDigitalContextOption] || 0,
    ];

    const totalScore = scores.reduce((acc, score) => acc + score, 0);
    const maxScore = 12 * 8.34; // 12 questions, max value of 8.34
    return (totalScore / maxScore) * 100;
  };

  useEffect(() => {
    // Calculate and store the percentage
    const percentage = calculatePercentage();

    // Set the percentage in local storage
    localStorage.setItem("risckCompletionPercentage", percentage);

  }, [
    selectedOption,
    cultureOption,
    planningOption,
    performanceOption,
    feedbackOption,
    valuesOption,
    innovationOption,
    embeddingOption,
    leadershipOption,
    rewardsOption,
    rewardsStrategyOption,
    RewardsDigitalContextOption,
  ]); // Dependencies to recalculate when any option changes

  const percentage = calculatePercentage();
  return (
    <div className="col-sm-12">
      <div className="card mt-4">
        <div className="card-header">
          <div className="justify-content-between align-items-center d-flex">
            <div className=" align-items-center d-flex">
              <h5>Risk Assessment</h5>
            </div>

            <div className="ms-3 align-items-center d-flex ms-3">
              <div className="m-r-15">
                <Link
                  className="backto-ondriver"
                  to="/growthframeworks/founderdriver"
                >
                  Back to the Founder OS Drivers of Growth
                </Link>
              </div>
              <CircularProgressbar
                className="assessmentprogress"
                value={percentage}
                text={`${Math.round(percentage)}%`}
                styles={{
                  path: {
                    stroke: "green", // Change the stroke color to black
                  },
                  text: {
                    fill: "black", // Change the text color to black
                    fontSize: "16px", // Optional: Adjust font size
                  },
                  trail: {
                    stroke: "#d6d6d6", // Optional: Change the trail color (if you want)
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card-body">
            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Governance – Structure</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  Please select the radio button that best describes where you
                  are in your business. (Leading, Established, Developing)
                </p>
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={selectedOption === "Leading"}
                      onChange={handleChange(setSelectedOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There is a strong governance structure in place, including
                  an independent board of directors with strong industry
                  expertise and other active committees (e.g. audit, risk and
                  compensation).
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={selectedOption === "Established"}
                      onChange={handleChange(setSelectedOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The board of directors and audit committee are actively
                  involved in oversight.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={selectedOption === "Developing"}
                      onChange={handleChange(setSelectedOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • A board of directors or informal advisory board exists.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Governance – Responsibilities </strong>
                  </b>
                </label>
                <p className="textnots-text">
                  Please select the radio button that best describes where you
                  are in your business. (Leading, Established, Developing)
                </p>
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={cultureOption === "Leading"}
                      onChange={handleChange(setCultureOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The 'three lines of defense' model (leadership, risk
                  management and internal audit) is well-defined and fully
                  understood across the organization.
                </p>
                <p className="textnots-text">
                  • Leadership set the risk culture and appetite in a tone from
                  the top.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={cultureOption === "Established"}
                      onChange={handleChange(setCultureOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Independent risk and control functions roles and
                  responsibilities are clearly defined and documented and follow
                  the three lines of defense model.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={cultureOption === "Developing"}
                      onChange={handleChange(setCultureOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Leaders of the business are responsible for Governance and
                  they tend to manage this in silos.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Risk Management</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  Please select the radio button that best describes where you
                  are in your business. (Leading, Established, Developing){" "}
                </p>
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={planningOption === "Leading"}
                      onChange={handleChange(setPlanningOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Risk strategy is clearly articulated and rigorously applied
                  to promote decisions at all levels.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={planningOption === "Established"}
                      onChange={handleChange(setPlanningOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Risk management influences all key expenditure.{" "}
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={planningOption === "Developing"}
                      onChange={handleChange(setPlanningOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Management shares responsibility for identifying,
                  documenting and regularly reviewing key risks that are
                  critical to achieving business objectives.{" "}
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Risk Management –Risk Identification Management
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={performanceOption === "Leading"}
                      onChange={handleChange(setPerformanceOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Key business risks are aligned to Key Performance Indicators
                  (KPIs) and managed to ensure business objectives are met.
                </p>
                <p className="textnots-text">
                  • Risk is embedded in the operational culture of the business.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={performanceOption === "Established"}
                      onChange={handleChange(setPerformanceOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There is a structured approach to identifying, monitoring
                  and assessing key risks and controls (e.g. tax, reputation and
                  operations).
                </p>
                <p className="textnots-text">
                  • Risk management is clearly articulated in all role
                  descriptions and performance criteria.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={performanceOption === "Developing"}
                      onChange={handleChange(setPerformanceOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Responsible risk-taking is seen as core to business success.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Risk Management –Creating Value from Risk Management
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={feedbackOption === "Leading"}
                      onChange={handleChange(setFeedbackOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Efficient and effective enterprise-wide risk management
                  delivers a competitive advantage, improved business
                  performance, better profits, well-managed and secure growth
                  (organic and acquisitive) and efficient use of capital.
                </p>
                <p className="textnots-text">
                  • Risk management features regularly in investor and analyst
                  dialogue, attracts investment and drives a lower cost of
                  capital.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={feedbackOption === "Established"}
                      onChange={handleChange(setFeedbackOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Legal and other compliance activities are actively managed.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={feedbackOption === "Developing"}
                      onChange={handleChange(setFeedbackOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Legal and other compliance activities are actively managed.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Policies, Procedures and Compliance – Policy
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={valuesOption === "Leading"}
                      onChange={handleChange(setValuesOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There is a comprehensive, documented, group-wide policy
                  framework to manage business risks (e.g. strategic,
                  operational, finance, tax and compliance), with a strong
                  leadership tone from the top.
                </p>
                <p className="textnots-text">
                  • The risk framework extends to external stakeholders in the
                  value chain.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={valuesOption === "Established"}
                      onChange={handleChange(setValuesOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Individual functions have formally documented policies and
                  procedures. These are aligned to overall business strategies
                  and objectives.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={valuesOption === "Developing"}
                      onChange={handleChange(setValuesOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Management leads the development and control of risk
                  policies and procedures for major risks.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Policies, Procedures and Compliance – Compliance
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={innovationOption === "Leading"}
                      onChange={handleChange(setInnovationOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business contributes to the development of the
                  regulatory landscape (e.g. finance, tax and government
                  policy).
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={innovationOption === "Established"}
                      onChange={handleChange(setInnovationOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There is an independent compliance function to manage
                  internal and external regulations.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={innovationOption === "Developing"}
                      onChange={handleChange(setInnovationOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business has a reactive approach to complying with
                  internal and external regulations.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Policies, Procedures and Compliance –Technology
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={embeddingOption === "Leading"}
                      onChange={handleChange(setEmbeddingOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Governance, risk and compliance (GRC) technologies are used
                  in a coordinated and integrated manner to support risk
                  activities across the organization and to provide real-time,
                  predictive risk intelligence.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={embeddingOption === "Established"}
                      onChange={handleChange(setEmbeddingOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Governance, risk and compliance (GRC) technologies, such as
                  software packages and platforms, are used to enable select
                  risk activities (e.g. policy management, risk management,
                  audit management).
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={embeddingOption === "Developing"}
                      onChange={handleChange(setEmbeddingOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Microsoft Excel, Microsoft Access and other products are
                  used to enable risk activities such as risk identification,
                  assessment and reporting.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Stakeholder Management – Communication</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={leadershipOption === "Leading"}
                      onChange={handleChange(setLeadershipOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Risk management is a differentiating factor in stakeholder
                  investment and results in high stakeholder confidence and
                  trust.
                </p>
                <p className="textnots-text">
                  • The company proactively communicates its risk management
                  program to all stakeholder groups tailored to their needs and
                  expectations.
                </p>
                <p className="textnots-text">
                  • The business publishes annual corporate social
                  responsibility reports (e.g. sustainability).
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={leadershipOption === "Established"}
                      onChange={handleChange(setLeadershipOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • A comprehensive risk framework describing all risk
                  categories and risk appetite is documented and distributed
                  internally.
                </p>
                <p className="textnots-text">
                  • The business understands stakeholder needs and is
                  increasingly proactive and transparent in its risk
                  communications.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={leadershipOption === "Developing"}
                      onChange={handleChange(setLeadershipOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Key risks are documented and discussed on a regular basis by
                  leadership.
                </p>
                <p className="textnots-text">
                  • Stakeholder risk communication is selective and mostly
                  reactive.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>
                      Risk – Stakeholder Management – Stakeholder Identification
                    </strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={rewardsOption === "Leading"}
                      onChange={handleChange(setRewardsOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Key stakeholders are banks, primary investors, regulators,
                  people, policy-makers and communities.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={rewardsOption === "Established"}
                      onChange={handleChange(setRewardsOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Key stakeholders are banks, primary investors, regulators
                  and people.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={rewardsOption === "Developing"}
                      onChange={handleChange(setRewardsOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Key stakeholders are banks and primary investors.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Risk – Stakeholder Management</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={rewardsStrategyOption === "Leading"}
                      onChange={handleChange(setRewardsStrategyOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business clearly articulates its whole corporate 'story'
                  and tailors it to individual stakeholders.
                </p>
                <p className="textnots-text">
                  • The business builds further trust and confidence with
                  stakeholders by pro-actively reporting on its performance
                  beyond just the financials (e.g. environmental, social impact,
                  governance (ESG) and diversity) as part of its long-term value
                  creation.
                </p>
                <p className="textnots-text">
                  • There is a track record of consistent delivery against
                  promises - no surprises.{" "}
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={rewardsStrategyOption === "Established"}
                      onChange={handleChange(setRewardsStrategyOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There is visible evidence of links between high performance,
                  rewards and the achievement of business objectives.
                </p>
                <p className="textnots-text">
                  • There are clearly defined roles and responsibilities to
                  manage key stakeholders.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={rewardsStrategyOption === "Developing"}
                      onChange={handleChange(setRewardsStrategyOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • The business builds trust with stakeholders by focusing on
                  compliance and statutory requirements.
                </p>
              </div>
            </div>

            <div className="founderdriers mb-3">
              <p className="textnots-text">
                <label>
                  <b>
                    <strong>Risk - Rewards - Strategy</strong>
                  </b>
                </label>
              </p>
              <p className="textnots-text">
                Please select the radio button that best describes where you are
                in your business. (Leading, Established, Developing)
              </p>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Leading"
                      checked={RewardsDigitalContextOption === "Leading"}
                      onChange={handleChange(setRewardsDigitalContextOption)}
                    />
                    <strong>Leading</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Behaviors, as well as outcomes, are rewarded for both the
                  team and the individual.
                </p>
                <p className="textnots-text">
                  • A strong employer brand is used to attract, motivate and
                  retain high performers and teams.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Established"
                      checked={RewardsDigitalContextOption === "Established"}
                      onChange={handleChange(setRewardsDigitalContextOption)}
                    />
                    <strong>Established</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • There are various incentives and total rewards programs
                  segmented to specific employee populations.
                </p>
              </div>

              <div className="mb-2">
                <label>
                  <b>
                    <input
                      className="radio_animated"
                      type="radio"
                      value="Developing"
                      checked={RewardsDigitalContextOption === "Developing"}
                      onChange={handleChange(setRewardsDigitalContextOption)}
                    />
                    <strong>Developing</strong>
                  </b>
                </label>
                <p className="textnots-text">
                  • Rewards are provided to attract and retain individuals and
                  to meet business objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RiskAssessment;
