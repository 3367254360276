import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import API from "../../../../utils";
import { toast } from "react-toastify";
const AddLicenseModal = ({ show, handleClose, fetchData }) => {
  const user_id = localStorage.getItem("user_id");
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const newErrors = {};
    const cardNumberRegex = /^[0-9]{16}$/;
    const cvcRegex = /^[0-9]{3}$/;

    if (!cardNumber) newErrors.cardNumber = "Card number is required.";
    else if (!cardNumberRegex.test(cardNumber)) newErrors.cardNumber = "Invalid card number.";

    if (!expMonth) newErrors.expMonth = "Expiration month is required.";
    else if (isNaN(expMonth) || expMonth < 1 || expMonth > 12) newErrors.expMonth = "Invalid month. Must be between 1 and 12.";

    if (!expYear) newErrors.expYear = "Expiration year is required.";
    else if (isNaN(expYear) || expYear.length !== 4) newErrors.expYear = "Invalid year. Must be 4 digits.";

    if (!cvc) newErrors.cvc = "CVC is required.";
    else if (!cvcRegex.test(cvc)) newErrors.cvc = "Invalid CVC. Must be 3 digits.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddLicense = async () => {
    if (!validateFields()) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("card_number", cardNumber);
    formData.append("exp_month", expMonth);
    formData.append("exp_year", expYear);
    formData.append("cvc", cvc);
    formData.append("price", 300);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_license}`,
        formData,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        fetchData();
        handleClose();
        toast.success(response.data.message);
      } else {
      }
    } catch (error) {
      console.error("Error adding license:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add License</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="form-group">
          <label>Card Number</label>
          <input
            type="text"
            className={`form-control ${errors.cardNumber ? "is-invalid" : ""}`}
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="Enter card number"
          />
          {errors.cardNumber && <div className="invalid-feedback">{errors.cardNumber}</div>}
        </div>
        <div className="form-group">
          <label>Expiration Month</label>
          <input
            type="text"
            className={`form-control ${errors.expMonth ? "is-invalid" : ""}`}
            value={expMonth}
            onChange={(e) => setExpMonth(e.target.value)}
            placeholder="MM"
          />
          {errors.expMonth && <div className="invalid-feedback">{errors.expMonth}</div>}
        </div>
        <div className="form-group">
          <label>Expiration Year</label>
          <input
            type="text"
            className={`form-control ${errors.expYear ? "is-invalid" : ""}`}
            value={expYear}
            onChange={(e) => setExpYear(e.target.value)}
            placeholder="YYYY"
          />
          {errors.expYear && <div className="invalid-feedback">{errors.expYear}</div>}
        </div>
        <div className="form-group">
          <label>CVC</label>
          <input
            type="text"
            className={`form-control ${errors.cvc ? "is-invalid" : ""}`}
            value={cvc}
            onChange={(e) => setCvc(e.target.value)}
            placeholder="CVC"
          />
          {errors.cvc && <div className="invalid-feedback">{errors.cvc}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddLicense} disabled={loading}>
          {loading ? "Adding..." : "Add License"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLicenseModal;
