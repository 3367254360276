import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import { Card, CardBody, CardHeader, Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../utils';

const ProjectGanttChart = () => {
  const location = useLocation();
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  useEffect(() => {
    const id = getQueryParam('id');
    if (id) {
      fetchTasks(id);
    }
  }, [location.search]);

  const fetchTasks = async (projectId) => {
    const uid = localStorage.getItem('user_id');
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('id', projectId);
    setLoading(true);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_project_gantt}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        const fetchedTasks = response.data.info.map((task) => {
          const startDate = Date.parse(task.created_date);
          const dueDate = Date.parse(task.due_date);

          if (isNaN(startDate) || isNaN(dueDate)) {
            console.warn('Invalid dates for task:', task);
            return null;
          }

          return [
            task.project_name || 'Unnamed Task',
            task.project_name || 'Unnamed Task',
            new Date(startDate),
            new Date(dueDate),
            null,
            75,
            null,
          ];
        }).filter(task => task !== null);

        setProjectData(fetchedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
      toast.error('Error fetching tasks');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { type: 'string', label: 'Task ID' },
    { type: 'string', label: 'Task Name' },
    { type: 'date', label: 'Start Date' },
    { type: 'date', label: 'End Date' },
    { type: 'number', label: 'Duration' },
    { type: 'number', label: 'Percent Complete' },
    { type: 'string', label: 'Dependencies' },
  ];

  return (
    <Container fluid={true}>
      <Card>
        <CardHeader>
          <h5>Project Gantt Chart</h5>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <div className="custom-gantt-container">
              <Chart
                chartType="Gantt"
                width="100%"
                height="500px"
                data={[columns, ...projectData]}
                options={{
                  gantt: {
                    trackHeight: 30,
                    criticalPathEnabled: true,
                    criticalPathStyle: {
                      stroke: '#e64a19',
                      strokeWidth: 3,
                    },
                    arrow: {
                      color: '#d9d9d9',
                      length: 8,
                      radius: 5,
                    },
                    innerGridTrack: { fill: '#f2f2f2' },
                    innerGridDarkTrack: { fill: '#eaeaea' },
                    labelStyle: {
                      fontName: 'Arial',
                      fontSize: 12,
                      color: '#333',
                    },
                  },
                }}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ProjectGanttChart;
