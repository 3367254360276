import React from 'react';
import { Modal, Button, Form} from 'react-bootstrap';
import { MdDelete } from "react-icons/md";

const AddAvatarModal = ({ show, onHide, formData, onChange, onSave, onAddField, onRemoveField }) => {

    const handleAddFieldClick = (field) => {
        if (onAddField) {
            onAddField(field);
        } else {
            console.error('onAddField function is not defined');
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Add New Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row">
                    <div className='col-lg-6'>
                        <div className="form-group">
                            <label className="col-form-label pt-0 mt-2" htmlFor="image"><b>Avatar Image</b></label>
                            <input
                                className="form-control"
                                name="image"
                                type="file"
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formName">
                            <Form.Label className='mt-2'>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formAge">
                            <Form.Label className='mt-2'>Age</Form.Label>
                            <Form.Control
                                type="text"
                                name="age"
                                value={formData.age || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>

                        <Form.Group controlId="formGender">
                            <Form.Label className='mt-2'>Gender</Form.Label>
                            <Form.Control
                                type="text"
                                name="gender"
                                value={formData.gender || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formLocation">
                            <Form.Label className='mt-2'>Location</Form.Label>
                            <Form.Control
                                type="text"
                                name="location"
                                value={formData.location || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formProfession">
                            <Form.Label className='mt-2'>Profession</Form.Label>
                            <Form.Control
                                type="text"
                                name="profession"
                                value={formData.profession || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formHobbies">
                            <Form.Label className='mt-2'>Hobbies</Form.Label>
                            <Form.Control
                                type="text"
                                name="hobbies"
                                value={formData.hobbies || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formEducation">
                            <Form.Label className='mt-2'>Education</Form.Label>
                            <Form.Control
                                type="text"
                                name="education"
                                value={formData.education || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formIncome">
                            <Form.Label className='mt-2'>Income</Form.Label>
                            <Form.Control
                                type="text"
                                name="income"
                                value={formData.income || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formFamily">
                            <Form.Label className='mt-2'>Family</Form.Label>
                            <Form.Control
                                type="text"
                                name="family"
                                value={formData.family || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formInfluencers">
                            <Form.Label className='mt-2'>Influencers</Form.Label>
                            <Form.Control
                                type="text"
                                name="Influencers"
                                value={formData.Influencers || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formBrandTheyLove">
                            <Form.Label className='mt-2'>Brands They Love</Form.Label>
                            <Form.Control
                                type="text"
                                name="brand_they_love"
                                value={formData.brand_they_love || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formSkills">
                            <Form.Label className='mt-2'>Skills/Expertise</Form.Label>
                            <Form.Control
                                type="text"
                                name="skills"
                                value={formData.skills || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formSources">
                            <Form.Label className='mt-2'>Sources</Form.Label>
                            <Form.Control
                                type="text"
                                name="sources"
                                value={formData.sources || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        <Form.Group controlId="formSocialMediaUse">
                            <Form.Label className='mt-2'>Social Media Used</Form.Label>
                            <Form.Control
                                type="text"
                                name="social_media_use"
                                value={formData.social_media_use || ''}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        {/* Motivations */}
                        <Form.Group>
                            <Form.Label className='mt-2'>Motivations</Form.Label>
                            {Array.isArray(formData.Motivations) && formData.Motivations.map((motivation, index) => (
                                <div key={index} className="d-flex align-items-center my-1">
                                    <Form.Control
                                        type="text"
                                        value={motivation}
                                        onChange={(e) => {
                                            const newMotivations = [...formData.Motivations];
                                            newMotivations[index] = e.target.value;
                                            onChange({ target: { name: 'Motivations', value: newMotivations } });
                                        }}
                                    />
                                    <button className='ms-2 btn-danger' onClick={() => onRemoveField('Motivations', index)}><MdDelete /></button>
                                </div>
                            ))}
                            <Button onClick={() => handleAddFieldClick('Motivations')}>Add Motivation</Button>
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>

                        {/* Pain Points */}
                        <Form.Group>
                            <Form.Label className='mt-2'>Pain Points</Form.Label>
                            {Array.isArray(formData.Pain_Points) && formData.Pain_Points.map((painPoint, index) => (
                                <div key={index} className="d-flex align-items-center my-1">
                                    <Form.Control
                                        type="text"
                                        value={painPoint}
                                        onChange={(e) => {
                                            const newPainPoints = [...formData.Pain_Points];
                                            newPainPoints[index] = e.target.value;
                                            onChange({ target: { name: 'Pain_Points', value: newPainPoints } });
                                        }}
                                    />
                                    <button className='ms-2 btn-danger' onClick={() => onRemoveField('Pain_Points', index)}><MdDelete /></button>
                                </div>
                            ))}
                            <Button onClick={() => handleAddFieldClick('Pain_Points')}>Add Pain Point</Button>
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>

                        {/* Wants & Needs */}
                        <Form.Group>
                            <Form.Label className='mt-2'>Wants & Needs</Form.Label>
                            {Array.isArray(formData.Wants_Needs) && formData.Wants_Needs.map((wantNeed, index) => (
                                <div key={index} className="d-flex align-items-center my-1">
                                    <Form.Control
                                        type="text"
                                        value={wantNeed}
                                        onChange={(e) => {
                                            const newWantsNeeds = [...formData.Wants_Needs];
                                            newWantsNeeds[index] = e.target.value;
                                            onChange({ target: { name: 'Wants_Needs', value: newWantsNeeds } });
                                        }}
                                    />
                                    <button className='ms-2 btn-danger' onClick={() => onRemoveField('Wants_Needs', index)}><MdDelete /></button>
                                </div>
                            ))}
                            <Button onClick={() => handleAddFieldClick('Wants_Needs')}>Add Want/Need</Button>
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>

                        {/* Opportunities */}
                        <Form.Group>
                            <Form.Label className='mt-2'>Opportunities</Form.Label>
                            {Array.isArray(formData.Opportunities) && formData.Opportunities.map((opportunity, index) => (
                                <div key={index} className="d-flex align-items-center my-1">
                                    <Form.Control
                                        type="text"
                                        value={opportunity}
                                        onChange={(e) => {
                                            const newOpportunities = [...formData.Opportunities];
                                            newOpportunities[index] = e.target.value;
                                            onChange({ target: { name: 'Opportunities', value: newOpportunities } });
                                        }}
                                    />
                                    <button className='ms-2 btn-danger' onClick={() => onRemoveField('Opportunities', index)}><MdDelete /></button>
                                </div>
                            ))}
                            <Button onClick={() => handleAddFieldClick('Opportunities')}>Add Opportunity</Button>
                        </Form.Group>
                    </div>
                    <div className='col-lg-6'>
                        {/* Resources */}
                        <Form.Group>
                            <Form.Label className='mt-2'>Resources</Form.Label>
                            {Array.isArray(formData.Resources) && formData.Resources.map((resource, index) => (
                                <div key={index} className="d-flex align-items-center my-1">
                                    <Form.Control
                                        type="text"
                                        value={resource}
                                        onChange={(e) => {
                                            const newResources = [...formData.Resources];
                                            newResources[index] = e.target.value;
                                            onChange({ target: { name: 'Resources', value: newResources } });
                                        }}
                                    />
                                    <button className='ms-2 btn-danger' onClick={() => onRemoveField('Resources', index)}><MdDelete />
                                    </button>
                                </div>
                            ))}
                            <Button onClick={() => handleAddFieldClick('Resources')}>Add Resource</Button>
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="success" onClick={onSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddAvatarModal;
