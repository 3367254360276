import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../../utils";
import { debounce } from 'lodash';

const Social = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({ linkedIn: '' });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (uid) {
      fetchData();
    }
  }, [uid]);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_social_data}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const linkedIn = response.data.data[0].linkedIn;
        setFormData({ linkedIn });
        setErrors({}); // Clear errors if data is fetched successfully
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validateURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });

    // Call the debounced function for API submission
    debouncedUpdateProfile(value);
  };

  // Debounced function to update profile
  const debouncedUpdateProfile = debounce(async (linkedIn) => {
    const newErrors = {};
    if (!linkedIn) {
      newErrors.linkedIn = 'LinkedIn URL is required';
    } else if (!validateURL(linkedIn)) {
      newErrors.linkedIn = 'Please enter a valid URL';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop execution if there are validation errors
    }

    // Prepare data for submission
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('linkedIn', linkedIn);

    try {
      // Send the data to the API
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_social}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message); // Notify success
        // Fetch updated data to reflect changes immediately
        fetchData();
      } else {
        toast.error(response.data.message); // Notify error
      }
    } catch (error) {
      console.error("Error during submission:", error); // Log error
      toast.error('An error occurred while submitting the form.'); // Notify error
    }
  }, 1000); // Debounce time in milliseconds

  return (
    <form className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Social Media</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group mb-3">
              <label className="form-label"><b>LinkedIn</b></label>
              <input
                className={`form-control ${errors.linkedIn ? 'is-invalid' : ''}`}
                type="text"
                placeholder="Enter LinkedIn URL"
                value={formData.linkedIn}
                name="linkedIn"
                onChange={handleChange}
              />
              {errors.linkedIn && (
                <div className="invalid-feedback">
                  {errors.linkedIn}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Social;
