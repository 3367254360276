import React, { useState, useEffect } from "react";
import man from "../../assets/images/user/user_default.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Login, Password } from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import Loginbg from "../../assets/images/logo/bg.png";
import API from '../../utils';
import MyLoader from "../../components/common/myloader";

const AdminPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [role, setRole] = useState(2);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginSuccess = (userData) => {
    localStorage.setItem("login", JSON.stringify(userData));
    localStorage.setItem("profileURL", userData.profileURL || man);
    localStorage.setItem("user_name", userData.user_name);
  };

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!email) {
      emailError = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Email is invalid.";
    }

    if (!password) {
      passwordError = "Password is required.";
    } else if (password.length < 6) {
      passwordError = "Password must be at least 6 characters.";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return false;
    }

    return true;
  };

  useEffect(() => {
    const currentRole = window.location.pathname.includes('/admin') ? 1 : 2;
    setRole(currentRole);

    if (currentRole === 1) {
      sessionStorage.setItem("role", true);
    } else {
      sessionStorage.setItem("role", false);
    }
  }, [location.pathname]);

  const loginAuth = async (e) => {
    e.preventDefault();

    // if (email !== "admin@gmail.com" || password !== "123456") {
    //   toast.error("Invalid credentials. Please enter the correct admin email and password.");
    //   return;
    // }

    const isValid = validate();
    if (!isValid) return;

    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('password', password);
    formDataToSend.append('user_role', "1");

    try {
      const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.login}`, {
        method: 'POST',
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (data.status === "success") {
        toast.success(data.message);
        localStorage.setItem("user_id", data.user_data.id);
        localStorage.setItem("user_name", data.user_data.user_name);
        localStorage.setItem("email", data.user_data.email);

        const userData = {
          email: data.user_data.email,
          name: data.user_data.name,
          profileURL: data.user_data.profileURL || man,
          user_name: data.user_data.user_name,
        };

        toast.success(data.message);
        navigate(`/admin/user`);
        handleLoginSuccess(userData);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MyLoader active={loading}>
        <div className="login-container d-flex">
          <div className="background-image">
            <div className="p-3 text-white justify-content-end text-end align-items-center">
              <div className="d-flex justify-content-end">
                <img src={Loginbg} className="Logosvg-main1" alt="background" />
              </div>
              <div className="d-flex align-items-lg-center">
                <h2 className="text-white mb-0 fw-bold">
                  Your Roadmap to a $1 <br /> Million Business & Beyond
                </h2>
              </div>
            </div>
          </div>
          <div className="login-box w-50">
            <div className="auth-heding-main">
              <h2 className="auth-heding">Admin</h2>
              <p className="auth-peregraft">Enter your Email and Password</p>
            </div>
            <form>
              <div className="form-group">
                <label className="col-form-label pt-0">Email</label>
                <input
                  className={`form-control ${emailError ? 'is-invalid' : ''}`}
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">{Password}</label>
                <input
                  className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  placeholder="**********"
                />
                {passwordError && <div className="text-danger">{passwordError}</div>}
              </div>

              <div className="form-group mt-3 mb-0 d-grid">
                <button className="login-button" onClick={loginAuth}>
                  {Login}
                </button>
              </div>
            </form>
          </div>
        </div>
      </MyLoader>
      <ToastContainer />
    </>
  );
};

export default AdminPage;
