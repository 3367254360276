import React, { useEffect, useState } from "react";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Directory = () => {
    const [errors, setErrors] = useState({});
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Accountabilitypagename: "",
        Owner: "",
        Admin: "",
        Manager: "",
        SeutHoldersPlural: "",
        Stepe: "",
        Observer: "",
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
          const response = await axios.post(
            `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_directory}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
    
          if (response.data.status === 'success') {
     
            const data = response.data.data[0];
            setFormData({
                Accountabilitypagename: data.page_name || "",
                Owner: data.owner || "",
                Admin: data.admin || "",
                Manager: data.manager || "",
                SeutHoldersPlural: data.managne || "",
                Stepe: data.stepe_plural || "",
                Observer: data.observer || "",
            });
          } else {
            toast.error(response.data.message);
                }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.Accountabilitypagename);
        formDataToSend.append('owner', formData.Owner);
        formDataToSend.append('admin', formData.Admin);
        formDataToSend.append('manager', formData.Manager);
        formDataToSend.append('managne', formData.SeutHoldersPlural);
        formDataToSend.append('stepe_plural', formData.Stepe);
        formDataToSend.append('observer', formData.Observer);

        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_directory}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
      
          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
            console.error("Error during task submission:", error);

        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="fa fa-folder-open-o mx-2"></i>Directory</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className={`form-control ${errors.Accountabilitypagename ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Page Name"
                                    name="Accountabilitypagename"
                                    value={formData.Accountabilitypagename}
                                    onChange={handleChange}
                                />
                                {errors.Accountabilitypagename && <div className="text-danger">{errors.Accountabilitypagename}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Owner</b></label>
                                <input
                                    className={`form-control ${errors.Owner ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Owner"
                                    name="Owner"
                                    value={formData.Owner}
                                    onChange={handleChange}
                                />
                                {errors.Owner && <div className="text-danger">{errors.Owner}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Admin</b></label>
                                <input
                                    className={`form-control ${errors.Admin ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Admin Name"
                                    name="Admin"
                                    value={formData.Admin}
                                    onChange={handleChange}
                                />
                                {errors.Admin && <div className="text-danger">{errors.Admin}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Manager</b></label>
                                <input
                                    className={`form-control ${errors.Manager ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Manager"
                                    name="Manager"
                                    value={formData.Manager}
                                    onChange={handleChange}
                                />
                                {errors.Manager && <div className="text-danger">{errors.Manager}</div>}
                            </div>
                        </div>


                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Managne</b></label>
                                <input
                                    className={`form-control ${errors.SeutHoldersPlural ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Managne"
                                    name="SeutHoldersPlural"
                                    value={formData.SeutHoldersPlural}
                                    onChange={handleChange}
                                />
                                {errors.SeutHoldersPlural && <div className="text-danger">{errors.SeutHoldersPlural}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Stepe Plural</b></label>
                                <input
                                    className={`form-control ${errors.Stepe ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Annual Stepe (Plural)"
                                    name="Stepe"
                                    value={formData.Stepe}
                                    onChange={handleChange}
                                />
                                {errors.Stepe && <div className="text-danger">{errors.Stepe}</div>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Observer
                                </b></label>
                                <input
                                    className={`form-control ${errors.Observer ? 'is-invalid' : ''}`}
                                    type="text"
                                    placeholder="Enter Sub-Step (singular)                                       "
                                    name="Observer"
                                    value={formData.Observer}
                                    onChange={handleChange}
                                />
                                {errors.Observer && <div className="text-danger">{errors.Observer}</div>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Directory;