import React, { useState } from 'react';
import axios from 'axios';
import API from '../../../utils';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const LaborEfficiencyRatio = () => {
    const [years, setYears] = useState([]);
    const uid = localStorage.getItem('user_id');
    const [financialData, setFinancialData] = useState({});

    const [newYear, setNewYear] = useState('');
    const [error, setError] = useState('');

    const addYear = () => {
        if (!newYear) {
            setError('Please select a year.');
            return;
        }
        if (years.includes(newYear)) {
            setError('Year already exists.');
            return;
        }

        setYears([...years, newYear]);
        setFinancialData({
            ...financialData,
            [newYear]: { revenue: 0, cogs: 0, direct_labor: 0, operating_exp: 0 }
        });
        setNewYear('');
        setError('');
    };


    const handleInputChange = (year, field, value) => {
        setFinancialData({
            ...financialData,
            [year]: { ...financialData[year], [field]: parseFloat(value) }
        });
    };

    const calculateGrossProfit = (year) => {
        const data = financialData[year] || {};
        return data.revenue - data.cogs;
    };


    const onSubmit = async () => {
        const yearsArray = [];
        const revenuesArray = [];
        const cogsArray = [];
        const direct_laborArray = [];
        const operating_expArray = []; // Changed from directoperating_expArray

        years.forEach(year => {
            yearsArray.push(year);
            revenuesArray.push(financialData[year]?.revenue || 0);
            cogsArray.push(financialData[year]?.cogs || 0);
            direct_laborArray.push(financialData[year]?.direct_labor || 0);
            operating_expArray.push(financialData[year]?.operating_exp || 0); // Capture operating expenses
        });

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        yearsArray.forEach((year, index) => {
            formDataToSend.append('year[]', year);
            formDataToSend.append('revenue[]', revenuesArray[index]);
            formDataToSend.append('cogs[]', cogsArray[index]);
            formDataToSend.append('direct_labor[]', direct_laborArray[index]);
            formDataToSend.append('operating_exp[]', operating_expArray[index]); // Include operating expenses
        });

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.productivity_insert}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data[0].status === "success") {
                toast.success(response.data[0].message);
                fetchData();
            } else {
                toast.error(response.data[0].message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to submit the form. Please try again later.');
        }
    };



    useEffect(() => {
        fetchData();
    }, []);



    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.productivity_get}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data;
                const newFinancialData = {};
                const newYears = [];

                data.forEach(item => {
                    const year = item.year;
                    newYears.push(year);
                    newFinancialData[year] = {
                        revenue: parseFloat(item.revenue) || 0,
                        cogs: parseFloat(item.cogs) || 0,
                        direct_labor: parseFloat(item.direct_labor) || 0,
                        operating_exp: parseFloat(item.operating_exp) || 0,
                    };
                });

                setFinancialData(newFinancialData);
                setYears(newYears);

            } else {
                // toast.error(response.data.message || 'Failed to fetch data.');
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        }
    };


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-12 align-items-center d-flex">
                            <h5>Cash: Labor Efficiency Ratio: Fine-Tuning Labor Productivity</h5>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <select
                                    className="form-control"
                                    value={newYear}
                                    onChange={(e) => setNewYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                                {error && <small className="text-danger">{error}</small>}
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <button onClick={addYear} className="btn btn-year btn-primary">
                                <i className="fa fa-plus-circle mx-1"></i>Add Year
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        {years.length === 0 ? (
                            <h6 className='d-flex justify-content-center my-lg-4'>No data available.</h6>
                        ) : (
                            years.map(year => (
                                <div className='col-md-6 my-3' key={year}>
                                    <div className="shadow shadow-showcase p-25 text-center">
                                        <div className="income-statement">
                                            <h5>Income Statement ({year})</h5>
                                            <div className='row'>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group mb-2">
                                                        <label className="align-items-lg-start col-form-label col-sm-12 d-flex"><b>Revenue</b></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={financialData[year]?.revenue || 0}
                                                            onChange={(e) => handleInputChange(year, 'revenue', e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group mb-2">
                                                        <label className="align-items-lg-start col-form-label col-sm-12 d-flex"><b>Cogs</b></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={financialData[year]?.cogs || 0}
                                                            onChange={(e) => handleInputChange(year, 'cogs', e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group mb-2">
                                                        <label className="align-items-lg-start col-form-label col-sm-12 d-flex"><b>Gross Profit</b></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={calculateGrossProfit(year)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group mb-2">
                                                        <label className="align-items-lg-start col-form-label col-sm-12 d-flex"><b>Direct Labor</b></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={financialData[year]?.direct_labor || 0}
                                                            onChange={(e) => handleInputChange(year, 'direct_labor', e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group mb-2">
                                                        <label className="align-items-lg-start col-form-label col-sm-12 d-flex"><b>Operating Exp</b></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={financialData[year]?.operating_exp || 0}
                                                            onChange={(e) => handleInputChange(year, 'operating_exp', e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                </div>
                <div className="card-footer">
                    <div className='col-md-12'>
                        <button onClick={onSubmit} className="float-end btn btn-success"
                            disabled={years.length === 0}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LaborEfficiencyRatio;