import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import API from '../../../../../utils';
import { toast } from 'react-toastify';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PeopleActions = () => {
    const actionsList = {
        "Capability": [
            { id: 1, name: "Develop an effective mechanism to continuously assess and manage employee health and well-being" },
            { id: 2, name: "Build a cohesive and caring virtual community (including leveraging digital/social media tools), to better cater for remote and onsite colleagues" },
            { id: 3, name: "Reimagine our overall organization structure (including the HR function) across geographies to leverage recent behavioral shifts and lessons learnt" },
            { id: 4, name: "Assess capabilities and skills needed as new norms and factor into our learning and development plans" },
            { id: 5, name: "Align resource requirements with future business objectives" },
            { id: 6, name: "Develop and monitor consistent HR management processes" },
            { id: 7, name: "Digitize workforce lifecycle including recruitment, onboarding, performance management, reward and recognition, learning & development and exit to drive productivity, performance and to better cater for a remote/virtual working environment" },
            { id: 8, name: "Promote and measure workplace diversity and inclusiveness" },
            { id: 9, name: "Actively monitor the future of work trends and design strategies to meet the changing needs and to seize opportunities they create to the business" },
            { id: 10, name: "Enable human resources (HR) to become strategic business partner" },
            { id: 11, name: "Embed people plan within corporate strategy" }
        ],
        "Performance Management": [
            { id: 12, name: "Align (changing) business strategy/objectives with performance management of all functions, teams and individuals" },
            { id: 13, name: "Embed company values in performance management" },
            { id: 14, name: "Build/improve capabilities to measure actual activity, behavior and outcomes of teams and individuals" },
            { id: 15, name: "Develop/enhance talent management program(s) securing effective talent development, career planning and retention" },
            { id: 16, name: "Embed a culture of continuous feedback" }
        ],
        "Innovation": [
            { id: 17, name: "Promote entrepreneurial culture — safe to take risks and celebrate lessons from failure" },
            { id: 18, name: "Implement/enhance program to develop innovation as a competency" },
            { id: 19, name: "Recruit/ select/ develop/ organize/ incentivize innovation and reward employees who demonstrate innovation" },
            { id: 20, name: "Provide time for employees to innovate" },
            { id: 21, name: "Provide innovation mentors/coaches" },
            { id: 22, name: "Setup dedicated innovation team(s) within or across functions/departments" },
            { id: 23, name: "Have leadership visibly champion and reward innovation" }
        ],
        "Leadership": [
            { id: 24, name: "Leadership to define the company’s purpose and to develop and promote an inspirational vision and strategy through communications and through action and behavior" },
            { id: 25, name: "Assess leadership competencies, experience and skillsets required to take the business to a next level of growth" },
            { id: 26, name: "Identify future leadership needs" },
            { id: 27, name: "Broaden leadership team – skills, diversity and culture" },
            { id: 28, name: "Perform mapping of all critical positions and develop/refresh clear leadership and management succession plan" },
            { id: 29, name: "Review compensation scheme for potential leaders" },
            { id: 30, name: "Introduce leadership development and retention program" },
            { id: 31, name: "Conduct a systematic People risk analysis (competencies, leadership, succession, HR-processes, systems, organization etc.) incl. outside, downside, and upside risks (risk taking required to realize the company’s growth and long-term value objectives)" }
        ],
        "Rewards": [
            { id: 32, name: "Link rewards/personal development/career opportunities to both short and long-term performance management and the achievement of business objectives" },
            { id: 33, name: "Introduce total rewards concept for teams and individuals" },
            { id: 34, name: "Link rewards to team goals, as well as individuals" },
            { id: 35, name: "Reward behaviors as well as outcomes" },
            { id: 36, name: "Reward risk taking as well as risk management" },
            { id: 37, name: "Create a strategy to become employer of choice in chosen markets" }
        ]
    };

    const [selectedActions, setSelectedActions] = useState([]);
    const [openCategory, setOpenCategory] = useState(null);
    const [checkedValue, setCheckedValue] = useState({});
    const uid = localStorage.getItem("user_id");

    const handleCategoryToggle = (category) => {
        setOpenCategory(openCategory === category ? null : category);
    };

    // Function to fetch data from API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_people}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_action;

                if (jsonData) {
                    try {
                        const data = JSON.parse(jsonData);

                        const parseCategoryData = (categoryData) => {
                            return categoryData ? categoryData.split(',') : [];
                        };

                        const actions = {
                            Capability: parseCategoryData(data.capability || ''),
                            Innovation: parseCategoryData(data.innovation || ''),
                            Leadership: parseCategoryData(data.leadership || ''),
                            "Performance Management": parseCategoryData(data.performance_management || ''),  // Updated key here
                            Rewards: parseCategoryData(data.rewards || ''),
                        };

                        setCheckedValue(actions);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No data found for technology_action.');
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch technology actions error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatSelectedActions = (selectedActions) => {
        const categories = {
            "Capability": "capability[]",
            "Performance Management": "performance_managment[]",
            "Innovation": "innovation[]",
            "Leadership": "leadership[]",
            "Rewards": "rewards[]"
        };

        const formatted = Object.keys(categories).reduce((acc, category) => {
            const selectedData = selectedActions
                .filter(action => action.category === category)
                .map(action => {
                    const actionDetails = actionsList[category].find(a => a.id === action.id);
                    return actionDetails ? actionDetails.name : null;  // Directly returning the action name
                })
                .filter(action => action !== null);

            // Ensure this is an array, even if empty
            acc[categories[category]] = selectedData;
            return acc;
        }, {});

        return formatted;
    };



    const handleAddToActions = async (category, action, index) => {
        try {
            const newCheckedValue = { ...checkedValue };
            const isSelected = newCheckedValue[category]?.includes(action.id);
            if (isSelected) {
                newCheckedValue[category] = newCheckedValue[category].filter(id => id !== action.id);
            } else {
                newCheckedValue[category] = [...(newCheckedValue[category] || []), action.id];
            }
            setCheckedValue(newCheckedValue);

            const updatedActions = Object.keys(newCheckedValue).flatMap(cat =>
                newCheckedValue[cat].map(id => ({ category: cat, id }))
            );
            setSelectedActions(updatedActions);

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const formattedActions = formatSelectedActions(updatedActions);

            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_people_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
            toast.error("An error occurred while updating data.");
        }
    };

    const renderActions = (category) => {
        const categoryActions = actionsList[category] || [];

        return categoryActions.map((action, index) => (
            <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
                <div className="action-card p-3 border">
                    <p>{action.name}</p>
                    <Button
                        variant={checkedValue[category]?.includes(action.id) ? "success" : "primary"}
                        onClick={() => handleAddToActions(category, action, index)}
                        className="d-flex align-items-center mt-3 p-2"
                    >
                        {checkedValue[category]?.includes(action.id) ? <FaCheck className="me-2" /> : <FaPlus className="me-2" />}
                        {checkedValue[category]?.includes(action.id) ? "Selected" : "Add to Actions"}
                    </Button>
                </div>
            </Col>
        ));
    };

    return (
        <div className="col-sm-12">
            <div className="card mt-4">
                <div className="card-header">
                    <div className='justify-content-between d-flex '>
                        <div className="">
                            <h5>Actions to Raise the Maturity Level for People</h5>
                        </div>
                        <div>
                            <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <p>To help you achieve your goals we have created actions learned from our insights of working with market leaders. Add ones that you feel are best for your business.</p>
                        <Accordion defaultActiveKey="0" activeKey={openCategory}>
                            {Object.keys(actionsList).map(category => (
                                <Accordion.Item eventKey={category} key={category}>
                                    <Accordion.Header onClick={() => handleCategoryToggle(category)}>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <h5 className='mb-0 fw-bold'>{category}</h5>
                                            <p className='mb-0 text-primary'>View Actions</p></div>

                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {openCategory === category && renderActions(category)}
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PeopleActions;