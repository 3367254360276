import React, { useEffect } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

import { FaRegAddressBook } from "react-icons/fa";
import { isSubscribedAccountant } from "../../utils";
import { FaClipboardUser } from "react-icons/fa6";

const IndexClients = () => {
    
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Nav variant="pills" className="border-tab nav-primary pt-3 mb-3">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/clients-name" exact>
                                    <FaClipboardUser  className="me-1" />Clients Name
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/clients-templeat" exact>
                                    <FaRegAddressBook className="me-1" />Clients Template
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default IndexClients;
