import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import configDB from "../data/customizer/config";
import PrivateRoute from "./private-route";
import Signin from "../auth/signin";
import AppLayout from "../components/app";
import ForgetPwd from "../pages/forgetPwd";
import ResetPwd from "../pages/resetPwd";
import Error404 from "../pages/errors/error404";
import { routes } from "./layouts-routes";
import { Loader } from "react-feather";
import Signup from "../pages/signup";

import AdminPage from "../components/admin/admin";
import TeamMemberChangePassword from "../pages/errors/teamMemberChangepass";
const MainRoutes = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    if (layout) {
      document.body.classList.add(layout);
    }
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              {routes.map(({ path, Component }, i) => (
                <Route element={<AppLayout />} key={i}>
                  <Route exact path={path} element={Component} />
                </Route>
              ))}
            </Route>
            <Route path="/login" element={<Signin />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgetpass" element={<ForgetPwd />} />
            <Route path="/change-password" element={<TeamMemberChangePassword />} />
            <Route path="/resetPwd" element={<ResetPwd />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      
    </>
  );
};

export default MainRoutes;
