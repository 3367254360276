import React, { useEffect, useState } from 'react';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaEye, FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

const FrequentlyQustions = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isTitleValid, setIsTitleValid] = useState(false);
  const uid = localStorage.getItem("user_id");
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);

  };
  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      toast.error('Title is required.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('title', title);
    formDataToSend.append('description', description);
 
    try {

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_frequently_question}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success('Video added successfully!');
        // Optionally reset form
        setTitle('');
        setDescription('');
       
        fetchData();
        toggleModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred while submitting the form.');
    }
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
    setTitle(item.title);
    setDescription(item.description);
    
    toggleEditModal();
  };

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };
  const handleDelete = (item) => {
    setSelectedItem(item);
    toggleDeleteModal();
  };
  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
    

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_frequently_question}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        // Sort the data based on created_at field
        const sortedUsers = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setUsers(sortedUsers);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while fetching data.");
    }
  };
  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,

    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,

    },
  
    {
      name: 'Actions',
      cell: row => (
        <div className="d-lg-flex">
          <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleView(row)}><FaEye /></Button>
          <Button className="btn-secondary btn-pill main-edit-view-buttons m-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button className="btn-danger btn-pill main-delete-buttons m-1" onClick={() => handleDelete(row)} ><MdDelete /></Button>
        </div>
      ),

    },

  ];

  const filteredEntries = users.filter(entry =>
    Object.values(entry).some(value =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  const handleDeleteItem = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('id', selectedItem.id);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.delete_frequently_question}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        toggleDeleteModal();
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete the item.");
    }
  };

  const handleEditItem = async () => {
    if (!selectedItem) return;

    const formDataToSend = new FormData();
    formDataToSend.append('id', selectedItem.id);
    formDataToSend.append('title', title);
    formDataToSend.append('description', description);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.update_frequently_question}`, // Correct endpoint
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success('Content updated successfully!');
        toggleEditModal();
        fetchData(); 
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during edit:", error);
      toast.error('An error occurred while updating the content.');
    }
  };

 

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setTitle('');
    setDescription('');
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Frequently Asked Questions</h5>
                    <Button className="btn-success main-buttons" onClick={toggleModal}>Add Frequently Asked Questions</Button>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div>
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    persistTableHead={true}
                    pagination
                    className="altimate-table"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Frequently Asked Questions</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setIsTitleValid(e.target.value.trim() !== ''); // Check if title is not empty
                }}
                placeholder="Enter Title"
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description"
              />
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            <Button color="primary" type="submit" disabled={!isTitleValid}>Add Frequently Asked Questions</Button>{' '}
          </ModalFooter>
        </form>
      </Modal>


      {isDeleteModalOpen && (
        <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
          <ModalHeader toggle={toggleDeleteModal}>Delete Item</ModalHeader>
          <ModalBody>
            <p>Are you sure you want to delete {selectedItem && selectedItem.name}?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
            <Button color="danger" onClick={handleDeleteItem}>Delete</Button>
          </ModalFooter>
        </Modal>
      )}

      {isEditModalOpen && selectedItem && (
        <Modal isOpen={isEditModalOpen} toggle={toggleEditModal} centered>
          <ModalHeader toggle={toggleEditModal}>Edit Frequently Asked Questions</ModalHeader>
          <ModalBody>
            <form>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                  defaultValue={selectedItem.title}
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                  defaultValue={selectedItem.description}
                />
              </div>
          
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEditModal}>Cancel</Button>
            <Button color="primary" onClick={handleEditItem}>Save Changes</Button>
          </ModalFooter>
        </Modal>
      )}


      {isViewModalOpen && selectedItem && (
        <Modal isOpen={isViewModalOpen} toggle={toggleViewModal} centered size="lg">
          <ModalHeader toggle={toggleViewModal}>Frequently Asked Questions Details</ModalHeader>
          <ModalBody>

            <table className="table">
              <thead></thead>
              <tbody>
                <tr>
                  <th style={thStyle} colSpan={2}>Frequently Asked Questions  Detail</th>
                </tr>
                <tr>
                  <td>Title</td>
                  <td>{selectedItem.title}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{selectedItem.description}</td>
                </tr>

              
              </tbody>
            </table>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleViewModal}>Close</Button>
          </ModalFooter>
        </Modal>
      )}

    </>
  );
};
export default FrequentlyQustions;
