import axios from 'axios';
import React, { useState, useEffect } from 'react';
import API from '../../../utils';
import { toast } from 'react-toastify';

const AccountPayable = () => {
    const [monthsData, setMonthsData] = useState({});
    const [selectedYear, setSelectedYear] = useState('');
    const [formError, setFormError] = useState('');
    const uid = localStorage.getItem("user_id");

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const fetchYearData = async (year) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('year', year);
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_accounts_payble}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                }
            });

            if (response.data.status === 'success') {
                const fetchedData = response.data.data;
                const monthsDataMap = {};

                fetchedData.forEach(entry => {
                    const monthIndex = parseInt(entry.month_id) - 1;
                    const revenue = parseFloat(entry.month_count);
                    monthsDataMap[months[monthIndex]] = { revenue };
                });

                setMonthsData(monthsDataMap);
                setFormError('');
            } else {
                // Initialize monthsData with default values (0 revenue) if no data
                const defaultMonthsData = months.reduce((acc, month) => {
                    acc[month] = { revenue: 0 };
                    return acc;
                }, {});
                setMonthsData(defaultMonthsData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchYearData();
    }, []);

    const handleYearChange = (e) => {
        const selectedYear = e.target.value;
        if (selectedYear) {
            setSelectedYear(selectedYear);
            fetchYearData(selectedYear);
        } else {
            setSelectedYear('');
            setMonthsData({});
            setFormError('Please select a year.');
        }
    };

    const handleMonthRevenueChange = (month, value) => {
        setMonthsData(prev => ({
            ...prev,
            [month]: { revenue: parseFloat(value) }
        }));
    };

    const validateForm = () => {
        if (!selectedYear) {
            setFormError('Please select a year.');
            return false;
        }

        for (let month in monthsData) {
            if (isNaN(monthsData[month].revenue) || monthsData[month].revenue < 0) {
                setFormError(`Please enter a valid revenue for ${month}.`);
                return false;
            }
        }

        setFormError(''); // Clear previous error if form is valid
        return true;
    };

    const onSubmit = async () => {
        if (!validateForm()) return;

        const monthIds = [];
        const monthCounts = [];

        months.forEach((month, index) => {
            const revenue = monthsData[month]?.revenue || 0;
            monthIds.push(index + 1);
            monthCounts.push(revenue);
        });

        const data = {
            user_id: uid,
            year: selectedYear,
            month_id: monthIds,
            month_count: monthCounts,
        };

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('year', data.year);
            data.month_id.forEach((id, index) => {
                formDataToSend.append('month_id[]', id);
                formDataToSend.append('month_count[]', data.month_count[index]);
            });

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_accounts_payable}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.data.status === "success") {
                toast.success(`Response updated successfully for year ${data.year}!`);
                // setSelectedYear('');
                setMonthsData({});
                fetchYearData(selectedYear);
            } else {
                toast.error(response.data.message || `Failed to update response for year ${data.year}`);
            }

        } catch (error) {
            console.error("Error updating response:", error);
            toast.error('An error occurred while updating the response.');
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5>Account Payable</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <select
                            className="form-control"
                            onChange={handleYearChange}
                            value={selectedYear}
                        >
                            <option value="">Select Year</option>
                            {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {selectedYear ? (
                    <div className="row mt-3">
                        <div className='col-md-12 my-3'>
                            <div className="shadow p-3 totalrevenue-border">
                                <h5>Account Payable ({selectedYear})</h5>
                                <div className='row mt-2'>
                                    {months.map(month => (
                                        <div key={month} className="col-md-6 mb-2">
                                            <label><b>{month} Account Payable</b></label>
                                            <input
                                                type="text"
                                                className="form-control mt-1"
                                                value={monthsData[month]?.revenue || 0}
                                                onChange={(e) => {
                                                    const value = parseFloat(e.target.value);
                                                    if (value >= 0) {
                                                        handleMonthRevenueChange(month, value);
                                                    } else {
                                                        // Optional: show error message or feedback for invalid input
                                                        setFormError(`Revenue for ${month} cannot be negative.`);
                                                    }
                                                }}
                                                placeholder={`Revenue for ${month}`}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="text-end mt-3">
                                    <button onClick={onSubmit} className="btn btn-success">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-3 text-center">
                        <small className="text-danger ">Please select a year to view and edit revenue data.</small>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountPayable;
