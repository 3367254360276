import React from "react";
import { Container } from "react-bootstrap";
import GrowthDataTable from "./growthdataTable";
import KeyResponsibilitiesTable from "./keyresponsibilitiesTable";
import SkillsTable from "./skillstable";

const JobScorecard = () => {
  return (
    <Container fluid={true}>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="align-items-center d-flex">
                  <h5>Job Scorecard</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
          
              <GrowthDataTable />
              <hr />
              <KeyResponsibilitiesTable />
              <hr />
              <SkillsTable />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default JobScorecard;
