import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "react-bootstrap";
import EventCharts from "./yourUpcomingMeetings";
import { FaRegCalendarAlt } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import { Line } from "react-chartjs-2";
import { gradientChartOptions } from "../../../data/default";
import { Chart } from "react-google-charts";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { GiRubElHizb } from "react-icons/gi";
import { GrScorecard } from "react-icons/gr";
import API, { isSubscribedAccountant } from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configDB from "../../../data/customizer/config";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const primary = localStorage.getItem("primary_color") || configDB.data.color.primary_color;

const Dashboard = () => {
  const uid = localStorage.getItem("user_id");

  const [chartData, setChartData] = useState({
    labels: ["year", "Q2", "Q3", "Q4"],
    datasets: [
      {
        data: [],
        lineTension: 0.5,
        borderColor: primary,
      },
    ],
  });

  const [people, setPeople] = useState([]);
  const [professional, setProfessional] = useState([]);

  const handleCheckboxCheck = async (taskId, isChecked) => {
    const formDataToSend = new FormData();
    formDataToSend.append('my_task_id', taskId);
    formDataToSend.append('is_check', isChecked ? "1" : "0");

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    try {
      await delay(500);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.is_check_my_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchPeopleData();
        fetchPersonalData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during update:", error);
      toast.error('An error occurred while updating the task.');
    }
  };

  // const handleCheckboxCheck = async (taskId, isChecked) => {
  //   const formDataToSend = new FormData();
  //   formDataToSend.append('my_task_id', taskId);
  //   formDataToSend.append('is_check', isChecked ? "1" : "0");

  //   try {
  //     const response = await axios.post(
  //       `${API.BASE_URL}${API.ENDPOINTS.is_check_my_task}`,
  //       formDataToSend,
  //       {
  //         headers: {
  //           Authorization: `${API.AUTHORIZATION_TOKEN}`,
  //         },
  //       }
  //     );

  //     if (response.data.status === "success") {
  //       fetchPeopleData();
  //       fetchPersonalData();
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error during update:", error);
  //     toast.error('An error occurred while updating the task.');
  //   }
  // };

  const renderCheckbox = (row) => (
    <input
      className="checkbox_animated"
      id="chk-ani1"
      type="checkbox"
      checked={row.is_complete === "1"}
      onChange={(e) => handleCheckboxCheck(row.id, e.target.checked)}
    />
  );

  const columns = [
    {
      name: 'Completed',
      selector: row => renderCheckbox(row),
      sortable: true,
    },
    {
      name: 'Task',
      selector: row => row.title,
      sortable: true
    },
    {
      name: 'Priority',
      selector: row => (
        <span style={{
          backgroundColor: getPriorityColor(row.criticality),
          color: 'white',
          padding: '5px 10px',
          borderRadius: '0px'
        }}>
          {row.criticality}
        </span>
      ),
      sortable: true,
    }
  ];

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Critical':
        return 'red';
      case 'High':
        return '#f39c12';
      case 'Medium':
        return 'green';
      case 'Low':
        return 'green';
      default:
        return '#95a5a6';
    }
  };

  const columns1 = [
    {
      name: 'Completed',
      selector: row => renderCheckbox(row),
      sortable: true,
    },
    {
      name: 'Task',
      selector: row => row.title,
      sortable: true
    },
    {
      name: 'Priority',
      selector: row => (
        <span style={{
          backgroundColor: getPriorityColor(row.criticality),
          color: 'white',
          padding: '5px 10px',
          borderRadius: '0px'
        }}>
          {row.criticality}
        </span>
      ),
      sortable: true,
    }
  ];

  const [ScorecardsData, setScorecardsData] = useState({
    labels: ["2021", "2022", "2023", "2024"],
    datasets: [
      {
        data: [],
        lineTension: 0,
        borderColor: primary,
      },
    ],
  });

  const data = [
    ['Year', 'Score'],
    ['2021', ScorecardsData.datasets[0].data[0] || 0],
    ['2022', ScorecardsData.datasets[0].data[1] || 0],
    ['2023', ScorecardsData.datasets[0].data[2] || 0],
    ['2024', ScorecardsData.datasets[0].data[3] || 0],
  ];

  const options = {
    maintainAspectRatio: false,
    bezierCurve: false,
    height: 360,
    colors: ["#4466f2", "#1ea6ec", "#31af47"],
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        display: true,
        ticks: {
          font: {
            size: 13,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 13,
          },
        },
      },
    },
  };


  const fetchScorecards = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.dashboard_data_from_sop_linance}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const scorecardData = response.data.info;

        const formattedChartData = {
          labels: ["2021", "2022", "2023", "2024"],
          datasets: [
            {
              data: [
                scorecardData["2021"] || 0,
                scorecardData["2022"] || 0,
                scorecardData["2023"] || 0,
                scorecardData["2024"] || 0
              ],
              borderColor: primary,
              lineTension: 0,
            },
          ],
        };

        setScorecardsData(formattedChartData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch scorecards error', error);
    }
  };


  const fetchPeopleData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.deshboard_people_data}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setPeople(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch gratitude error', error);
    }
  };

  const fetchPersonalData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.deshboard_Professional_data}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setProfessional(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch gratitude error', error);
    }
  };

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.dashboard_data_from_project}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      const data = response.data.info;

      if (response.data && response.data.status === "success") {
        const allValuesAreZero = data.Q1 === 0 && data.Q2 === 0 && data.Q3 === 0 && data.Q4 === 0;

        if (allValuesAreZero) {
          setChartData(null);
        } else {
          const chartData = {
            labels: ["1st Quarter", "2nd Quarter", "3rd Quarter", "4th Quarter"],
            datasets: [
              {
                data: [data.Q1, data.Q2, data.Q3, data.Q4],
                lineTension: 0.5,
                borderColor: primary,
              },
            ],
          };
          setChartData(chartData);
        }
      } else {
        const errorMessage = response.data ? response.data.message : "Unknown error";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Fetch checked days error", error);
      // toast.error("Failed to fetch data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchPeopleData();
    fetchPersonalData();
    fetchData();
    fetchScorecards();
  }, []);

  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12" className="p-0">
            <Card>
              <CardHeader>
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5><FaRegCalendarAlt className="me-2" />Upcoming Meetings For This Week</h5>
                    <div className="allmeting-text">
                      <a href="/meeting/meetings">All Meetings <i className="fa fa-angle-double-right"></i></a>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-xl-12 xl-100 ">
                    <div className="row">
                      <EventCharts />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className="row">
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className=" align-items-center justify-content-between d-flex">
                        <h5><VscWorkspaceTrusted className="me-2" />Personal To-Dos</h5>
                        <div className="allmeting-text">
                          <a href="/my-task/task">All Personal To-Dos<i className="fa fa-angle-double-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <DataTable
                      columns={columns}
                      data={people}
                      persistTableHead={true}
                      pagination
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className=" align-items-center justify-content-between d-flex">
                        <h5><VscWorkspaceTrusted className="me-2" />professional To-Dos</h5>
                        <div className="allmeting-text">
                          <a href="/my-task/task">All professional To-Dos <i className="fa fa-angle-double-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <DataTable
                      columns={columns1}
                      data={professional}
                      persistTableHead={true}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className=" align-items-center justify-content-between d-flex">
                        <h5><GiRubElHizb className="me-2" />Rocks</h5>
                        <div className="allmeting-text">
                          <a href="/project/project-name">All Rocks <i className="fa fa-angle-double-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div>
                      {chartData && chartData.datasets[0].data.some((value) => value !== 0) ? (
                        <div className="smooth-chart flot-chart-container">
                          <Line data={chartData} options={gradientChartOptions} height="360" />
                        </div>
                      ) : (
                        <p className="text-center">No data to display</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className=" align-items-center justify-content-between d-flex">
                        <h5><GrScorecard className="me-2" />Scorecards</h5>
                        <div className="allmeting-text">
                          <a href="/sops/finance">All Scorecards <i className="fa fa-angle-double-right"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* defult-chart */}
                  <div className="card-body">
                    {ScorecardsData.datasets[0].data.some(value => value > 0) ? (
                      <Chart
                        className="defult-chart hello-chart"
                        chartType="Bar"
                        width="100%"
                        height="360px"
                        data={data}
                        options={options}
                      />
                    ) : (
                      <p className="text-center">No data to display</p>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
