import React, { useEffect, useState } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { FaProjectDiagram } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { isSubscribedAccountant } from "../../utils";

const IndexProject = () => {
    const settings = {
        infinite: false,
        speed: 500,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, []);

    return (
        <Container fluid>
            <Row className="mx-lg-3">
                <Slider {...settings}>
                    <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary">
                            <Nav.Item>
                                <Nav.Link as={NavLink} to="/project/project-name" exact>
                                    <FaProjectDiagram className="me-1" /> Project Name
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* <div className="slider-item">
                        <Nav variant="pills" className="border-tab nav-primary ">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact to="/project/project-gannt">
                                    <TbReportSearch className="me-1" /> Project Gantt
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div> */}
                </Slider>
            </Row>
        </Container>
    );
};

export default IndexProject;
