import React, { useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { FaBalanceScaleRight } from "react-icons/fa";
import { Tb360View, TbReportSearch } from "react-icons/tb";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { isSubscribedAccountant } from "../../utils";

const Indexreporting = () => {
    const navigate = useNavigate()
    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/billings");
            window.location.reload();
        }
    }, []);

    return (
        <Container fluid>
            <div className="mx-3">
                <div className="slider-item">
                    <Nav variant="pills" className="border-tab nav-primary">
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/people/pace">
                                <FaBalanceScaleRight className="me-1" />Goal Scorecard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/Monthly">
                                <TbReportSearch className="me-1" />Reports
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={NavLink} exact to="/reporting-data">
                                <Tb360View className="me-1" />Reporting Data
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
        </Container>
    );
};

export default Indexreporting;
