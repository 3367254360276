import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const Growth = () => {
    // const settings = {
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     // centerMode: true,
    //     variableWidth: true,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 infinite: false
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: false
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: false
    //             }
    //         }
    //     ]
    // };

    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);

    return (
        <Container fluid>
            <Row className="mx-3">
                {/* <Slider {...settings}> */}
                <Nav variant="pills" className="border-tab nav-primary">
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/growthframeworks/foundersOSchecklist" exact>
                            <FaHome className="me-1" /> Founders OS Habits Checklist
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/growthframeworks/founderdriver" exact>
                            <TbReportSearch className="me-1" /> Founder’s Enterprise Maturity Strategy
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {/* </Slider> */}
            </Row>
        </Container>
    );
};

export default Growth;
