
const API = {
  GOOGLE_CLIENT_ID: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
  GOOGLE_MAP_API_ID: `${process.env.REACT_APP_GOOGLE_MAP_API_ID}`,
  PROXY_URL: `${process.env.REACT_APP_PROXY_URL}`,
  BASE_URL: `${process.env.REACT_APP_BASE_URL}`,
  BASE_URLRESERT: `${process.env.REACT_APP_BASE_RESERTURL}`,
  BASE_RECAPTCHASERT: `${process.env.REACT_APP_BASE_RECAPTCHA}`,
  AUTHORIZATION_TOKEN: "FFxx21$$21life360AAA212TRRR",

  ENDPOINTS: {
    // login
    login: "/login",
    signup: "/signup_subscription",
    googleLogin: "/googleLogin",
    facebookLogin:'/facebookLogin',
    changepassword: "/changepassword",
    forgotPassword: '/forgotPassword',
    update_profile: "/update_profile",
    get_user_details: "/get_user_details",
    deshboard_notification: "/deshboard_notification",
    view_notification:"/view_notification",
    resetpassword: "/reset-password",
    delete_emergency_contact: "/delete_emergency_contact",
    add_update_preference: '/add_update_preference',
    get_preference: '/get_preference',
    checkUserExists: '/checkUserExists',

    // subscription
    check_subscription: "/check_subscription",
    renew_subscription: "/renew_subscription",
    renew_teammates_subscription:'/renew_teammates_subscription',

    //profile > metrice
    personal_profile_metrice: "/personal_profile_metrice",
    get_all_personal_profile_metrice: "/get_all_personal_profile_metrice",
    personal_strengths_top:'/personal_strengths_top',
    personal_strengths_bottom:'/personal_strengths_bottom',

    // billing licenses
    get_billing_licenses: "/get_billing_licenses",
    add_billing_licenses: "/add_billing_licenses",
    updated_billing_licenses: "/updated_billing_licenses",
    delete_billing_licenses: "/delete_billing_licenses",

    //mytask
    add_task: "/add_task",
    get_all_task: "/get_all_task",
    update_task: "/update_task",
    oauth: "/oauth",

    // Continous Learning
    add_continous_learning: "/add_continous_learning",
    get_all_continue_learning: "/get_all_continue_learning",
    update_continous_learning: "/update_continous_learning",
    // achievement
    get_all_achievement: "/get_all_achievement",
    add_achievements: "/add_achievements",

    //user profile

    add_new_company: "/add_new_company",
    updated_new_company:"/updated_new_company",
    add_update_login_info: "/add_update_login_info",
    get_login_info: "/get_login_info",
    is_check_my_task: "/is_check_my_task",

    add_social: "/add_social",
    get_all_social_data: "/get_all_social_data",
    add_contact: "/add_contact",

    //team
    add_team_membres: "/add_team_membres",
    get_all_team_membres: "/get_all_team_membres",
    update_team_membres: "/update_team_membres",
    delete_team_membres: "/delete_team_membres",

    //metting
    add_meeting: "/add_meeting",
    get_all_email_phone: "/get_all_email_phone",
    check_meetings: "/check_meetings",

    add_meeting_agenda_items:"/add_meeting_agenda_items",
    //meeting type
    add_meeting_type: "/add_meeting_type",
    get_meeting_type: "/get_meeting_type",
    delete_meeting_type: "/delete_meeting_type",
    edit_meeting_type: "/edit_meeting_type",


    delete_company:"/delete_company",
    //statigic goal
    add_strategic_goal: "/add_strategic_goal",
    get_strategic_goal_data: "/get_strategic_goal_data",
    update_strategic_goal_data: "/update_strategic_goal_data",
    check_strategic_goal: "/check_strategic_goal",

    //mettings
    get_meeting_sidebar_data: "/get_meeting_sidebar_data",
    add_meeting_main_sidebar: "/add_meeting_main_sidebar",
    check_meetings_filter: "/check_meetings_filter",
    update_meeting_sidebar_data: "/update_meeting_sidebar_data",
    update_meeting_sidebar_attendes:"/update_meeting_sidebar_attendes",
    update_all_meeting_main_sidebar:"/update_all_meeting_main_sidebar",
    meeting_type_note:"/meeting_type_note",
    get_all_meeting: "/get_all_meeting",
    delete_meeting: "/delete_meeting",
    update_meeting: "/update_meeting",
    add_meeting_agenda: "/add_meeting_agenda",
    get_meeting_agenda: "/get_meeting_agenda",
    edit_meeting_agenda:"/edit_meeting_agenda",
    // edit_meeting_agenda: "/edit_meeting_agenda",
    delete_meeting_agenda: "/delete_meeting_agenda",
    get_company_info: "/get_company_info",


    // founderos
      add_founderos_step: "/add_founderos_step",
    get_founderos_data_by_cat_name: "/get_founderos_data_by_cat_name",
    update_founderos_step: '/update_founderos_step',
    delete_founderos_step:'/delete_founderos_step',

    //power hour
    add_updated_task_calender_event: "/add_updated_task_calender_event",
    get_task_calendar_event: "/get_task_calendar_event",

    //corporate

    add_updated_category_question: "/add_updated_category_question",
    get_all_category_question: "/get_all_category_question",

    //cilent

    add_client: "/add_client",
    get_all_client: "/get_all_client",
    update_clients: "/update_clients",
    send_client_email: '/send_client_email',

    add_client_template: '/add_client_template',
    update_client_template: '/update_client_template',
    get_client_template: '/get_client_template',

    // project name
    add_projectname: "/add_projectname",
    get_projectname: "/get_projectname",
    update_projectname: "/update_projectname",
    is_check_project_name: "/is_check_project_name",
    get_project_gantt: "/get_project_gantt",

    // Business Tools

    add_businesstools_people: "/add_businesstools_people",
    get_businesstools_people: "/get_businesstools_people",
    update_businesstools_people: "/update_businesstools_people",

    get_businesstools_process: "/get_businesstools_process",
    add_businesstools_process: "/add_businesstools_process",
    edit_businesstools_process: "/edit_businesstools_process",

    add_businesstools_technology: "/add_businesstools_technology",
    get_businesstools_technology: "/get_businesstools_technology",
    edit_businesstools_technology: "/edit_businesstools_technology",

    //report goal scorecard

    add_reporting_goal_scorecard: "/add_reporting_goal_scorecard",
    get_all_reporting_goal_scorecard: "/get_all_reporting_goal_scorecard",
    update_reporting_goal_scorecard: "/update_reporting_goal_scorecard",
    get_weekly_reporting_goal_scorecard: "/get_weekly_reporting_goal_scorecard",
    get_monthly_reporting_goal_scorecard: '/get_monthly_reporting_goal_scorecard',
    get_quarterly_reporting_goal_scorecard: '/get_quarterly_reporting_goal_scorecard',
    get_year_to_date_reporting_goal_scorecard: '/get_year_to_date_reporting_goal_scorecard',
    get_trailing_12_months: '/get_trailing_12_months',

    //sops

    add_sops: "/add_sops",
    get_all_sops: "/get_all_sops",
    updated_sops: "/updated_sops",

    add_departments_notes: "/add_departments_notes",
    get_departments_notes: "/get_departments_notes",
    update_departments_notes: "/update_departments_notes",

    //new company
    add_update_new_company: "/add_update_new_company",
    get_all_new_company: '/get_all_new_company',
    update_new_company:"/update_new_company",
    
    notification_schedule: "/notification_schedule",
    get_all_notification: "/get_all_notification",
    notification_send_by_email:'/notification_send_by_email',

    //dashboard
    deshboard_meeting_data: "/deshboard_meeting_data",
    deshboard_people_data: "/deshboard_people_data",
    deshboard_Professional_data: "/deshboard_Professional_data",
    dashboard_data_from_project: "/dashboard_data_from_project",
    dashboard_data_from_sop_linance: "/dashboard_data_from_sop_linance",
    add_user_terminology: "/add_user_terminology",

    add_update_bussiness_canvas: "/add_update_bussiness_canvas",
    get_all_bussiness_canvas: "/get_all_bussiness_canvas",

    //invite teammates
    get_all_teammates_invite_to_company: "/get_all_teammates_invite_to_company",
    // add_teammates_invite_to_company: "/add_teammates_invite_to_company",
    teammates_subscription:'/teammates_subscription',
    update_teammates_invite_to_company: "/update_teammates_invite_to_company",
    delete_teammates_invite_to_company: "/delete_teammates_invite_to_company",
    get_All_billing_licenses_details:'/get_All_billing_licenses_details',
    // to_directory
    add_teammates_to_directory: '/add_teammates_to_directory',
    update_teammates_to_directory: "/update_teammates_to_directory",
    get_all_teammates_to_directory: '/get_all_teammates_to_directory',
    delete_teammates_to_directory: "/delete_teammates_to_directory",





    //business execution

    //Review Quarter Theme
    theme_insert: "/theme_insert",
    get_theme: "/get_theme",


    //who,what,when
    who_what_when_insert: "/who_what_when_insert",
    who_what_when_get: '/who_what_when_get',
    who_what_when_update: "/who_what_when_update",
    //personal selling

    competence_insert: "/competence_insert",
    competence_get: "/competence_get",




    //our terminology

    add_update_terminology_to_dos: "/add_update_terminology_to_dos",
    get_all_terminology_to_dos: "/get_all_terminology_to_dos",
    add_update_terminology_issues: "/add_update_terminology_issues",
    get_all_terminology_issues: "/get_all_terminology_issues",
    add_update_terminology_meetings: "/add_update_terminology_meetings",
    get_all_terminology_meetings: "/get_all_terminology_meetings",
    add_update_terminology_headlines: "/add_update_terminology_headlines",
    get_all_terminology_headlines: "/get_all_terminology_headlines",
    add_update_90my_life: "/add_update_90my_life",
    get_my_90life_data: "/get_my_90life_data",
    add_update_terminology_scorecard: "/add_update_terminology_scorecard",
    get_my__scorecard_data: "/get_my__scorecard_data",
    add_update_terminology_Measurables: "/add_update_terminology_Measurables",
    get_my__Measurables_data: "/get_my__Measurables_data",
    add_update_terminology_rocks: "/add_update_terminology_rocks",
    get_all_terminology_rocks:"/get_all_terminology_rocks",
    add_update_terminology_Milestones: "/add_update_terminology_Milestones",
    get_all_terminology_Milestones: "/get_all_terminology_Milestones",
    add_update_terminology_cascading_messages: "/add_update_terminology_cascading_messages",
    get_all_terminology_cascading_messages: "/get_all_terminology_cascading_messages",
    add_update_terminology_v_tom: "/add_update_terminology_v_tom",
    get_all_terminology_v_tom: "/get_all_terminology_v_tom",

    // business_strategy
    add_update_business_strategy: "/add_update_business_strategy",
    get_business_strategy_question: "/get_business_strategy_question",
    get_business_strategy: "/get_business_strategy",
    add_update_business_strategy_swot:'/add_update_business_strategy_swot',

    // Business Execution ==> Company Priority
    company_priority_get: "/company_priority_get",
    company_priority_insert: "/company_priority_insert",
    company_priority_delete: "/company_priority_delete",

    // Business Execution ==> individual priority
    individual_priority_insert: "/individual_priority_insert",
    individual_priority_get: "/individual_priority_get",
    individual_priority_delete: "/individual_priority_delete",

    // Business Execution ==> Individual Quarter Sprint
    sprint_insert: '/sprint_insert',
    kpis_insert: '/kpis_insert',
    priorities_insert: '/priorities_insert',
    get_sprint: '/get_sprint',

    // Business Execution ==> Individual Quarter Sprint
    develop_insert: '/develop_insert',
    develop_get: '/develop_get',

    // Business Execution ==> Sales Playbook Worksheet
    sales_play_insert: '/sales_play_insert',
    sales_play_get: '/sales_play_get',

    checkup_insert: '/checkup_insert',
    checkup_get: "/checkup_get",

    get_all_process_accountability_chart: '/get_all_process_accountability_chart',
    add_process_accountability_chart: "/add_process_accountability_chart",
    update_process_accountability_chart: '/update_process_accountability_chart',

    add_functional_accountability_chart: "/add_functional_accountability_chart",
    get_all_functional_accountability_chart: '/get_all_functional_accountability_chart',
    update_functional_accountability_chart: '/update_functional_accountability_chart',


    checklist_insert: "/checklist_insert",
    checklist_get: "/checklist_get",

    // People Analysis
    get_growth_people: "/get_growth_people",
    add_update_people_analysis: "/add_update_people_analysis",
    add_update_people_assesment: '/add_update_people_assesment',
    add_update_people_action: '/add_update_people_action',

    add_update_technology_analysis: '/add_update_technology_analysis',
    get_growth_technology: '/get_growth_technology',
    add_update_technology_assesment: '/add_update_technology_assesment',
    add_update_technology_action: '/add_update_technology_action',

    // operations
    get_growth_operation: '/get_growth_operation',
    add_update_operation_analysis: '/add_update_operation_analysis',
    add_update_operation_assesment: '/add_update_operation_assesment',
    add_update_operation_action: '/add_update_operation_action',

    get_growth_customer: '/get_growth_customer',
    add_update_customers_analysis: '/add_update_customers_analysis',
    add_update_customer_assesment: '/add_update_customer_assesment',
    add_update_customer_action: '/add_update_customer_action',

    get_growth_finance: '/get_growth_finance',
    add_update_finance_analysis: '/add_update_finance_analysis',
    add_update_finance_assesment: '/add_update_finance_assesment',
    add_update_finance_action: '/add_update_finance_action',

    get_growth_transactions: '/get_growth_transactions',
    add_update_transactions_analysis: '/add_update_transactions_analysis',
    add_update_transactions_action: '/add_update_transactions_action',
    add_update_transactions_assesment: '/add_update_transactions_assesment',

    get_growth_risk: '/get_growth_risk',
    add_update_risk_analysis: '/add_update_risk_analysis',
    add_update_risk_assesment: '/add_update_risk_assesment',
    add_update_risk_action: '/add_update_risk_action',

    five_dysfunctions_insert: "/five_dysfunctions_insert",
    five_dysfunctions_get: "/five_dysfunctions_get",

    quick_profile_insert: "/quick_profile_insert",
    quick_profile_get: "/quick_profile_get",

    team_effectiveness_insert: "/team_effectiveness_insert",
    team_effectiveness_get: "/team_effectiveness_get",
    team_effectiveness_Update: "/team_effectiveness_Update",

    personal_histories_insert: "/personal_histories_insert",
    personal_histories_get: "/personal_histories_get",

    love_loathe_insert: "/love_loathe_insert",
    love_get: "/love_get",
    loathe_get: "/loathe_get",
    love_loathe_Update: "/love_loathe_Update",

    //contact support
    get_contact_support: '/get_contact_support',
    add_contact_support: "/add_contact_support",

    // personal_plan_insert:'/personal_plan_insert',
    // personal_plan_get:'/personal_plan_get',

    grow_your_team_insert: '/grow_your_team_insert',
    grow_your_team_get: '/grow_your_team_get',

    people_analyze_insert: '/people_analyze_insert',
    people_analyze_get: '/people_analyze_get',
    people_analyze_update:'/people_analyze_update',
    complete_hide_strategic_goals:"/complete_hide_strategic_goals",
    
    personal_plan_update: '/personal_plan_update',
    personal_plan_get: '/personal_plan_get',

    organization_data_get_all: '/organization_data_get_all',
    organizational_insert: '/organizational_insert',

    productivity_insert: '/productivity_insert',
    productivity_get: '/productivity_get',

    insert_cycle_datas: '/insert_cycle_datas',
    get_cycle_data_by_user_id: '/get_cycle_data_by_user_id',

    cash_compensation_insert: '/cash_compensation_insert',
    get_cash_compensation: '/get_cash_compensation',

    power_insert: '/power_insert',
    power_get: '/power_get',

    fundability_insert: '/fundability_insert',
    get_fundability_data: '/get_fundability_data',
    delete_fundability_ideas: '/delete_fundability_ideas',

    get_revenue: '/get_revenue',
    Revenue_insert: '/Revenue_insert',
    Revenue_ideas_get: '/Revenue_ideas_get',
    Revenue_ideas_delete: '/Revenue_ideas_delete',
    Revenue_ideas_insert: '/Revenue_ideas_insert',

    // avatar
    get_customer_avatar: '/get_customer_avatar',
    add_customer_avatar: '/add_customer_avatar',
    update_customer_avatar: '/update_customer_avatar',
    delete_customer_avatar:'/delete_customer_avatar',

    add_update_terminology_accountability_chart:"/add_update_terminology_accountability_chart",
    get_all_terminology_accountability_chart:"/get_all_terminology_accountability_chart",

    add_update_terminology_1_On_1:"/add_update_terminology_1_On_1",
    get_all_terminology_1_On_1:"/get_all_terminology_1_On_1",

    add_update_terminology_process:"/add_update_terminology_process",
    get_all_terminology_process:"/get_all_terminology_process",

    add_update_terminology_directory:"/add_update_terminology_directory",
    get_all_terminology_directory:"/get_all_terminology_directory",

    add_update_terminology_EOS_toolboX:"/add_update_terminology_EOS_toolboX",
    get_all_terminology_EOS_toolboX:"/get_all_terminology_EOS_toolboX",

    personal_contact_email:"/personal_contact_email",
    personal_contact:"/personal_contact",

    add_founderos_university_training:"/add_founderos_university_training",
    get_founderos_university_training:"/get_founderos_university_training",
    update_founderos_university_training:"/update_founderos_university_training",
    delete_founderos_university_training:"/delete_founderos_university_training",
    edit_meeting_agenda_name:"/edit_meeting_agenda_name",

    // Talent Assessment Chart
    add_talentassessment:'/add_talentassessment',
    get_talentassessment:'/get_talentassessment',
    update_talentassessment:'/update_talentassessment',
    get_char_talentassessment:'/get_char_talentassessment',
    delete_talentassessment:'/delete_talentassessment',

    get_job_scorecard:'/get_job_scorecard',
    add_job_scorecard:'/add_job_scorecard',
    update_job_scorecard:'/update_job_scorecard',
    get_job_scorecard_responsibilities:"/get_job_scorecard_responsibilities",
    add_job_scorecard_responsibilities:'/add_job_scorecard_responsibilities',
    update_job_scorecard_responsibilities:'/update_job_scorecard_responsibilities',
    get_job_scorecard_competencies:'/get_job_scorecard_competencies',
    add_job_scorecard_competencies:'/add_job_scorecard_competencies',
    update_job_scorecard_competencies:'/update_job_scorecard_competencies',

    add_update_url_links:'/add_update_url_links',
    add_update_documents:'/add_update_documents',
    add_update_departments_notes:'/add_update_departments_notes',
    get_department_notes_data_by_uid_qid:'/get_department_notes_data_by_uid_qid',

    complete_hide_task:'/complete_hide_task',
    update_achievements:'/update_achievements',
    delete_achievement:'/delete_achievement',

    add_license:"/payment_process",
    get_licence:"/get_license_payment_process",

    add_report_data:'/add_report_data',
    get_report_data:'/get_report_data',

    get_accounts_payble:'/get_accounts_payble',
    add_update_accounts_payable:'/add_update_accounts_payable',
    all_users:'/all-users',

    create_content:"/create-content",
    get_all_contents:"/get_all_contents",
    update_content:'/update-content',
    delete_content:"/delete-content",


    add_frequently_question:"/add_frequently_question",
    get_all_frequently_question:"/get_all_frequently_question",
    update_frequently_question:"/update_frequently_question",
    delete_frequently_question:"/delete_frequently_question",

    add_update_founderos_process_name:"/add_update_founderos_process_name",
    get_founderos_process_name:"/get_founderos_process_name",
    add_teammates_invite_to_company: "/add_teammates_invite_to_company",
    get_user_card_details:"/get_user_card_details",
    upgrade_subscription:"/upgrade_subscription",
    get_user_license_count:"/get_user_license_count",
  },
};

export default API;

export const isSubscribedAccountant = () => {
  const userRole = localStorage.getItem('role');

  // Check if the user's role is 3
  if (userRole === '3') {
    return true;
  }

  const isSubscribed = localStorage.getItem('isSubscriptionValid');
  if (isSubscribed === 'true') {
    return true;
  } else {
    return false;
  }
};
