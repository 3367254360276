import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';
import { MdKeyboardArrowRight } from "react-icons/md";

const DynamicStep = ({ questions, formData, handleChange }) => (
    <div className='row'>
        {questions.map((question, index) => (
            <div key={index} className="col-md-12 col-lg-12 col-sm-12">
                <div className="form-group mb-2">
                    <label><b>{question.question}</b></label>
                    <div className="row">
                        {[1, 2, 3, 4, 5].map((num) => (
                            <div key={num} className="col-md-2 col-lg-2 col-sm-12">
                                <input
                                    type="text"
                                    className={`form-control`}
                                    placeholder={`Answer ${num}`}
                                    name={`${question.question_id}_${num}`}
                                    value={formData[`${question.question_id}_${num}`] || ''}
                                    onChange={(e) => handleChange(e, question.question_id, num)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ))}
    </div>
);

// const QuadrantLayout = ({ quadrantTitle, questions, formData }) => {
//     // Group questions if not already done
//     const groupedQuestions = questions.reduce((acc, question) => {
//         const key = question.b_s_m_c_id || 'default'; // Group by b_s_m_c_id
//         if (!acc[key]) {
//             acc[key] = [];
//         }
//         acc[key].push(question);
//         return acc;
//     }, {});

//     return (
//         <div className="col-md-6 quadrant">
//             <h5 className="quadrant-title">{quadrantTitle}</h5>
//             {Object.keys(groupedQuestions).length === 0 ? (
//                 <p>No questions available for this category.</p>
//             ) : (
//                 Object.keys(groupedQuestions).map((groupId) => {
//                     const questionsInGroup = groupedQuestions[groupId];

//                     return questionsInGroup.map((question) => {
//                         // Parse the answer if it's a string and handle null case
//                         const answers = question.answer ? JSON.parse(question.answer) : [];

//                         return (
//                             <div key={question.question_id}>
//                                 <h6>Question ID: {question.question_id}</h6>
//                                 {answers.length > 0 ? (
//                                     answers.map((answer, index) => (
//                                         <p key={index}>
//                                             Answer {`${question.question_id}_${index + 1}`}: {answer || 'No answer given'}
//                                         </p>
//                                     ))
//                                 ) : (
//                                     <p>No answers set for this question.</p>
//                                 )}
//                             </div>
//                         );
//                     });
//                 })
//             )}
//         </div>
//     );
// };

const QuadrantLayout = ({ quadrantTitle, questions, selectedQuestionId, bgColor }) => {
    // Filter the questions based on the selectedQuestionId
    const filteredQuestions = questions.filter(
        (question) => question.question_id === selectedQuestionId
    );

    return (
        <div className="col-md-6 w-75 quadrant">
            <h5 className="quadrant-title" style={{ backgroundColor: bgColor }}>{quadrantTitle}</h5>
            {filteredQuestions.length === 0 ? (
                <p>No questions available for this category.</p>
            ) : (
                filteredQuestions.map((question) => {
                    const answers = question.answer ? JSON.parse(question.answer) : [];

                    return (
                        <div key={question.question_id} className="question-box" >
                            {answers.length > 0 ? (
                                answers.map((answer, index) => (
                                    <p className='' key={index}>
                                        <b><MdKeyboardArrowRight />{answer || 'No answer given'}</b>
                                    </p>
                                ))
                            ) : (
                                <p className='justify-content-center d-flex'>No answers set for this question.</p>
                            )}
                        </div>
                    );
                })
            )}
        </div>
    );
};


const Swot = () => {
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('b_s_m_c_id', "4");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_business_strategy_question}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.data || []);
                const initialFormData = {};
                (response.data.data || []).forEach((question) => {
                    const answers = question.answer ? JSON.parse(question.answer) : Array(5).fill('');
                    answers.forEach((answer, index) => {
                        initialFormData[`${question.question_id}_${index + 1}`] = answer;
                    });
                });
                setFormData(initialFormData);

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch questions.');
        }
    };

    const debouncedApiCall = useCallback(
        debounce(async (question_id, answers) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id', question_id);

            answers.forEach((answer) => {
                formDataToSend.append('answer[]', answer);
            });

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.add_update_business_strategy_swot}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    toast.success(response.data.message);
                    fetchQuestions();
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid] // Ensure uid is in dependencies for debounced function
    );

    const handleChange = (e, question_id, input_num) => {
        const { value } = e.target;

        setFormData((prevData) => {
            const updatedData = { ...prevData, [`${question_id}_${input_num}`]: value };
            const answers = Array.from({ length: 5 }, (_, i) => updatedData[`${question_id}_${i + 1}`] || '');
            debouncedApiCall(question_id, answers);
            return updatedData;
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>SWOT</h5>
                        </div>
                        <form>
                            <div className="card-body m-3">
                                <DynamicStep questions={questions} formData={formData} handleChange={handleChange} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="container swot-quadrant-layout p-3">
                <div className="row justify-content-lg-end w-75">
                    <QuadrantLayout quadrantTitle="Strengths" questions={questions} selectedQuestionId="21" bgColor="hsl(214.75deg 79.22% 45.29%)" /> {/* Light green */}
                    <QuadrantLayout quadrantTitle="Weaknesses" questions={questions} selectedQuestionId="22" bgColor="hsl(203.62deg 100% 50.2%)" /> {/* Light red */}
                </div>
                <div className="row w-75">
                    <QuadrantLayout quadrantTitle="Opportunities" questions={questions} selectedQuestionId="23" bgColor="hsl(99.03deg 52.82% 61.76%)" /> {/* Light yellow */}
                    <QuadrantLayout quadrantTitle="Threats" questions={questions} selectedQuestionId="24" bgColor="hsl(38.82deg 100% 50%)" /> {/* Light blue */}
                </div>
            </div>
        </>
    );
};

export default Swot;
