import React, {useEffect } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { isSubscribedAccountant } from "../../utils";
import { TbReportSearch } from "react-icons/tb";

const Indexsupport = () => {
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <Nav variant="pills" className="border-tab nav-primary">
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/customerSupport/frequentlyAskedQuestion" exact>
                            <TbReportSearch className="me-1" /> Frequently Asked Questions
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/customerSupport/foudersosUniversity" exact>
                            <TbReportSearch className="me-1" /> Founders OS University
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={NavLink} to="/customerSupport/contactSupport" exact>
                            <FaHome className="me-1" />Contact Support
                        </Nav.Link>
                    </Nav.Item>
               </Nav>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Indexsupport;
