import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import API from '../../../../../utils';
import { toast } from 'react-toastify';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const RiskActions = () => {
    const actionsList = {
        "Governance": [
            { id: 1, name: "Establish formal roles and responsibilities to manage and monitor all major risks" },
            { id: 2, name: "Introduce/Elevate the role of Chief Risk Officer (CRO) as a critical part of the C-suite to drive active collaboration with other CxOs and help develop robust risk strategies" },
            { id: 3, name: "Establish a formal governance structure to manage risk, including an independent board of directors and other active committees" },
            { id: 4, name: "Implement the “three lines of defense” model (leadership, risk management and internal audit) across the organization" },
            { id: 5, name: "Improve/enhance the internal audit function capabilities (e.g. skills/competencies training and development, technology enablement)" },
            { id: 6, name: "Expand the scope of the internal audit program to include frequent audits on all key risk areas (not just financial and operational)" },
            { id: 7, name: "Conduct periodic assessment of risk governance and introduce changes to drive long-term improvements" }
        ],
        "Risk Management": [
            { id: 8, name: "Develop enterprise-wide risk management system covering outside, downside, as well as upside risks (risk taking required to realize the company’s growth and long-term value objectives)" },
            { id: 9, name: "Develop structured approach to identify and assess key risks (incl. geo-political, 3rd party, financial, tax, operational, ESG, cyber, technology, reputational, talent and regulatory risks) and agree on risk appetite" },
            { id: 10, name: "Embed risk management into all key initiatives and expenditures" },
            { id: 11, name: "Weave risk management into the strategic planning process by increasing the contribution and overall participation of the risk function in strategy development to proactively manage risks and drive agility in risk-based decision-making" },
            { id: 12, name: "Enable trust and confidence by instilling the right risk mindset across the organization by defining the risk culture, setting aspirations and driving cultural transformation through programs that promote desired behaviors" },
            { id: 13, name: "Align key business risks to Key Performance Indicators (KPIs) and embed in role descriptions and performance criteria to ensure business objectives are met" },
            { id: 14, name: "Collaborate with the HR team to educate and empower employees regarding any job-related risk management matters (e.g. data handling, security awareness, vigilance)" },
            { id: 15, name: "Build a strong virtual working culture mitigating risks arising from increased remote working and hybrid models (e.g. employee disconnect, work/life balance, weakening organizational culture, cyber security)" }
        ],
        "Policies, Procedures and Compliance": [
            { id: 16, name: "Review and (re-)align policies and procedures with business strategy" },
            { id: 17, name: "Define organizational risk strategy and see that it is clearly articulated and rigorously applied to promote decision-making at all levels" },
            { id: 18, name: "Set up/enhance a comprehensive, documented, group-wide policy framework to manage business risks with a strong leadership tone from the top" },
            { id: 19, name: "Widen the scope of risk management policies and procedures to cover stakeholders in the extended value chain (third parties)" },
            { id: 20, name: "Verify that appropriate third parties adhere to relevant policies and procedures" },
            { id: 21, name: "Regularly communicate importance of risk management from leadership" },
            { id: 22, name: "Review existing policies and insurance contracts to identify new, additional or alternate coverage for key risk events" },
            { id: 23, name: "Implement governance, risk and compliance (GRC) technologies that provide real-time, predictive risk intelligence with risk-sensing capabilities" }
        ],
        "Stakeholder Management": [
            { id: 24, name: "Identify key stakeholders and assign responsibilities to manage their needs across senior management" },
            { id: 25, name: "Develop corporate story (incl. risk strategy) and tailor for specific stakeholders" },
            { id: 26, name: "Ensure that crisis-related decisions, actions and communications are consistent with the company's purpose, culture and values" },
            { id: 27, name: "Develop/enhance clear and pro-active risk communication process for all key stakeholders" },
            { id: 28, name: "Pro-actively and periodically communicate risk practices to all stakeholder groups (incl. investor community)" },
            { id: 29, name: "Create a stakeholder response strategy for all risk occurrences impacting stakeholder confidence and trust" },
            { id: 30, name: "Develop strategy to influence key policymakers" },
            { id: 31, name: "Develop and publish annual corporate social responsibility report (e.g. sustainability)" }
        ]
    };
    const [openCategory, setOpenCategory] = useState(null);
    const [checkedValue, setCheckedValue] = useState({});
    const uid = localStorage.getItem("user_id");

    const handleCategoryToggle = (category) => {
        setOpenCategory(openCategory === category ? null : category);
    };

    // Function to fetch data from API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_risk}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_action;

                if (jsonData) {
                    try {
                        const data = JSON.parse(jsonData);

                        const parseCategoryData = (categoryData) => {
                            return categoryData ? categoryData.split(',') : [];
                        };

                        const actions = {
                            Capability: parseCategoryData(data.capability || ''),
                            Innovation: parseCategoryData(data.innovation || ''),
                            Leadership: parseCategoryData(data.leadership || ''),
                            "Performance Management": parseCategoryData(data.performance_management || ''),  // Updated key here
                            Rewards: parseCategoryData(data.rewards || ''),
                        };

                        setCheckedValue(actions);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No data found for technology_action.');
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch technology actions error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Function to format selected actions for API
    const formatSelectedActions = (selectedActions) => {
        const categories = {
            "Risk Management": "risk_management[]",
            "Governance": "governance[]",
            "Policies, Procedures and Compliance": "policies_procedures_and_compliance[]",
            "Stakeholder Management": "stakeholder_management[]",
        };

        const formatted = Object.keys(categories).reduce((acc, category) => {
            const selectedData = selectedActions
                .filter(action => action.category === category)
                .map(action => {
                    const actionDetails = actionsList[category].find(a => a.id === action.id);
                    return actionDetails ? actionDetails.name : null;  // Directly returning the action name
                })
                .filter(action => action !== null);

            // Ensure this is an array, even if empty
            acc[categories[category]] = selectedData;
            return acc;
        }, {});

        return formatted;
    };


    const handleAddToActions = async (category, action, index) => {
        try {
            // Toggle selection state
            const newCheckedValue = { ...checkedValue };
            const isSelected = newCheckedValue[category]?.includes(action.id);
            if (isSelected) {
                newCheckedValue[category] = newCheckedValue[category].filter(id => id !== action.id);
            } else {
                newCheckedValue[category] = [...(newCheckedValue[category] || []), action.id];
            }
            setCheckedValue(newCheckedValue);

            // Update selected actions state
            const updatedActions = Object.keys(newCheckedValue).flatMap(cat =>
                newCheckedValue[cat].map(id => ({ category: cat, id }))
            );


            // Make API call
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            // Format the actions correctly
            const formattedActions = formatSelectedActions(updatedActions);

            // Append formatted actions to FormData
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  // To represent an empty array
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_risk_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
            toast.error("An error occurred while updating data.");
        }
    };
    const renderActions = (category) => {
        const categoryActions = actionsList[category] || [];

        return categoryActions.map((action, index) => (
            <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
                <div className="action-card p-3 border">
                    <p>{action.name}</p>
                    <Button
                        variant={checkedValue[category]?.includes(action.id) ? "success" : "primary"}
                        onClick={() => handleAddToActions(category, action, index)}
                        className="d-flex align-items-center mt-3 p-2"
                    >
                        {checkedValue[category]?.includes(action.id) ? <FaCheck className="me-2" /> : <FaPlus className="me-2" />}
                        {checkedValue[category]?.includes(action.id) ? "Selected" : "Add to Actions"}
                    </Button>
                </div>
            </Col>
        ));
    };

    return (
        <div className="col-sm-12">
            <div className="card mt-4">
                <div className="card-header">
                    <div className='justify-content-between d-flex '>
                        <div className="">
                            <h5>Actions for Risk</h5>
                        </div>
                        <div>
                            <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <p>To help you achieve your goals we have created actions learned from our insights of working with market leaders. Add ones that you feel are best for your business.</p>
                        <Accordion defaultActiveKey="0" activeKey={openCategory}>
                            {Object.keys(actionsList).map(category => (
                                <Accordion.Item eventKey={category} key={category}>
                                    <Accordion.Header onClick={() => handleCategoryToggle(category)}>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <h5 className='mb-0 fw-bold'>{category}</h5>
                                            <p className='mb-0 text-primary'>View Actions</p></div>

                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {openCategory === category && renderActions(category)}
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskActions;
