import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../utils';
import { useEffect } from 'react';

const PowerOfOneForm = () => {
    const uid = localStorage.getItem('user_id');
    const [netCashFlow, setNetCashFlow] = useState('');
    const [ebit, setEbit] = useState('');
    const [changes, setChanges] = useState({
        priceIncrease: '',
        volumeIncrease: '',
        cogsReduction: '',
        overheadsReduction: '',
        reductionInDebtorsDays: '',
        reductionInStockDays: ''
    });
    const [results, setResults] = useState({
        totalImpact: '',
        adjustedNetCashFlow: '',
        adjustedEbit: ''
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Check if value is a valid number
        if (!/^\d*\.?\d*$/.test(value)) {
            return; // Ignore invalid inputs
        }

        if (name === 'netCashFlow') {
            setNetCashFlow(value);
        } else if (name === 'ebit') {
            setEbit(value);
        } else {
            setChanges({ ...changes, [name]: value });
        }
    };

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.power_get}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data;
                setNetCashFlow(data.netcashflow || '');
                setEbit(data.ebit || '');
                setChanges({
                    priceIncrease: data.priceIncrease || '',
                    volumeIncrease: data.volumeincrease || '',
                    cogsReduction: data.cogsreduction || '',
                    overheadsReduction: data.overheadsreduction || '',
                    reductionInDebtorsDays: data.reductionIndebtorsdays || '',
                    reductionInStockDays: data.reductionInstockdays || ''
                });
                setResults({
                    totalImpact: calculateTotalImpact(), 
                    adjustedNetCashFlow: '', 
                    adjustedEbit: ''
                });
            } else {
                // toast.error(response.data.message || 'Failed to fetch data.');
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
            //toast.error('Failed to fetch data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const calculateImpact = (percentage) => {
        if (!netCashFlow || isNaN(netCashFlow) || !percentage || isNaN(percentage)) return '0.00';
        return (parseFloat(netCashFlow) * (parseFloat(percentage) / 100)).toFixed(2);
    };

    const calculateTotalImpact = () => {
        const { priceIncrease, volumeIncrease, cogsReduction, overheadsReduction } = changes;
        const totalImpact = (parseFloat(calculateImpact(priceIncrease)) || 0) +
            (parseFloat(calculateImpact(volumeIncrease)) || 0) +
            (parseFloat(calculateImpact(cogsReduction)) || 0) +
            (parseFloat(calculateImpact(overheadsReduction)) || 0);
        return totalImpact.toFixed(2);
    };

    const updateResults = () => {
        const totalImpact = calculateTotalImpact();
        setResults({
            totalImpact: totalImpact,
            adjustedNetCashFlow: totalImpact, 
            adjustedEbit: totalImpact 
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = {};
        if (!/^\d*\.?\d*$/.test(netCashFlow)) {
            validationErrors.netCashFlow = "Net Cash Flow must be a valid number";
        }
        if (!/^\d*\.?\d*$/.test(ebit)) {
            validationErrors.ebit = "EBIT must be a valid number";
        }
        if (Object.values(changes).some(value => !/^\d*\.?\d*$/.test(value))) {
            validationErrors.changes = "All fields must be valid numbers";
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            updateResults(); 

            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('netcashflow', netCashFlow);
            formDataToSend.append('ebit', ebit);
            formDataToSend.append('priceIncrease', changes.priceIncrease);
            formDataToSend.append('volumeincrease', changes.volumeIncrease);
            formDataToSend.append('cogsreduction', changes.cogsReduction);
            formDataToSend.append('overheadsreduction', changes.overheadsReduction);
            formDataToSend.append('reductionIndebtorsdays', changes.reductionInDebtorsDays);
            formDataToSend.append('reductionInstockdays', changes.reductionInStockDays);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.power_insert}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                    fetchData();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during request:", error);
                toast.error('Failed to submit the form. Please try again later.');
            }
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-12 align-items-center d-flex">
                        <h5>Cash: The Power of One</h5>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    <div className="form-section">
                        <div className='row my-3'>
                            <div className='col-md-4 d-flex align-items-center'>
                                <label><b>Current Position</b></label>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input
                                        className='form-control mb-3'
                                        type="text"
                                        placeholder="Net Cash Flow $"
                                        name="netCashFlow"
                                        value={netCashFlow}
                                        onChange={handleInputChange}
                                        pattern="\d*\.?\d*"
                                    />
                                    {errors.netCashFlow && <p className="text-danger">{errors.netCashFlow}</p>}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group ">
                                    <input
                                        className='form-control mb-3'
                                        type="text"
                                        placeholder="EBIT $"
                                        name="ebit"
                                        value={ebit}
                                        onChange={handleInputChange}
                                        pattern="\d*\.?\d*"
                                    />
                                    {errors.ebit && <p className="text-danger">{errors.ebit}</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="life-planing-div">
                        <table className="life-planning-table table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">{"Your Power of One"}</th>
                                    <th scope="col">{"Change you would like to make"}</th>
                                    <th scope="col">{"Annual Impact on Cash Flow $"}</th>
                                    <th scope="col">{"Impact on EBIT $"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { label: 'Price Increase', name: 'priceIncrease' },
                                    { label: 'Volume Increase', name: 'volumeIncrease' },
                                    { label: 'COGS Reduction', name: 'cogsReduction' },
                                    { label: 'Overheads Reduction', name: 'overheadsReduction' },
                                    { label: 'Reduction in Debtors Days', name: 'reductionInDebtorsDays' },
                                    { label: 'Reduction in Stock Days', name: 'reductionInStockDays' }
                                ].map(({ label, name }, index) => (
                                    <tr key={index}>
                                        <td>{label}</td>
                                        <td>
                                            <div className="cash-flow-table">

                                                <input
                                                    className='form-control cash-form-control'
                                                    type="text"
                                                    placeholder={name.includes('Days') ? 'day(s)' : '%'}
                                                    name={name}
                                                    value={changes[name]}
                                                    onChange={handleInputChange}
                                                    pattern="\d*\.?\d*"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="cash-flow-table">
                                                <input
                                                    className='form-control cash-form-control'
                                                    type="text"
                                                    value={name.includes('Days') ? '' : calculateImpact(changes[name])}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="cash-flow-table">
                                                <input
                                                    className='form-control cash-form-control'
                                                    type="text"
                                                    value={name.includes('Days') ? '' : calculateImpact(changes[name])}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="form-section">
                        <div className='row my-3'>
                            <div className='col-md-4 d-flex align-items-center'>
                                <label><b>Your Power of One Impact</b></label>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className='form-control mb-3'
                                        placeholder="Net Cash Flow $"
                                        value={results.totalImpact}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input
                                        className='form-control mb-3'
                                        type="text"
                                        placeholder="EBIT $"
                                        value={results.totalImpact}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-section">
                        <div className='row my-3'>
                            <div className='col-md-4 d-flex align-items-center'>
                                <label><b>Your Adjusted Position</b></label>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className='form-control mb-3'
                                        placeholder="Net Cash Flow $"
                                        value={results.adjustedNetCashFlow}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className='form-control mb-3'
                                        placeholder="EBIT $"
                                        value={results.adjustedEbit}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card-footer'>
                    <div className='d-flex justify-content-end'>
                        <Button type="submit" variant="success" className=" mb-2 me-1 float-end">Save</Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PowerOfOneForm;
