import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { isSubscribedAccountant } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const StartStopContinue = () => {

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteType, setDeleteType] = useState("");
  
  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);
  const toggleModal = () => {
    setShowModal(!showModal);
    resetForm();
  };
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const [formData, setFormData] = useState({
    taskType: '',
    Exercise: '',
  });
  const [errors, setErrors] = useState({
    taskType: '',
    Exercise: '',
  });
  const resetForm = () => {
    setFormData({ taskType: '', Exercise: '' });
    setErrors({ taskType: '', Exercise: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate(name, value);
  };
  const validate = (name, value) => {
    const newErrors = { ...errors };

    switch (name) {
      case 'taskType':
        newErrors.taskType = value ? '' : 'Task type is required';
        break;
      case 'Exercise':
        newErrors.Exercise = value ? '' : 'Exercise name is required';
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };
  const validateForm = () => {
    const { taskType, Exercise } = formData;
    const newErrors = {
      taskType: taskType ? '' : 'This Field is required',
      Exercise: Exercise ? '' : 'This Field is required',
    };

    setErrors(newErrors);

    return Object.values(newErrors).every(error => error === '');
  };
  const handleSave = () => {  
    const isValid = validateForm();

    if (isValid) {

      setFormData({ taskType: '', Exercise: '' });
      setErrors({ taskType: '', Exercise: '' });
      toggleModal();
    }
  };

  const handleConfirmDelete = (index, type) => {
    toggleDeleteModal();
  };

  return (
    <div>
      <div className="container-fluid">
        <div className='mb-4 d-flex justify-content-end'></div>

        <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Start-Stop-Continue Exercise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label><b>Select Exercise Type</b></label>
            <select
              name="taskType"
              className={`form-control ${errors.taskType ? 'is-invalid' : ''}`}
              value={formData.taskType}
              onChange={handleChange}
            >
              <option value="">Select Task Type</option>
              <option value="Start">Start</option>
              <option value="Stop">Stop</option>
              <option value="Continue">Continue</option>
            </select>
            {errors.taskType && <div className="invalid-feedback">{errors.taskType}</div>}
          </div>

          <div className="form-group mt-3">
            <label><b>Exercise Name</b></label>
            <input
              type="text"
              className={`form-control ${errors.Exercise ? 'is-invalid' : ''}`}
              name="Exercise"
              value={formData.Exercise}
              onChange={handleChange}
            />
            {errors.Exercise && <div className="invalid-feedback">{errors.Exercise}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>Close</Button>
          <Button variant="success" onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>

        <Modal show={showDeleteModal} onHide={toggleDeleteModal} className=''>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this item?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-primary me-1" onClick={() => handleConfirmDelete(deleteIndex, deleteType)}>Delete</Button>
            <Button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={toggleDeleteModal} className=''>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this item?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary me-1" onClick={() => handleConfirmDelete(deleteIndex, deleteType)}>Delete</button>
            <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
          </Modal.Footer>
        </Modal>

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Start-Stop-Continue</h5>
                    <Button className="btn btn-success main-buttons" onClick={toggleModal}>Add Exercise</Button>
                  </div>
                </div>
              </div>
              
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-9 align-items-center">
                    <span className='love-hate'>Start-Stop-Continue Exercise</span>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6 col-lg-4 mb-3">
                    <div className="card">
                      <div className="card-header">
                        <h5>I need to start doing this</h5>
                      </div>
                      <div className="card-body start-stop-conti">
                        <ul>
                          <li><div className='d-flex justify-content-lg-between main'>Why do we use it <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>Including versions of Lorem Ipsum <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>There are many variations <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>Why do we use it <FaTrash className="delete-icon" /></div></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 mb-3">
                    <div className="card">
                      <div className="card-header">
                        <h5>I need to stop doing this</h5>
                      </div>
                      <div className="card-body start-stop-conti">
                        <ul>
                          <li><div className='d-flex justify-content-lg-between main'>Why do we use it <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>Including versions of Lorem Ipsum <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>There are many variations <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>Why do we use it <FaTrash className="delete-icon" /></div></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4 mb-3">
                    <div className="card">
                      <div className="card-header">
                        <h5>I need to continue doing this</h5>
                      </div>
                      <div className="card-body start-stop-conti">
                        <ul>
                          <li><div className='d-flex justify-content-lg-between main'>Why do we use it <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>Including versions of Lorem Ipsum <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>There are many variations <FaTrash className="delete-icon" /></div></li>
                          <li><div className='d-flex justify-content-lg-between main'>Why do we use it <FaTrash className="delete-icon" /></div></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartStopContinue;
