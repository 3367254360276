import React, { useEffect, useState } from "react";
import { CardBody, Collapse } from 'reactstrap';
import API from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";

const HelpCenter = () => {
    const [isOpen, setIsOpen] = useState(null);
    const [users, setUsers] = useState([]);

    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_frequently_question}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // Sort the data based on created_at field
                const sortedUsers = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setUsers(sortedUsers);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while fetching data.");
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Frequently Asked Questions</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {users.length > 0 ? (
                                    users.map((item) => (
                                        <div key={item.id} className="col-md-12 col-lg-12">
                                            <div className="helpcenter-main">
                                                <div className="card helpcenter-main-card">
                                                    <h5 className="mb-0">
                                                        <button className="w-100 d-flex justify-content-between helpcenter-main-button" onClick={() => toggle(item.id)}>
                                                            <div>
                                                                {item.title}
                                                            </div>
                                                            <div>
                                                                {isOpen === item.id}
                                                            </div>
                                                        </button>
                                                    </h5>
                                                    <Collapse isOpen={isOpen === item.id}>
                                                        <CardBody>
                                                            {item.description}
                                                        </CardBody>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-md-12">
                                        <p>No FAQs available at the moment.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HelpCenter;
