import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";
import axios from "axios";


const Meetings = () => {
    const uid = localStorage.getItem("user_id");

    const [formData, setFormData] = useState({
        MeetingPagename: '',
        Level10Meeting: '',
        MeetingQuarterly: '',
        Quarterlysession: '',
        MeetingAnnual: '',
        MeetingSessionAnnual: '',

        Focusday: '',
        Visionthildingday: '',
        Visionthildingday2: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.MeetingPagename);
        formDataToSend.append('level_10_meeting', formData.Level10Meeting);
        formDataToSend.append('quarterly', formData.MeetingQuarterly);
        formDataToSend.append('quarterly_session', formData.Quarterlysession);

        formDataToSend.append('annual', formData.MeetingAnnual);
        formDataToSend.append('annual_session', formData.MeetingSessionAnnual);

        formDataToSend.append('focus_day', formData.Focusday);
        formDataToSend.append('vision_thilding_day_1', formData.Visionthildingday);
        formDataToSend.append('vision_thilding_day_2', formData.Visionthildingday2);


        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_meetings}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_meetings}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );


            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    MeetingPagename: data.page_name || "",
                    Level10Meeting: data.level_10_meeting || "",
                    MeetingQuarterly: data.quarterly || "",
                    Quarterlysession: data.quarterly_session || "",
                    MeetingAnnual: data.annual || "",
                    MeetingSessionAnnual: data.annual_session || "",

                    Focusday: data.focus_day || "",
                    Visionthildingday: data.vision_thilding_day_1 || "",
                    Visionthildingday2: data.vision_thilding_day_2 || "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };


    return (
        <>
            {/* Meetings */}
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="fa fa-comment mx-2"></i>Meetings</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Meeting Page Name"
                                    name="MeetingPagename"
                                    value={formData.MeetingPagename}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Level 10 Meeting™</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Level 10 Meeting"
                                    name="Level10Meeting"
                                    value={formData.Level10Meeting}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Quarterly</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Quarterly"
                                    name="MeetingQuarterly"
                                    value={formData.MeetingQuarterly}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Quarterly Session</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Quarterly Session"
                                    name="Quarterlysession"
                                    value={formData.Quarterlysession}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Annual</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Annual"
                                    name="MeetingAnnual"
                                    value={formData.MeetingAnnual}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Annual Session</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Ente Annual Session"
                                    name="MeetingSessionAnnual"
                                    value={formData.MeetingSessionAnnual}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Focus Day</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Focus Day"
                                    name="Focusday"
                                    value={formData.Focusday}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Vision thilding Day 1</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Vision thilding Day 1"
                                    name="Visionthildingday"
                                    value={formData.Visionthildingday}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Vision thilding Day 2</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Vision thilding Day 2"
                                    name="Visionthildingday2"
                                    value={formData.Visionthildingday2}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Meetings;