import React, { useEffect, useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Issue = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        IssuePagename: "",
        Issue: "",
        ShortTerm: "",
        LongTerm: "",

    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.IssuePagename);
        formDataToSend.append('issue', formData.Issue);
        formDataToSend.append('short_term_issue', formData.ShortTerm);
        formDataToSend.append('long_term_issue', formData.LongTerm);


        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_issues}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_issues}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    IssuePagename: data.page_name || "",
                    Issue: data.issue || "",
                    ShortTerm: data.short_term_issue || "",
                    LongTerm: data.long_term_issue || "",

                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };
    return (
        <>
            {/* Issues */}
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="fa fa-exclamation mx-2"></i>Issues</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Page Name"
                                    name="IssuePagename"
                                    value={formData.IssuePagename}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Issue</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Issue"
                                    name="Issue"
                                    value={formData.Issue}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Short-Term Issue</b></label>
                                <input
                                    className={`form-control `}
                                    type="text"
                                    placeholder="Enter Short Term Issue"
                                    name="ShortTerm"
                                    value={formData.ShortTerm}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Long-Term Issue</b></label>
                                <input
                                    className={`form-control`}
                                    type="text"
                                    placeholder="Enter Long Term Issue"
                                    name="LongTerm"
                                    value={formData.LongTerm}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Issue;