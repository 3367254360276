import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import API from "../../../utils";
import axios from "axios";
import { format } from "date-fns";
import RenewalModal from "../../../auth/cardDetailsmodal/renewModal";
import MyLoader from "../../common/myloader";
import { useNavigate } from "react-router-dom";
import Inviteteammatesmember from "../../invite-teammates/inviteteammates";
import { TbLicense } from "react-icons/tb";
import Licences from "./licenceModal/licence";

const Billings = () => {
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("role");
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  const navigate = useNavigate();
  const role = localStorage.getItem("role")
  const fetchData = async () => {
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", user_id);
    if (role) {
      formDataToSend.append("role", role);

    }

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_user_details}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const userData = response.data.user_data;

        // Check if the user's role is 3
        if (user_role === 3) {
          setIsSubscriptionExpired(false);
          setSubscriptionStatus("active");
          setLoading(false);
          return;
        }

        const subscriptionDate = userData.subscription_date
          ? new Date(userData.subscription_date)
          : null;
        const subscriptionDuration = userData.subscription_duration
          ? parseInt(userData.subscription_duration, 10)
          : 0;

        if (userData.free_plan === "1") {
          subscriptionDate.setDate(subscriptionDate.getDate());
          setIsFreeTrial(true);
        } else {
          setIsFreeTrial(false);
        }

        if (subscriptionDate && subscriptionDuration) {
          subscriptionDate.setDate(subscriptionDate.getDate() + subscriptionDuration);
          const formattedExpiryDate = format(subscriptionDate, "eeee, MMMM d, yyyy");
          setExpiryDate(formattedExpiryDate);

          const today = new Date();
          if (subscriptionDate < today) {
            setIsSubscriptionExpired(true);
            localStorage.setItem("isSubscriptionValid", false);
          } else {
            setIsSubscriptionExpired(false);
            localStorage.setItem("isSubscriptionValid", true);
          }
        } else {
          setExpiryDate("Not Available");
          setIsSubscriptionExpired(true);
        }

        setSubscriptionStatus(userData.subscription_status);
      } else {
        navigate(`/login`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (user_role === 3 && isSubscriptionExpired === true) {
      navigate("/login")
    }
  }, []);

  const handleRenewalModalShow = () => setShowRenewalModal(true);
  const handleRenewalModalClose = () => setShowRenewalModal(false);

  return (
    <>
      <MyLoader active={loading}>
        <Tabs
          defaultActiveKey="billing"
          id="justify-tab-example"
          className="border-tab nav-primary pt-3 mb-3"
        >
          <Tab eventKey="billing" title="Billing">
            <Row>
              <Col lg={12}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <TbLicense className="me-2" />
                      Subscription Details:
                    </h5>
                  </div>
                  <div className="card-body">
                    {isFreeTrial && (
                      <Col lg={12} className="mt-2">
                        <h4>
                          <i className="fa fa-check-square-o"></i> Stripe Billing
                        </h4>
                        <h6>You are enjoying your 1 month free trial</h6>
                        <h6>Your 1-month free trial is ending on:</h6>
                        <b className="text-danger">{expiryDate}</b>
                        <div className="mt-3">
                          <Licences fetchData={fetchData} />
                        </div>
                      </Col>
                    )}
                    {!isSubscriptionExpired && !isFreeTrial && (
                      <Col lg={12} className="mt-2">
                        <h4>
                          <i className="fa fa-check-square-o"></i> Stripe Billing
                        </h4>
                        <h6>You have successfully upgraded your plan.</h6>
                        <h6>Subscription expires on:</h6>
                        <b className="text-danger">{expiryDate}</b>
                        <div className="mt-3">
                          <Licences />
                        </div>
                      </Col>
                    )}
                    {isSubscriptionExpired && subscriptionStatus === "0" && (
                      <div className="text-danger mt-2">
                        <h6>Take a new subscription to access the application</h6>
                        <button
                          className="btn btn-primary"
                          style={{ cursor: "pointer" }}
                          onClick={handleRenewalModalShow}
                        >
                          Buy New Subscription
                        </button>
                      </div>
                    )}
                    {isSubscriptionExpired && subscriptionStatus === "2" && (
                      <div className="text-danger mt-2">
                        <h6>Your subscription has expired.</h6>
                        <button
                          className="btn btn-primary"
                          style={{ cursor: "pointer" }}
                          onClick={handleRenewalModalShow}
                        >
                          Renew Your Subscription
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          {!isSubscriptionExpired && (
            <Tab eventKey="licenses" title="Licenses">
              <Inviteteammatesmember />
            </Tab>
          )}
        </Tabs>
        <RenewalModal
          loading={loading}
          show={showRenewalModal}
          handleClose={handleRenewalModalClose}
          fetchData={fetchData}
        />
      </MyLoader>
    </>
  );
};

export default Billings;
