import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import API from '../../../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function FinanceAssessment() {
    const [selectedOption, setSelectedOption] = useState('');
    const [cultureOption, setCultureOption] = useState('');
    const [planningOption, setPlanningOption] = useState('');
    const [performanceOption, setPerformanceOption] = useState('');
    const [feedbackOption, setFeedbackOption] = useState('');
    const [valuesOption, setValuesOption] = useState('');
    const [innovationOption, setInnovationOption] = useState('');
    const [embeddingOption, setEmbeddingOption] = useState('');
    const [leadershipOption, setLeadershipOption] = useState('');
    const [rewardsOption, setRewardsOption] = useState('');
    const [rewardsStrategyOption, setRewardsStrategyOption] = useState('');
    const [RewardsDigitalContextOption, setRewardsDigitalContextOption] = useState('');
    const uid = localStorage.getItem("user_id");

    const updateTask = async (formDataToSend) => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_finance_assesment}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: API.AUTHORIZATION_TOKEN,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while updating data.");
        }
    };

    const handleChange = (setter) => (event) => {
        setter(event.target.value);
    };
    useEffect(() => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('Funding_Growth_Sources_Capital', selectedOption);
        formDataToSend.append('Funding_Growth_Working_Capital', cultureOption);
        formDataToSend.append('Funding_Growth', planningOption);
        formDataToSend.append('People_Organization_People', performanceOption);
        formDataToSend.append('People_Organization_Organization', feedbackOption);
        formDataToSend.append('Finance_Process_Controls_Finance_Processes', valuesOption);
        formDataToSend.append('Finance_Process_Controls_Finance_Controls', innovationOption);
        formDataToSend.append('Data_Technology_Technology', embeddingOption);
        formDataToSend.append('Data_Technology_Data', leadershipOption);
        formDataToSend.append('Stakeholder_Management_Stakeholder_Identification', rewardsOption);
        formDataToSend.append('Stakeholder_Management', rewardsStrategyOption);
        formDataToSend.append('Rewards_Strategy', RewardsDigitalContextOption);

        // Only update if any field has a value
        if (selectedOption || cultureOption || planningOption || performanceOption || feedbackOption || valuesOption || innovationOption || embeddingOption || leadershipOption || rewardsOption || rewardsStrategyOption || RewardsDigitalContextOption) {
            updateTask(formDataToSend);
        }
    }, [selectedOption, cultureOption, planningOption, performanceOption, feedbackOption, valuesOption, innovationOption, embeddingOption, leadershipOption, rewardsOption, rewardsStrategyOption, RewardsDigitalContextOption]);


    const [checkedValue, setCheckedValue] = useState({ finance_assesment: '' });


    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_finance}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {

                const jsonData = response.data.data.finance_assesment;

                let data;
                try {
                    data = JSON.parse(jsonData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);

                    return;
                }

                // Update the state with the decoded values
                setSelectedOption(data.Funding_Growth_Sources_Capital);
                setCultureOption(data.Funding_Growth_Working_Capital);
                setPlanningOption(data.Funding_Growth);
                setPerformanceOption(data.People_Organization_People);
                setFeedbackOption(data.People_Organization_Organization);
                setValuesOption(data.Finance_Process_Controls_Finance_Processes);
                setInnovationOption(data.Finance_Process_Controls_Finance_Controls);
                setEmbeddingOption(data.Data_Technology_Technology);
                setLeadershipOption(data.Data_Technology_Data);
                setRewardsOption(data.Stakeholder_Management_Stakeholder_Identification);
                setRewardsStrategyOption(data.Stakeholder_Management);
                setRewardsDigitalContextOption(data.Rewards_Strategy);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch meeting types error', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    const [completionPercentage, setCompletionPercentage] = useState(0);

    const calculateCompletionPercentage = () => {
        const values = {
            Leading: 8.34,
            Established: 5.56,
            Developing: 2.78,
        };

        const answers = [
            selectedOption,
            cultureOption,
            planningOption,
            performanceOption,
            feedbackOption,
            valuesOption,
            innovationOption,
            embeddingOption,
            leadershipOption,
            rewardsOption,
            rewardsStrategyOption,
        ];

        const totalScore = answers.reduce((total, answer) => {
            if (answer) {
                total += values[answer] || 0;
            }
            return total;
        }, 0);

        // Assuming the max score is 100 (12 questions with max score of 8.34)
        const maxScore = 12 * 8.34;
        const percentage = (totalScore / maxScore) * 100;

        localStorage.setItem('financeCompletionPercentage', percentage);

        return percentage;
    };

    useEffect(() => {
        calculateCompletionPercentage();
    }, [selectedOption, cultureOption, planningOption, performanceOption, feedbackOption, valuesOption, innovationOption, embeddingOption, leadershipOption, rewardsOption, rewardsStrategyOption]);

    useEffect(() => {
        const storedPercentage = localStorage.getItem('completionPercentage');
        if (storedPercentage) {
            setCompletionPercentage(Number(storedPercentage)); // Set the state with stored value
        }
    }, []);

    return (
        <div className="col-sm-12">
            <div className="card mt-4">
                <div className="card-header">
                    <div className='justify-content-between align-items-center d-flex'>
                        <div className=' align-items-center d-flex'>
                            <h5>Finance Assessment</h5>
                        </div>

                        <div className='ms-3 align-items-center d-flex ms-3'>
                            <div className='m-r-15'>
                                <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                            </div>
                            <CircularProgressbar className='assessmentprogress'
                                value={completionPercentage}
                                text={`${Math.round(completionPercentage)}%`}
                                styles={{
                                    path: {
                                        stroke: 'green', // Change the stroke color to black
                                    },
                                    text: {
                                        fill: 'black', // Change the text color to black
                                        fontSize: '16px', // Optional: Adjust font size
                                    },
                                    trail: {
                                        stroke: '#d6d6d6', // Optional: Change the trail color (if you want)
                                    },
                                }}
                            />

                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card-body">
                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Funding Growth – Sources of Capital</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={selectedOption === 'Leading'}
                                        onChange={handleChange(setSelectedOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There are established sources of optimal capital and associated costs (e.g. debt, bonds, private equity and capital markets).
                                </p>
                                <p className="textnots-text">
                                    •	Tax planning considered a source of capital.
                                </p>
                                <p className="textnots-text">
                                    •	A robust capital allocation plan is in place.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"

                                        checked={selectedOption === 'Established'}
                                        onChange={handleChange(setSelectedOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is access to multiple sources of capital to fund growth (e.g. private equity, debt, and equity capital markets).
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"

                                        checked={selectedOption === 'Developing'}
                                        onChange={handleChange(setSelectedOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Capital sources are identified and evaluated.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Funding Growth – Working Capital</strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"

                                        checked={cultureOption === 'Leading'}
                                        onChange={handleChange(setCultureOption)}


                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Working capital optimized as an asset, not a liability
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"

                                        checked={cultureOption === 'Established'}
                                        onChange={handleChange(setCultureOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business knows how to improve working capital but hasn't fully implemented it yet.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"

                                        checked={cultureOption === 'Developing'}
                                        onChange={handleChange(setCultureOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Leaders are aware of how working capital supports funding.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>
                                        Finance – Funding Growth
                                    </strong>
                                </b></label>
                                <p className="textnots-text">
                                    Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)                                </p>
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={planningOption === 'Leading'}
                                        onChange={handleChange(setPlanningOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The growth funding strategy fully supports the business strategy and the company's objective to create long-term value.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={planningOption === 'Established'}
                                        onChange={handleChange(setPlanningOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There are evolving functions to manage banks, investors and other stakeholders (treasury, tax, risk and investor relations).
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={planningOption === 'Developing'}
                                        onChange={handleChange(setPlanningOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Leaders are focused on top-line growth and liquidity events.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – People and Organization - People</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={performanceOption === 'Leading'}
                                        onChange={handleChange(setPerformanceOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The structure is an optimal combination of shared services, outsourcing and in-house capability, which enables the group to deliver consistent services and to be highly agile.
                                </p>
                                <p className="textnots-text">
                                    •	The structure is highly efficient and effective.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={performanceOption === 'Established'}
                                        onChange={handleChange(setPerformanceOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Certain activities are centralized to manage costs.
                                </p>
                                <p className="textnots-text">
                                    •	The structure is highly efficient or highly effective, but not yet both.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={performanceOption === 'Developing'}
                                        onChange={handleChange(setPerformanceOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The structure is highly efficient or highly effective, but not yet both.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – People and Organization - Organization</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={feedbackOption === 'Leading'}
                                        onChange={handleChange(setFeedbackOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	An open, innovative and value-driven finance culture augmented with technology and data science has created a finance workforce of the future.
                                </p>
                                <p className="textnots-text">
                                    •	Finance is a reliable pipeline of leadership talent for the business.
                                </p>
                                <p className="textnots-text">
                                    •	The business units view the finance function as a true business partner.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={feedbackOption === 'Established'}
                                        onChange={handleChange(setFeedbackOption)}

                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Strong analytical capability drives business value.
                                </p>
                                <p className="textnots-text">
                                    •	The business units view the finance function as knowledgeable and relevant.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={feedbackOption === 'Developing'}
                                        onChange={handleChange(setFeedbackOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business units view the finance function as knowledgeable and relevant.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Finance Process and Controls – Finance Processes</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={valuesOption === 'Leading'}
                                        onChange={handleChange(setValuesOption)}

                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Financial policies and processes are consistent across the enterprise and are highly automated and strategic. They secure resiliency and drive long-term value.
                                </p>
                                <p className="textnots-text">
                                    •	Integrated financial scenario planning factoring in cost and revenue forecasts, macroeconomic and governmental intervention, is highly effective.
                                </p>
                                <p className="textnots-text">
                                    •	Dynamic business planning (vs traditional quarterly/annual planning cycles) allows for agile decision-making.
                                </p>
                                <p className="textnots-text">
                                    •	Integrated financial and tax planning provisioning and compliance minimizes financial and reputational risk.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"

                                        checked={valuesOption === 'Established'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Timely financial reporting and analysis are used to inform operational decision-making.
                                </p>
                                <p className="textnots-text">
                                    •	The finance and tax process is responsive.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={valuesOption === 'Developing'}
                                        onChange={handleChange(setValuesOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The primary focus is on measuring and reporting.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Finance Process and Controls – Finance Controls</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={innovationOption === 'Leading'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Financial controls are consistent across the enterprise and are highly automated and strategic.
                                </p>
                                <p className="textnots-text">
                                    •	They safeguard against interdependent risks including geopolitics, disruptive technology and cybersecurity.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={innovationOption === 'Established'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business has robust financial controls, which are predominantly preventative.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={innovationOption === 'Developing'}
                                        onChange={handleChange(setInnovationOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Financial and tax controls are detective rather than preventative. They are primarily maintained by executives.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Data and Technology - Technology</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={embeddingOption === 'Leading'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Efficient and effective financial systems enable finance and tax resources to focus on enhancing business performance.
                                </p>
                                <p className="textnots-text">
                                    •	New technologies (e.g. AI, blockchain, RPA) are deployed at speed creating a transparent, data-driven and smart function.
                                </p>
                                <p className="textnots-text">
                                    •	Finance processes are automated end-to-end, reducing cost and making the function highly agile and future-ready.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        checked={embeddingOption === 'Established'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Multiple legacy systems support financial and tax processes at a relatively high cost.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={embeddingOption === 'Developing'}
                                        onChange={handleChange(setEmbeddingOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Finance and tax teams rely heavily on spreadsheets to compensate for underdeveloped core finance systems.
                                </p>
                            </div>
                        </div>


                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Data and Technology - Data</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={leadershipOption === 'Leading'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Financial data is an asset that is actively managed and highly valued. It strongly enhances strategic decision-making.
                                </p>
                                <p className="textnots-text">
                                    •	The Finance function's data and analytics capabilities have transformed the business' forecasting and scenario planning, risk management, cost reduction and understanding of value drivers.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={leadershipOption === 'Established'}
                                        onChange={handleChange(setLeadershipOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Common financial data enables analysis across the enterprise.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={leadershipOption === 'Developing'}
                                        onChange={handleChange(setLeadershipOption)}

                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Accuracy is the primary focus of financial data owners.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Stakeholder Management – Stakeholder Identification</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={rewardsOption === 'Leading'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Key stakeholders are banks, primary investors, regulators, people, policy-makers and communities.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={rewardsOption === 'Established'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Key stakeholders are banks, primary investors, regulators and people.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Developing"
                                        checked={rewardsOption === 'Developing'}
                                        onChange={handleChange(setRewardsOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Key stakeholders are banks and primary investors.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance – Stakeholder Management</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={rewardsStrategyOption === 'Leading'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business clearly articulates its whole corporate 'story' and tailors it to individual stakeholders.
                                </p>
                                <p className="textnots-text">
                                    •	The business builds further trust and confidence with stakeholders by pro-actively reporting on its performance beyond just the financials (e.g. environmental, social impact, governance (ESG) and diversity) as part of its long-term value creation.
                                </p>
                                <p className="textnots-text">
                                    •	There is a track record of consistent delivery against promises - no surprises.                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Established"
                                        checked={rewardsStrategyOption === 'Established'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There is visible evidence of links between high performance, rewards and the achievement of business objectives.
                                </p>
                                <p className="textnots-text">
                                    •	There are clearly defined roles and responsibilities to manage key stakeholders.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={rewardsStrategyOption === 'Developing'}
                                        onChange={handleChange(setRewardsStrategyOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	The business builds trust with stakeholders by focusing on compliance and statutory requirements.
                                </p>
                            </div>
                        </div>

                        <div className="founderdriers mb-3">
                            <p className="textnots-text">
                                <label><b>

                                    <strong>Finance - Rewards - Strategy</strong>
                                </b></label>
                            </p>
                            <p className="textnots-text">
                                Please select the radio button that best describes where you are in your business. (Leading, Established, Developing)
                            </p>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Leading"
                                        checked={RewardsDigitalContextOption === 'Leading'}
                                        onChange={handleChange(setRewardsDigitalContextOption)}
                                    />
                                    <strong>Leading</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Behaviors, as well as outcomes, are rewarded for both the team and the individual.
                                </p>
                                <p className="textnots-text">
                                    •	A strong employer brand is used to attract, motivate and retain high performers and teams.
                                </p>

                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"

                                        type="radio"
                                        value="Established"
                                        checked={RewardsDigitalContextOption === 'Established'}
                                        onChange={handleChange(setRewardsDigitalContextOption)}
                                    />
                                    <strong>Established</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	There are various incentives and total rewards programs segmented to specific employee populations.
                                </p>
                            </div>

                            <div className='mb-2'>
                                <label><b>
                                    <input
                                        className="radio_animated"
                                        type="radio"
                                        value="Developing"
                                        checked={RewardsDigitalContextOption === 'Developing'}
                                        onChange={handleChange(setRewardsDigitalContextOption)}
                                    />
                                    <strong>Developing</strong>
                                </b></label>
                                <p className="textnots-text">
                                    •	Rewards are provided to attract and retain individuals and to meet business objectives.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinanceAssessment;
