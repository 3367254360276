import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import API from '../../../../../utils';
import { toast } from 'react-toastify';
import { FaCheck, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const TechnologyActions = () => {
    const actionsList = {
        "Digital Enterprise": [
            { id: 1, name: "Define the desired digital experience for all key stakeholders (e.g. lead to cash, procure to pay processes) driving increased stakeholder value and differentiation" },
            { id: 2, name: "Develop and deploy the required Technology roadmap, leveraging the latest technologies to enhance interaction with customers, employees and other business partners" },
            { id: 3, name: "Develop/enhance capabilities to adopt and deploy new technologies in an agile way" },
            { id: 4, name: "Encourage experimentation" },
            { id: 5, name: "Explore scenarios with respect to how digital technologies could disrupt the company’s existing business models and enable new ones" },
            { id: 6, name: "Set up a flexible budget to accommodate unforeseen technology investments on innovation initiatives" },
            { id: 7, name: "Encourage digital innovation across the organization" },
            { id: 8, name: "Establish a Chief Technology Officer (CTO) role with multidimensional mandates including product/process optimization, influencing customer experience, and mitigating business risk" },
            { id: 9, name: "Make the IT function a strategic business partner" },
            { id: 10, name: "Conduct a systematic Technology risk analysis (people/competencies, processes, systems, organization design, etc.) including outside, downside, and upside risks (risk taking required to realize the company’s growth and long-term value objectives)" }
        ],
        "Data and Analytics": [
            { id: 11, name: "Ensure/improve consistently high quality levels of both internal and external data by introducing/improving effective Enterprise data management and data quality processes" },
            { id: 12, name: "Develop/enhance capability to rapidly capture, store, analyze, and activate all of the organization's internal and external data" },
            { id: 13, name: "Introduce predictive analytics capability to support business decision-making" },
            { id: 14, name: "Introduce real-time analytics to track customer behavior and needs" },
            { id: 15, name: "Create a culture and approach to experimentation through analytics across the business" },
            { id: 16, name: "Create a data-driven culture where the use of techniques that fully leverage the internal and external data within the organization drives decision making" },
            { id: 17, name: "Develop/Improve strong data-governance processes to use, secure, and share data across the organization to remain compliant with data (privacy) regulations" }
        ],
        "Security and Privacy": [
            { id: 18, name: "Assess internal capabilities and decide between an internal cybersecurity team or outsourcing to a specialist firm to improve cost-effectiveness" },
            { id: 19, name: "In-line with the recent changed way of working (e.g. more remote), adjust risk management for sharing and accessing data within and outside our organization for data privacy and security regulations and requirements" },
            { id: 20, name: "Identify the most critical digital assets and business growth drivers and ensure that security and privacy strategies are fully aimed at protecting and supporting these" },
            { id: 21, name: "Define procedures for limiting the impact of a security breach. Security architecture to support a ‘defense-in-depth’ strategy which provides layers of deterrence that limits attackers’ ability to access critical digital assets" },
            { id: 22, name: "Implement an “active defense” approach that proactively leverages internal and external threat intelligence" },
            { id: 23, name: "Ensure that the security function recognizes and regularly evaluates the ‘ecosystem’ including reliance on third parties and business partners" },
            { id: 24, name: "Ensure IT/Cyber security is viewed as a critical component of the company’s overall risk management. Organize accountability at Top management level (strong “tone at the top”)" },
            { id: 25, name: "Enhance security culture across the entire business" }
        ],
        "Technology in a Digital Context": [
            { id: 26, name: "Drive technology enablement and automation (e.g. robotic process automation) in people-intensive activities within the organization to increase resilience, efficiency, and drive down cost" },
            { id: 27, name: "Drive competitive advantage and create new revenue streams by investing in IoT, AI, 5G, AR/VR, blockchain, and/or cloud" },
            { id: 28, name: "Retrain and upskill the workforce across the organization to help them work with new technologies" },
            { id: 29, name: "Determine required capabilities of the IT function and which specialist skills to leverage from business partners" },
            { id: 30, name: "Increase experimentation with newer technology vendors or service providers and/or start-ups" },
            { id: 31, name: "Ensure that digital platforms are fully established and integrated and driven from an externally focused perspective (not an internal perspective and not relying on legacy platform infrastructure)" },
            { id: 32, name: "Introduce greater mobility to access key applications" },
            { id: 33, name: "Make the cloud the standard deployment choice for new applications. Ensure quick retirement of legacy technology platforms" },
            { id: 34, name: "Ensure active participation of tax function and CFO in assessing tax implications of moving from an on-premise to cloud model" }
        ]
    };

    const [selectedActions, setSelectedActions] = useState([]);
    const [openCategory, setOpenCategory] = useState(null);
    const [checkedValue, setCheckedValue] = useState({});
    const uid = localStorage.getItem("user_id");

    const handleCategoryToggle = (category) => {
        setOpenCategory(openCategory === category ? null : category);
    };

    // Function to fetch data from API
    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_growth_technology}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === "success") {
                const jsonData = response.data.data.technology_action;

                if (jsonData) {
                    try {
                        const data = JSON.parse(jsonData);

                        const parseCategoryData = (categoryData) => {
                            return categoryData ? categoryData.split(',') : [];
                        };

                        const actions = {
                            "Digital Enterprise": parseCategoryData(data.digital_enterprice || ''),
                            "Data and Analytics": parseCategoryData(data.data_analytics || ''),
                            "Security and Privacy": parseCategoryData(data.security_privacy || ''),
                            "Technology in a Digital Context": parseCategoryData(data.technology_digital_context || ''),
                        };

                        setCheckedValue(actions);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No data found for technology_action.');
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch technology actions error', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatSelectedActions = (selectedActions) => {
        const categories = {
            "Digital Enterprise": "digital_enterprice[]",
            "Data and Analytics": "data_analytics[]",
            "Security and Privacy": "security_privacy[]",
            "Technology in a Digital Context": "technology_digital_context[]"
        };
    
   
        const formatted = Object.keys(categories).reduce((acc, category) => {
            const selectedData = selectedActions
                .filter(action => action.category === category)
                .map(action => {
                    const actionDetails = actionsList[category].find(a => a.id === action.id);
                    return actionDetails ? actionDetails.name : null;  // Directly returning the action name
                })
                .filter(action => action !== null);
    
            acc[categories[category]] = selectedData;
            return acc;
        }, {});
    
    
        return formatted;
    };

    const handleAddToActions = async (category, action, index) => {
        try {
            const newCheckedValue = { ...checkedValue };
            const isSelected = newCheckedValue[category]?.includes(action.id);
            if (isSelected) {
                newCheckedValue[category] = newCheckedValue[category].filter(id => id !== action.id);
            } else {
                newCheckedValue[category] = [...(newCheckedValue[category] || []), action.id];
            }
            setCheckedValue(newCheckedValue);
    
            const updatedActions = Object.keys(newCheckedValue).flatMap(cat =>
                newCheckedValue[cat].map(id => ({ category: cat, id }))
            );
            setSelectedActions(updatedActions);
    
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
    
           const formattedActions = formatSelectedActions(updatedActions);
    
            Object.entries(formattedActions).forEach(([key, value]) => {
                if (value.length > 0) {
                    value.forEach(item => formDataToSend.append(key, item));
                } else {
                    formDataToSend.append(key, '[]');  
                }
            });
    
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_technology_action}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Submit technology actions error', error);
            toast.error("An error occurred while updating data.");
        }
    };

    const renderActions = (category) => {
        const categoryActions = actionsList[category] || [];

        return categoryActions.map((action, index) => (
            <Col xs={12} md={6} lg={3} key={action.id} className="mb-3">
                <div className="action-card p-3 border">
                    <p>{action.name}</p>
                    <Button 
                        variant={checkedValue[category]?.includes(action.id) ? "success" : "primary"} 
                        onClick={() => handleAddToActions(category, action, index)}
                        className="d-flex align-items-center mt-3 p-2"
                    >
                        {checkedValue[category]?.includes(action.id) ? <FaCheck className="me-2" /> : <FaPlus className="me-2" />}
                        {checkedValue[category]?.includes(action.id) ? "Selected" : "Add to Actions"}
                    </Button>
                </div>
            </Col>
        ));
    };

    return (
        <div className="col-sm-12">
        <div className="card mt-4">
            <div className="card-header">
                <div className='justify-content-between d-flex '>
                    <div className="">
                        <h5>Actions to Raise the Maturity Level for Technology</h5>
                    </div>
                    <div>
                        <Link className='backto-ondriver' to="/growthframeworks/founderdriver">Back to the Founder OS Drivers of Growth</Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card-body">
                    <p>To help you achieve your goals we have created actions learned from our insights of working with market leaders. Add ones that you feel are best for your business.</p>
                    <Accordion defaultActiveKey="0" activeKey={openCategory}>
                {Object.keys(actionsList).map(category => (
                    <Accordion.Item eventKey={category} key={category}>
                        <Accordion.Header onClick={() => handleCategoryToggle(category)}>
                        <div className='d-flex justify-content-between align-items-center w-100'>
              <h5 className='mb-0 fw-bold'>{category}</h5>
              <p className='mb-0 text-primary'>View Actions</p></div>
                           
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                {openCategory === category && renderActions(category)}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
        </div>
        </div>
        </div>
    );
};

export default TechnologyActions;
