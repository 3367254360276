import React, { useState, useEffect } from "react";

import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Rocks = () => {
    const uid = localStorage.getItem("user_id");


    const [formData, setFormData] = useState({
        RocksPagename: "",
        Rockssingulart: "",
        Rocksplural: "",
        DepartmentalNonLaadunhteam: "",
        CompanyLeadershipteamt: "",


    });





    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.RocksPagename);
        formDataToSend.append('rock_singular', formData.Rockssingulart);
        formDataToSend.append('rock_prular', formData.Rocksplural);
        formDataToSend.append('company', formData.CompanyLeadershipteamt);
        formDataToSend.append('departmental', formData.DepartmentalNonLaadunhteam);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_rocks}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_terminology_rocks}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    RocksPagename: data.page_name || "",
                    Rockssingulart: data.rock_singular || "",
                    Rocksplural: data.rock_prular || "",
                    CompanyLeadershipteamt: data.company || "",
                    DepartmentalNonLaadunhteam: data.departmental || "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="icofont icofont-island-alt mx-2"></i>Rocks</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Measurabile (singular)"
                                    name="RocksPagename"
                                    value={formData.RocksPagename}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Rocks (singulart)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Measurabile (singular)"
                                    name="Rockssingulart"
                                    value={formData.Rockssingulart}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Rocks (plural)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Rocks (plural)"
                                    name="Rocksplural"
                                    value={formData.Rocksplural}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Company (Leadership Teamt)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Company (Leadership Teamt)"
                                    name="CompanyLeadershipteamt"
                                    value={formData.CompanyLeadershipteamt}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Departmental (Non-Laadunh Team)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Departmental (Non-Laadunh Team)"
                                    name="DepartmentalNonLaadunhteam"
                                    value={formData.DepartmentalNonLaadunhteam}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Rocks;