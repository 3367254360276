import React, { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import API from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from "lodash";

const PersonalHistoriesExercise = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    remembered: "",
    parents: "",
    colleagues: "",
    spouse: "",
    commitment: "",
    challenging: "",
  });

  const saveDataDebounced = useCallback(
    debounce(async (updatedFormData) => {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('remembered', updatedFormData.remembered);
      formDataToSend.append('parents', updatedFormData.parents);
      formDataToSend.append('colleagues', updatedFormData.colleagues);
      formDataToSend.append('challenging', updatedFormData.challenging);
      formDataToSend.append('spouse', updatedFormData.spouse);
      formDataToSend.append('commitment', updatedFormData.commitment);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.personal_histories_insert}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Profile update failed.");
      }
    }, 2000),
    []
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    saveDataDebounced(updatedFormData);
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.personal_histories_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const fetchedData = response.data.data[0];

        setFormData({
          remembered: fetchedData.remembered || "",
          parents: fetchedData.parents || "",
          colleagues: fetchedData.colleagues || "",
          spouse: fetchedData.spouse || "",
          commitment: fetchedData.commitment || "",
          challenging: fetchedData.challenging || "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch data error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container fluid>
      <div className="card">
        <div className="card-header">
          <h5>Personal Histories Exercise</h5>
        </div>
        <form className="form theme-form">
          <div className="card-body">
            <div className="form-group">
              <label><b>
                Have all team members complete (in writing or mentally) the
                following questions about themselves.
              </b></label>
              <textarea
                className="form-control"
                name="remembered"
                value={formData.remembered}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>

            <div className="form-group">
              <label><b>
                Where did you grow up?
              </b></label>
              <textarea
                className="form-control"
                name="parents"
                value={formData.parents}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>

            <div className="form-group">
              <label><b>
                How many siblings do you have and where do you fall in that
                order?
              </b></label>
              <textarea
                className="form-control"
                name="colleagues"
                value={formData.colleagues}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>

            <div className="form-group">
              <label><b>
                Please describe a unique or interesting challenge or experience
                from your childhood.
              </b></label>
              <textarea
                className="form-control"
                name="challenging"
                value={formData.challenging}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>

            <div className="form-group">
              <label><b>
                Ask each team member to share their responses to the questions.
              </b></label>
              <textarea
                className="form-control"
                name="spouse"
                value={formData.spouse}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>

            <div className="form-group">
              <label><b>
                After all team members have shared, ask the group to discuss
                what they learned about one another that they didn't already
                know.
              </b></label>
              <textarea
                className="form-control"
                name="commitment"
                value={formData.commitment}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default PersonalHistoriesExercise;
