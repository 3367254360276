import React, { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import API from "../../../utils";
import { toast } from "react-toastify";
import { useEffect } from "react";

const CashAccelerationForm = () => {
    const uid = localStorage.getItem('user_id');

    const [formData, setFormData] = useState({
        salesCycle: Array(5).fill({ way: "", shortenCycle: false, eliminateMistakes: false, improveBusinessModel: false }),
        productionCycle: Array(5).fill({ way: "", shortenCycle: false, eliminateMistakes: false, improveBusinessModel: false }),
        deliveryCycle: Array(5).fill({ way: "", shortenCycle: false, eliminateMistakes: false, improveBusinessModel: false }),
        billingCycle: Array(5).fill({ way: "", shortenCycle: false, eliminateMistakes: false, improveBusinessModel: false }),
    });

    const handleInputChange = (section, index, field, value) => {
        const updatedSection = formData[section].map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setFormData({ ...formData, [section]: updatedSection });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        // Helper function to append cycle data
        const appendCycleData = (sectionPrefix, sectionData) => {
            sectionData.forEach((item, index) => {
                formDataToSend.append(`${sectionPrefix}_way_to_improve[]`, item.way);
                formDataToSend.append(`${sectionPrefix}_shorten_cycle_times[]`, item.shortenCycle ? "1" : "0");
                formDataToSend.append(`${sectionPrefix}_eliminate_mistakes[]`, item.eliminateMistakes ? "1" : "0");
                formDataToSend.append(`${sectionPrefix}_improve_business_model[]`, item.improveBusinessModel ? "1" : "0");
            });
        };

        // Append data for each cycle
        appendCycleData('sales_cycle', formData.salesCycle);
        appendCycleData('inventory_cycle', formData.productionCycle);
        appendCycleData('delivery_cycle', formData.deliveryCycle);
        appendCycleData('payment_cycle', formData.billingCycle);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.insert_cycle_datas}`,
                formDataToSend,
                {
                    headers: {
                        'Authorization': `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data', // Important for FormData
                    },
                }
            );

            if (response.data.status === 'success') {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
            //toast.error('Failed to fetch data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_cycle_data_by_user_id}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {

                const data = response.data.data;

                // Process data for each cycle
                const parseCycleData = (cycleData) => {
                    return JSON.parse(cycleData).map(item => ({
                        way: item.way_to_improve || "",
                        shortenCycle: item.shorten_cycle_times === "1",
                        eliminateMistakes: item.eliminate_mistakes === "1",
                        improveBusinessModel: item.improve_business_model === "1"
                    }));
                };

                setFormData({
                    salesCycle: parseCycleData(data.sales_cycle),
                    productionCycle: parseCycleData(data.inventory_cycle),
                    deliveryCycle: parseCycleData(data.delivery_cycle),
                    billingCycle: parseCycleData(data.payment_cycle),
                });
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
            //toast.error('Failed to fetch data. Please try again later.');
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <FormSection
                title="Ways to improve your Sales Cycle"
                section="salesCycle"
                data={formData.salesCycle}
                onInputChange={handleInputChange}
            />
            <FormSection
                title="Ways to improve your Make/Production & Inventory Cycle"
                section="productionCycle"
                data={formData.productionCycle}
                onInputChange={handleInputChange}
            />
            <FormSection
                title="Ways to improve your Delivery Cycle"
                section="deliveryCycle"
                data={formData.deliveryCycle}
                onInputChange={handleInputChange}
            />
            <FormSection
                title="Ways to improve your Billing & Payment Cycle"
                section="billingCycle"
                data={formData.billingCycle}
                onInputChange={handleInputChange}
            />

        </form>
    );
};

const FormSection = ({ title, section, data, onInputChange }) => {
    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-12 align-items-center d-flex">
                        <h5>{title}</h5>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div style={styles.formSection} className="life-planing-div mt-4">
                    <table style={styles.table} className="life-planing-table  table table-bordered">
                        <thead>
                            <tr>
                                <th style={styles.th}>#</th>
                                <th style={styles.th}>Ways to improve</th>
                                <th style={styles.th}>Shorten Cycle Times</th>
                                <th style={styles.th}>Eliminate Mistakes</th>
                                <th style={styles.th}>Improve Business Model & P&L</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td style={styles.td}>{index + 1}</td>
                                    <td style={styles.td}>
                                        <div className="form-group">
                                            <div className="cash-flow-table">
                                                <input
                                                    className="form-control  cash-form-control"
                                                    type="text"
                                                    placeholder={`Way ${index + 1}`}
                                                    value={item.way}
                                                    onChange={(e) =>
                                                        onInputChange(section, index, "way", e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td style={styles.td}>
                                        <input
                                            className="checkbox_animated"
                                            type="checkbox"
                                            checked={item.shortenCycle}
                                            onChange={(e) =>
                                                onInputChange(section, index, "shortenCycle", e.target.checked)
                                            }
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <input
                                            className="checkbox_animated"
                                            type="checkbox"
                                            checked={item.eliminateMistakes}
                                            onChange={(e) =>
                                                onInputChange(section, index, "eliminateMistakes", e.target.checked)
                                            }
                                        />
                                    </td>
                                    <td style={styles.td}>
                                        <input
                                            className="checkbox_animated"
                                            type="checkbox"
                                            checked={item.improveBusinessModel}
                                            onChange={(e) =>
                                                onInputChange(section, index, "improveBusinessModel", e.target.checked)
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='card-footer'>
                <div className='d-flex justify-content-end'>
                    <Button type="submit" variant="success" className=" float-end">Save</Button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    header: {
        textAlign: "center",
        color: "#1e8449",
    },

    subHeader: {
        textAlign: "center",
        color: "#1e8449",
    },
    cycleContainer: {
        display: "flex",
        justifyContent: "space-around",
        margin: "20px 0",
    },
    cycleItem: {
        padding: "10px",
        backgroundColor: "#f2f3f4",
        border: "1px solid #ddd",
        // width: "20%",
        textAlign: "center",
    },
    formSection: {
        margin: "20px 0",
    },
    sectionHeader: {
        backgroundColor: "#1e8449",
        color: "white",
        padding: "10px",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    th: {
        border: "1px solid #ddd",
        padding: "8px",
        backgroundColor: "#101040",
        color: "#fff",
    },
    td: {
        border: "1px solid #ddd",
        padding: "8px",
    },
    input: {
        width: "100%",
    },
};

export default CashAccelerationForm;
