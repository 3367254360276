import React, { useEffect, useState } from "react";

import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MyNinty = () => {
    const uid = localStorage.getItem("user_id");

    const [formData, setFormData] = useState({
        pageName: "",
        teamsTodos: "",
        privateTodos: "",
        milestones: "",
        scorecard: "",
        Todoscreacted: "",
        rocksstatuses: "",
        Measurables: "",

    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('page_name', formData.pageName);
        formDataToSend.append('team_to', formData.teamsTodos);
        formDataToSend.append('private_to', formData.privateTodos);
        formDataToSend.append('Rocks_Milestones', formData.milestones);
        formDataToSend.append('Scorecard', formData.scorecard);
        formDataToSend.append('To_Dos_Creacted_Last_90Days', formData.Todoscreacted);
        formDataToSend.append('Rocks_Statuses', formData.rocksstatuses);
        formDataToSend.append('Measurables', formData.Measurables);

        try {
          const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_update_90my_life}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
      
          if (response.data.status === "success") {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
            console.error("Error during task submission:", error);

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
          const response = await axios.post(
            `${API.BASE_URL}${API.ENDPOINTS.get_my_90life_data}`,
            formDataToSend,
            {
              headers: {
                Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
            }
          );
    
          if (response.data.status === 'success') {
     
            const data = response.data.info;
            setFormData({
                pageName: data.page_name || "",
                teamsTodos: data.team_to || "",
                privateTodos: data.private_to || "",
                milestones: data.Rocks_Milestones || "",
                scorecard: data.Scorecard || "",
                Todoscreacted: data.To_Dos_Creacted_Last_90Days || "",
                rocksstatuses: data.Rocks_Statuses || "",
                Measurables: data.Measurables || "",
            });
          } else {
            toast.error(response.data.message);
                }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="icofont icofont-compass mx-2"></i>My 90</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Page Name</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Page Name"
                                    name="pageName"
                                    value={formData.pageName}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Teams To-Dos </b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Teams To-Dos"
                                    name="teamsTodos"
                                    value={formData.teamsTodos}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Privart To-Dos</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Privart To-Dos"
                                    name="privateTodos"
                                    value={formData.privateTodos}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Rocks & milestones</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Rocks & milestones"
                                    name="milestones"
                                    value={formData.milestones}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>


                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Scorecard</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Scorecard"
                                    name="scorecard"
                                    value={formData.scorecard}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>To-Dos Creacted Last 90Days</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter To-Dos Creacted Last 90Days"
                                    name="Todoscreacted"
                                    value={formData.Todoscreacted}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Rocks Statuses</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Rocks Statuses"
                                    name="rocksstatuses"
                                    value={formData.rocksstatuses}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Measurables</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Measurables"
                                    name="Measurables"
                                    value={formData.Measurables}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default MyNinty;