import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from 'axios';
import API from "../../../utils";

const Integrations = () => {
    const [isGoogleChecked, setIsGoogleChecked] = useState(false);
    const [isOfficeChecked, setIsOfficeChecked] = useState(false);
    const uid = localStorage.getItem('user_id');

    useEffect(() => {
        const googleTaskState = localStorage.getItem('googleTask');
        const officeTaskState = localStorage.getItem('officeTask');
        if (googleTaskState) {
            setIsGoogleChecked(JSON.parse(googleTaskState));
        }
        if (officeTaskState) {
            setIsOfficeChecked(JSON.parse(officeTaskState));
        }
    }, []);

    const handleGoogleCheckboxChange = async (event) => {
        const isChecked = event.target.checked;
        setIsGoogleChecked(isChecked);
        localStorage.setItem('googleTask', JSON.stringify(isChecked));

        if (isChecked) {
            const oauthWindow = window.open(`${API.BASE_URL}${API.ENDPOINTS.oauth}/${uid}`);

            const oauthInterval = setInterval(() => {
                if (oauthWindow.closed) {
                    clearInterval(oauthInterval);
                    const oauthSuccess = localStorage.getItem('oauthSuccess');

                    if (oauthSuccess === 'true') {
                        localStorage.setItem('googleTask', JSON.stringify(true));
                    } else {
                        setIsGoogleChecked(false);
                        localStorage.setItem('googleTask', JSON.stringify(false));
                    }
                }
            }, 2000);
        } else {
            localStorage.setItem('googleTask', JSON.stringify(false));
        }
    };

    const handleOfficeCheckboxChange = async (event) => {
        const isChecked = event.target.checked;
        setIsOfficeChecked(isChecked);
        localStorage.setItem('officeTask', JSON.stringify(isChecked));

        if (isChecked) {
            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.oauth}`, {
                    provider: 'office365',
                }, {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                });

                if (response.data.status === 'success') {
                    toast.success('Office 365 integration successful!');
                    localStorage.setItem('officeTask', JSON.stringify(true));
                    window.open('https://your-office365-integration-url.com', '_blank');
                } else {
                    toast.error(response.data.message || 'Office 365 integration failed.');
                    setIsOfficeChecked(false);
                    localStorage.setItem('officeTask', JSON.stringify(false));
                }
            } catch (error) {
                console.error('Error during Office 365 integration:', error);
                setIsOfficeChecked(false);
                localStorage.setItem('officeTask', JSON.stringify(false));
            }
        } else {
            localStorage.setItem('officeTask', JSON.stringify(false));
        }
    };

    return (
        <form>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Google</h5>
                </div>
                <div className="card-body text-center">
                    <div className="card-body">
                        <div className="form-group google-main-row row">
                            <div className="col-sm-3 google-col-main">
                                <i className="fa fa-google"></i>
                            </div>
                            <div className="col-sm-6">
                                <label className="col-form-label">You are set as the owner of an item.</label>
                                <label className="col-form-label">Includes Rocks, Milestones, To-Dos, and issues</label>
                            </div>
                            <div className="col-sm-3 google-col-checkbox">
                                <label className="switch">
                                    <input type="checkbox" checked={isGoogleChecked} onChange={handleGoogleCheckboxChange} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0">Office 365</h5>
                </div>
                <div className="card-body text-center">
                    <div className="card-body">
                        <div className="form-group google-main-row row">
                            <div className="col-sm-3 google-col-main">
                                <i className="fa fa-windows"></i>
                            </div>
                            <div className="col-sm-6">
                                <label className="col-form-label">You are set as the owner of an item.</label>
                                <label className="col-form-label">Includes Rocks, Milestones, To-Dos, and issues</label>
                            </div>
                            <div className="col-sm-3 google-col-checkbox">
                                <label className="switch">
                                    <input type="checkbox" checked={isOfficeChecked} onChange={handleOfficeCheckboxChange} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Integrations;
