import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';

const Process = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [gratitudeEntries, setGratitudeEntries] = useState([]);
  const user_id = localStorage.getItem('user_id');
  const [formData, setFormData] = useState({
    name: "",
    details: "",
    type: "",
    Documented: '',
  });
  const [updatedTask, setUpdatedTask] = useState(null);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_businesstools_process}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {


        const formattedData = response.data.info.map((entry) => ({
          id: entry.id,
          name: entry.name,
          details: entry.details,
          type: entry.type,
          Documented: entry.Documented,
          created_at: entry.created_at,
        }));

        const sortedTasks = formattedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setGratitudeEntries(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error during task fetching:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('details', formData.details);
    formDataToSend.append('Documented', formData.Documented);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_businesstools_process}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Process Added Successfully");
        setFormData({
          name: "",
          details: "",
          type: "",
          Documented: '',
        });
        fetchTasks();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting data.");
    }
  };

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('process_id', task.id);
      formDataToSend.append('field', task.field);
      formDataToSend.append('text', task.value);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.edit_businesstools_process}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchTasks();
          toast.success("Process Updating Successfully");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, id) => {
    const { value } = e.target;
    const updatedOutcomes = [...gratitudeEntries];
    updatedOutcomes[index][field] = value;
    setUpdatedTask({ field, value, id });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.name,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <input
            type="text"
            name="name"
            value={row.name}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "name", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Process Department",
      selector: (row) => row.type,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <select
            value={row.type}
            className="form-control"
            name="type"
            onChange={(e) => handleEditInputChange(e, index, "type", row.id)}
          >
            <option value="">Select Process Type</option>
            <option value="Sales">Sales</option>
            <option value="Finance">Finance</option>
            <option value="Marketing">Marketing</option>
            <option value="Partnerships">Partnerships</option>
            <option value="Compliance">Compliance</option>
            <option value="Operations">Operations</option>
            <option value="Information Technology">Information Technology</option>
            <option value="Human Resources">Human Resources</option>
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Process Documented in Founders OS Workflow Mapping?",
      selector: (row) => row.Documented,
      cell: (row, index) =>
      (
        <div className="business-tool-table">

          <select
            value={row.Documented}
            className="form-control"
            name="Documented"
            onChange={(e) => handleEditInputChange(e, index, "Documented", row.id)}
          >
            <option value="">Select Type</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="In-Progress">In-Progress</option>
          </select>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Notes",
      selector: (row) => row.details,
      cell: (row, index) =>
      (
        <div className="business-tool-table">
          <textarea
            type="text"
            name="details"
            value={row.details}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "details", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = gratitudeEntries.filter(entry =>
    (entry.name || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.details || "").toLowerCase().includes((searchQuery || "").toLowerCase())
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const isSaveButtonEnabled = () => formData.Documented.trim() !== '';

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="align-items-center justify-content-between d-flex">
                    <h5>Process</h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form>
                  <div className='row'>
                    <div className="form-group mb-3 col-lg-6 col-md-6">
                      <label><b>Process Name</b></label>
                      <input type='text' className="form-control" name="name" value={formData.name} onChange={handleInputChange} placeholder='Process Name' />
                    </div>
                    <div className="form-group mb-3 col-lg-6 col-md-6">
                      <label><b>Process Department</b></label>
                      <select className="form-control" onChange={handleInputChange} name="type" value={formData.type}>
                        <option value="">Select Process Type</option>
                        <option value="Sales">Sales</option>
                        <option value="Finance">Finance</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Partnerships">Partnerships</option>
                        <option value="Compliance">Compliance</option>
                        <option value="Operations">Operations</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Human Resources">Human Resources</option>

                      </select>
                    </div>

                    <div className="form-group mt-2 mb-3 col-lg-6 col-md-6">
                      <label><b>Process Documented in Founders OS Workflow Mapping?</b></label>
                      <select
                        className='form-control'
                        name="Documented"
                        onChange={handleInputChange}
                        value={formData.Documented}
                      >
                        <option value="">Select Type</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="In-Progress">In-Progress</option>
                      </select>
                    </div>
                    <div className="mb-3 form-group col-lg-6 col-md-6">
                      <label><b>Notes</b></label>
                      <textarea type='text' name="details" className="form-control" value={formData.details} onChange={handleInputChange} placeholder='Notes' />
                    </div>
                  </div>
                  <div className="form-group text-end ">
                    <Button variant="success"
                      disabled={!isSaveButtonEnabled()}
                      onClick={handleSave}>Save</Button>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <div className="form-group text-end mb-3">
                      <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div>
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    pagination
                    persistTableHead={true}
                    responsive
                    className='keyrelationship-table'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
