import React, { useCallback, useState } from "react";
import { Container, Button } from "react-bootstrap";
import axios from 'axios';
import { toast } from 'react-toastify'; // Ensure you have react-toastify installed for toast notifications
import API from "../../../utils";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import debounce from 'lodash/debounce';

const PeopleAnalyzer = () => {
    const uid = localStorage.getItem("user_id");
    const [formState, setFormState] = useState({
        date_of_analysis: '',
        team_member_name: '',
        onboarding1: '',
        coaching1: '',
        learning1: '',
        onboarding2: '',
        coaching2: '',
        learning2: '',
        anymore2: '',
        onboarding3: '',
        coaching3: '',
    });

    const handleRadioChange = (event) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.people_analyze_get}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                // const fetchedData = response.data.data || []; // Ensure it's an array
                const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                setData(Array.isArray(sortedTasks) ? sortedTasks : []);
                // Handle parsed data...
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch data error', error);
            //toast.error("Failed to fetch data.");
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('team_member_name', formState.team_member_name);
        formDataToSend.append('date_of_analysis', formState.date_of_analysis);
        formDataToSend.append('onboarding1', formState.onboarding1);
        formDataToSend.append('coaching1', formState.coaching1);
        formDataToSend.append('learning1', formState.learning1);
        formDataToSend.append('onboarding2', formState.onboarding2);
        formDataToSend.append('coaching2', formState.coaching2);
        formDataToSend.append('learning2', formState.learning2);
        formDataToSend.append('anymore2', formState.anymore2);
        formDataToSend.append('onboarding3', formState.onboarding3);
        formDataToSend.append('coaching3', formState.coaching3);
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.people_analyze_insert}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    }
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
                setFormState({
                    date_of_analysis: '',
                    team_member_name: '',
                    onboarding1: '',
                    coaching1: '',
                    learning1: '',
                    onboarding2: '',
                    coaching2: '',
                    learning2: '',
                    anymore2: '',
                    onboarding3: '',
                    coaching3: '',
                });
                window.location.reload();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error details:", error);
            toast.error("Profile update failed.");
        }
    };



    const getPerformanceRating = (row) => {
        const getIt = JSON.parse(row.get_it);
        const wantIt = JSON.parse(row.want_it);
        const haveCapacity = JSON.parse(row.have_the_capacity);

        const countYes = (obj) => Object.values(obj).filter(value => value === 'yes').length;

        const totalYes = countYes(getIt) + countYes(wantIt) + countYes(haveCapacity);

        if (totalYes === 9) {
            return 'A Player';
        } else if (totalYes === 8) {
            return 'B Player';
        } else if (totalYes === 7) {
            return 'Average at Best';
        } else {
            return 'Not a Good Fit for the Organization';
        }
    };


    const [data, setData] = useState([]);

    const [updatedTask, setUpdatedTask] = useState(null);

    const debouncedUpdateTask = useCallback(
        debounce(async (task) => {
            const formDataToSend = new FormData();
            formDataToSend.append('id', task.id);
            formDataToSend.append('field', task.field);
            formDataToSend.append('text', task.value);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.people_analyze_update}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === "success") {
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while updating data.");
            }
        }, 2000),
        []
    );

    const handleEditInputChange = (e, index, field, id) => {
        const { value } = e.target;
        const updatedOutcomes = [...data];
        updatedOutcomes[index][field] = value;

        setData(updatedOutcomes);
        setUpdatedTask({ field, value, id });
    };


    useEffect(() => {
        if (updatedTask) {
            debouncedUpdateTask(updatedTask);
        }
    }, [updatedTask, debouncedUpdateTask]);

    const columns = [
        {
            name: 'Team Member Name',
            selector: row => row.team_member_name,
            cell: (row, index) => (
                <div className="repoting-table">
                    <input
                        type="text"
                        value={row.team_member_name}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "team_member_name", row.id)}
                    />
                </div>
            ),
            sortable: true,
            center: true,
        },
        {
            name: 'Date of Analysis',
            selector: row => row.date_of_analysis,
            cell: (row, index) => (
                <div className="task-table">
                    <input
                        type="date"
                        value={row.date_of_analysis}
                        className="form-control"
                        onChange={(e) => handleEditInputChange(e, index, "date_of_analysis", row.id)}
                    />
                </div>
            ),
            sortable: true,
            center: true,
        },
        {
            name: 'Performance Rating',
            selector: row => row.id,
            cell: (row) => {
                const performanceRating = getPerformanceRating(row);
                return (
                    <div>
                        {performanceRating}
                    </div>
                );
            },
            sortable: true,
            center: true,
        }
    ];

    return (
        <Container fluid={true}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center">
                                    <h5>People Analyze</h5>
                                </div>
                            </div>
                        </div>
                        <form className="form theme-form" onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <label>Team Member's Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Team Member's Name"
                                                name="team_member_name"
                                                onChange={(e) => setFormState({ ...formState, team_member_name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <label>Date of Analysis</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="date_of_analysis"
                                                onChange={(e) => setFormState({ ...formState, date_of_analysis: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="life-wheel-tabel">
                                            <table className="table table-bordernone">
                                                <thead>
                                                    <tr>
                                                        <th>Get it?</th>
                                                        <th>Yes</th>
                                                        <th>No</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Do all of the neurons in his or her brain connect when you explain and they do the job?</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="onboarding1"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="onboarding1"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Does he or she get all of the ins and outs of the position?</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="coaching1"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="coaching1"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Not everyone gets it, and it's not a bad thing. He or she just needs to be in a different seat</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="learning1"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="learning1"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="life-wheel-tabel">
                                            <table className="table table-bordernone">
                                                <thead>
                                                    <tr>
                                                        <th>Want it?</th>
                                                        <th>Yes</th>
                                                        <th>No</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Does he or she genuinely want to do the job?</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="onboarding2"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="onboarding2"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Does he or she get up every morning wanting to do it?</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="coaching2"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="coaching2"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>You can't pay, motivate, force, or beg him or her to want it. He or she has to want it on their own</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="learning2"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="learning2"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sometimes people get it and have the capacity, but just don't want it anymore.</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="anymore2"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="anymore2"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="life-wheel-tabel">
                                            <table className="table table-bordernone">
                                                <thead>
                                                    <tr>
                                                        <th>Have the capacity?</th>
                                                        <th>Yes</th>
                                                        <th>No</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Does he or she have the mental, physical, spiritual, time, knowledge and emotional capacity to do the job?</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="onboarding3"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="onboarding3"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Sometimes this one is negotiable. While not getting it or wanting it are deal-killers, a problem of capacity can be solved, although rarely. If you believe the person can gain the capacity and you are willing to invest the time, resources, and energy for him or her to do so, do it. It is just that most growing organizations don't have the luxury of waiting one to three years for someone to gain the capacity and need the seat filled completely now</td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="coaching3"
                                                                    value="yes"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="justify-content-around">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="radio"
                                                                    className="radio_animated"
                                                                    name="coaching3"
                                                                    value="no"
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className=" mb-2">
                                    <Button type="submit" variant="success" className="float-end mb-2">Save</Button>
                                </div>
                            </div>

                            <div className="m-2">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    className='taskapp-tables'
                                    pagination
                                    persistTableHead={true}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default PeopleAnalyzer;
