import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Modal } from "react-bootstrap";
import ReactPlayer from 'react-player';
import API from "../../utils";
import axios from "axios";

const LiveTraining = () => {
    const [show, setShow] = useState(false);
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [episodes, setEpisodes] = useState([]);

    const handleShow = (episode) => {
        setSelectedEpisode(episode);
        setShow(true);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_contents}`,
                null,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const formattedEpisodes = response.data.data.map((item) => ({
                    episodeNumber: item.btn_text,
                    title: item.title,
                    imageUrl: item.image,
                    description: item.description,
                    videoUrl: `https://foundersos.co/api/public/uploads/videos/${item.video}`
                }));
                setEpisodes(formattedEpisodes);
            }
        } catch (error) {
            console.error('Fetch data error:', error);
        }
    };

    const handleClose = () => setShow(false);

    return (
        <Container className="episodes-grid">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6 align-items-center">
                            <h5>Founders OS University</h5>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <Row>
                        {episodes.map((episode, index) => (
                            <Col key={index} sm={12} md={6} lg={4}>
                                <Card className="episode-card mb-4" onClick={() => handleShow(episode)}>
                                    <img className="card-img" variant="top" src={episode.imageUrl} alt="" />
                                    <div className="card-body-livetreing">
                                        <Card.Title className="livetraining-Title">{episode.title}</Card.Title>
                                        <Card.Text className="livetraining-taxt">{episode.description}</Card.Text>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

            {/* Modal for Video Playback */}
            <Modal show={show} onHide={handleClose} centered size="lg">
                {selectedEpisode && (
                    <div className="video-container">
                        <ReactPlayer
                            url={selectedEpisode.videoUrl}
                            width="100%"
                            height="400px"
                            playing={true}
                            controls={true}
                        />
                    </div>
                )}
            </Modal>
        </Container>
    );
};

export default LiveTraining;
