import React, { useState, Fragment, useEffect, useLayoutEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import UserMenu from './userMenu';
import Notification from './notification';
import { Bell, MoreHorizontal, User } from 'react-feather';
import { FaAlignLeft } from 'react-icons/fa';
import Indexmytask from '../../my-tasks';
import Indexfounders from '../../foundersos';
import IndexStrategicgoals from '../../strategic-goals';
import IndexMeeting from '../../meetings';
import SopsSales from '../../sops';
import Growth from '../../Growth-Frameworks';
import People from '../../People/Index';
import IndexStrategyDevelopment from '../../strategy-development';
import Indexinviting from '../../invite-teammates/inviteindex';
import Indexreporting from '../../reporting/reportingindex';
import CashIndex from '../../cashunderstanding/cashindex';
import IndexProject from '../../projects';
import Indexbusinessmodal from "../../business-model/Indexbusinessmodal";
import Indexbusinesstools from '../../business-tools/Indexbusinesstools';
import IndexCorporate from "../../corporate/index";
import API from '../../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import IndexClients from '../../clients/clientindex';
import Indexsupport from '../../learningandsupport/indexsupport';
import Adminmenu from './adminmenu';

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [headerbar, setHeaderbar] = useState(true);
  const location = useLocation();
  const role = sessionStorage.getItem("role");

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 991) {
        setSidebar(false);
        document.querySelector(".page-main-header").classList.add('open');
        document.querySelector(".page-sidebar").classList.add('open');
      } else {
        setSidebar(true);
        document.querySelector(".page-main-header").classList.remove('open');
        document.querySelector(".page-sidebar").classList.remove('open');
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setSidebar(false);
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    }
  }, []);

  function openCloseSidebar() {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }

  const [total_notification_count, setNotificationCount] = useState(0);
  const uid = localStorage.getItem('user_id');

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  const fetchNotificationCount = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.deshboard_notification}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        setNotificationCount(response.data.total_notification_count); // Set notification count
      } else {
        const errorMessage = response.data ? response.data.message : 'Unknown error';
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Fetch notifications error', error);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      sessionStorage.setItem("role", "true");
    } else {
      sessionStorage.setItem("role", "false");
    }
  }, [location.pathname]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/customerSupport/frequentlyAskedQuestion');
  };

  const [formData, setFormData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', localStorage.getItem('user_id'));

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_company_info}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );
      if (response.data.status === "success") {
        const companiesData = response.data.data.map(company => ({
          Company: company.company_name,
        }));
        setFormData(companiesData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none col-auto">
            <div className="logo-wrapper">
              <Link to={`/`}>
                <span className='header-logo'>Founders O/S</span>
              </Link>
            </div>
          </div>

          <div className="mobile-sidebar d-lg-none d-block col-auto">
            <div className="flex-grow-1 text-end switch-sm">
              <label className="switch"><b>
                <p onClick={() => openCloseSidebar()}>
                  <FaAlignLeft className='align-right-left-icon' />
                </p>
              </b></label>
            </div>
          </div>

          <div className="nav-right manidisply col ">
            {role === "true" ? (
              <li className='d-flex justify-content-end'>
                <Adminmenu />
              </li>
            ) : (
              <>
                <ul className={`nav-menus justify-content-lg-end justify-content-md-center ${headerbar ? '' : 'open'}`}>
                  {
                    ['/'].includes(location.pathname) && (
                      <>
                        <li>
                          <div className="header-tagline">
                            <p>“Your Roadmap to a $1 Million Business & Beyond”</p>
                          </div>
                        </li>
                      </>
                    )
                  }

                  {['/my-task/task', '/my-task/continuous-learning', '/my-task/start-stop-contineu', '/my-task/achievments', '/my-task/power-hour-planner'].includes(location.pathname) && (
                    <li>
                      <Indexmytask />
                    </li>
                  )}
                  {['/sales', '/founderos/marketing', '/founderos/finance', '/founderos/human-resources', '/founderos/information-technology', '/founderos/partnership', '/founderos/founderosadmin', '/founderos/operations'].includes(location.pathname) && (
                    <li>
                      <Indexfounders />
                    </li>
                  )}

                  {['/meeting/meetings', '/meeting/agenda', '/meeting/meeting-type'].includes(location.pathname) && (
                    <li>
                      <IndexMeeting />
                    </li>
                  )}

                  {['/Strategic-goal/one-year-goal', '/Strategic-goal/ninty-days-sprints', '/Strategic-goal/parking-lot-items', '/Strategic-goal/years-aspirations'].includes(location.pathname) && (
                    <li>
                      <IndexStrategicgoals />
                    </li>
                  )}
                  {/* {['/execution/personal-selling'].includes(location.pathname) && (
                <li><IndexExecution /></li>
              )} */}

                  {['/sops/sales', '/sops/finance', '/sops/marketing', '/sops/Partnerships', '/sops/risks', '/sops/humanresources', '/sops/operations', '/sops/informationtechnology'].includes(location.pathname) && (
                    <li>
                      <SopsSales />
                    </li>
                  )}

                  {['/growthframeworks/foundersOSchecklist', '/growthframeworks/founderdriver'].includes(location.pathname) && (
                    <li>
                      <Growth />
                    </li>
                  )}

                  {['/people/disc', '/people/fivedisfunctions', '/people/face', '/people/growyoyrteam', '/people/jobscorecard', '/people/loveloathe', '/people/oppp', '/people/organizationalcheckup', '/people/peopleanalyzer', '/people/personalhistoriesexercise', '/people/talentassessment', '/people/team'].includes(location.pathname) && (
                    <li>
                      <People />
                    </li>
                  )}

                  {['/strategy-deveopment/seven-strata', '/strategy-deveopment/vision-summry', '/strategy-deveopment/brand-promise', '/strategy-deveopment/swot', '/strategy-deveopment/customer-avtar', '/strategy-deveopment/purpose-review', '/strategy-deveopment/sandbox'].includes(location.pathname) && (
                    <li>
                      <IndexStrategyDevelopment />
                    </li>
                  )}


                  {['/project/project-name', '/project/project-gannt'].includes(location.pathname) && (
                    <li>
                      <IndexProject />
                    </li>
                  )}

                  {['/clients-name', '/clients-templeat'].includes(location.pathname) && (
                    <li>
                      <IndexClients />
                    </li>
                  )}
                  {/* '/goalscorecard', */}
                  {['/people/pace', '/Monthly', '/reporting-data'].includes(location.pathname) && (
                    <li>
                      <Indexreporting />
                    </li>
                  )}

                  {['/labor-efficiency-ratio', '/cash-acceleration-strategies', '/cash-compensation', '/the-power-of-one', '/fundability-improvement', '/value-Improvement-from-recurringrevenue', '/cashglossary'].includes(location.pathname) && (
                    <li>
                      <CashIndex />
                    </li>
                  )}

                  {['/invite-to-Company', '/add-to-directory'].includes(location.pathname) && (
                    <li>
                      <Indexinviting />
                    </li>
                  )}

                  {['/businessmodal/businessmodalcanvas', '/businessmodal/onepagepersonalplan'].includes(location.pathname) && (
                    <li>
                      <Indexbusinessmodal />
                    </li>
                  )}

                  {['/businesstool/people', '/businesstool/process', '/businesstool/technology'].includes(location.pathname) && (
                    <li>
                      <Indexbusinesstools />
                    </li>
                  )}

                  {['/corporate/corevalues', '/corporate/foundationstory', '/corporate/foundationalpillars', '/corporate/mission', '/corporate/productsservices', '/corporate/uniquevalueproposition', '/corporate/vision', '/corporate/branding-guidelines', '/corporate/market-strategy'].includes(location.pathname) && (
                    <li>
                      <IndexCorporate />
                    </li>
                  )}


                  {['/customerSupport/frequentlyAskedQuestion', '/customerSupport/foudersosUniversity', '/customerSupport/contactSupport',].includes(location.pathname) && (
                    <li>
                      <Indexsupport />
                    </li>
                  )}

                  <div className='d-flex Notificationdsplaylg justify-content-lg-end'>
                    <li>
                      {formData.length > 0 && formData[0].Company ? (
                        formData[0].Company.trim() ? (  // Check if Company is not empty or only spaces
                          <span
                            className="p-2 me-2 text-bg-danger text-end personalinfo-teem-detaile"
                          >
                            {formData[0].Company}
                          </span>
                        ) : null
                      ) : (
                        <></>
                      )}
                      <li>

                        <Notification onClick={handleNavigate} className="Notification-icon" />
                        <User onClick={handleNavigate} className="Notification-icon" />
                      </li>
                    </li>
                    <li className="onhover-dropdown">
                      <Notification />
                      <Bell className='Notification-icon' />
                      <span className="badge rounded-pill notificationicon pull-right">{total_notification_count}</span>
                    </li>
                    <UserMenu />
                  </div>
                </ul>
              </>
            )}

            <div className="d-lg-none  mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal /></div>
            <div className='d-lg-none Notificationdsplay'>
              <li className="onhover-dropdown">
                <Notification />
                <Bell className='Notification-icon' />
                <span className="badge rounded-pill notificationicon pull-right">{total_notification_count}</span>
              </li>
              <UserMenu />
            </div>
          </div>


          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">{"Your search turned up 0 results. This most likely means the backend is down, yikes!"}</div>
          </script>
        </div>
      </div>
    </Fragment >
  )
};

export default Header;
