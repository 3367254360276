import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';

const ContinuousLearning = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({
    area_focus: "",
    source_info: "",
    desire_insight: "",
    deadline: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [contineousLearning, setContineousLearning] = useState([]);

  const user_id = localStorage.getItem('user_id');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setValidationErrors({});
  };

  const handleCancel = () => {
    setFormData({
      area_focus: "",
      source_info: "",
      desire_insight: "",
      deadline: "",
    });
    setValidationErrors({});
    setIsPopupOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const errorsCopy = { ...validationErrors };
    switch (name) {
      case "area_focus":
        errorsCopy.area_focus = value.trim() ? "" : "This field is required";
        break;
      case "source_info":
        errorsCopy.source_info = value.trim() ? "" : "This field is required";
        break;
      case "desire_insight":
        errorsCopy.desire_insight = value.trim() ? "" : "This field is required";
        break;
      case "deadline":
        errorsCopy.deadline = value.trim() ? "" : "This field is required";
        break;
      default:
        break;
    }
    setValidationErrors(errorsCopy);
  };

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', user_id);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_continue_learning}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setContineousLearning(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);
    formDataToSend.append('area_focus', formData.area_focus);
    formDataToSend.append('source_info', formData.source_info);
    formDataToSend.append('desire_insight', formData.desire_insight);
    formDataToSend.append('deadline', formData.deadline);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_continous_learning}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Continous Learning Added Successfully");
        fetchData();
        handleCancel();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting data.");
    }
  };

  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('continous_learning_id', task.id);
      formDataToSend.append('field', task.field);
      formDataToSend.append('text', task.value);

      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_continous_learning}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchData();
          toast.success("Continous Learning Updating Successfully");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 2000),
    []
  );

  const handleEditInputChange = (e, index, field, id) => {
    const { value } = e.target;
    const updatedOutcomes = [...contineousLearning];
    updatedOutcomes[index][field] = value;

    setUpdatedTask({ field, value, id });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = contineousLearning.filter(entry =>
    (entry.area_focus || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.source_info || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.desire_insight || "").toLowerCase().includes((searchQuery || "").toLowerCase()) ||
    (entry.deadline || "").toLowerCase().includes((searchQuery || "").toLowerCase())
  );

  const columns = [
    {
      name: 'Area Of Focus',
      selector: row => row.area_focus,
      sortable: true,
      cell: (row, index) => (
        <div className="continouselernig-table">
          <input
            name="area_focus"
            type="text"
            value={row.area_focus}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "area_focus", row.id)}
          />
        </div>
      ),
    },
    {
      name: 'Sources Of Info',
      selector: row => row.source_info,
      sortable: true,
      cell: (row, index) => (
        <div className="continouselernig-table">

          <input
            name="source_info"
            type="text"
            value={row.source_info}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "source_info", row.id)}
          />
        </div>
      ),
    },
    {
      name: 'Desired Insight',
      selector: row => row.desire_insight,
      sortable: true,
      cell: (row, index) => (
        <div className="continouselernig-table">
          <input
            name="desire_insight"
            type="text"
            value={row.desire_insight}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "desire_insight", row.id)}
          />
        </div>
      ),
    },
    {
      name: 'Deadline',
      selector: row => row.deadline,
      sortable: true,
      cell: (row, index) => (
        <div className="continouselernig-table">
          <input
            name="deadline"
            type="date"
            value={row.deadline}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "deadline", row.id)}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>
      ),
    },
  ];


  const isSaveButtonEnabled = () => formData.area_focus.trim() !== '';

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">

              <div className="row">
                <div className=" align-items-center justify-content-between d-flex">
                  <h5>Continous Learning</h5>
                </div>
              </div>

            </div>


            <div className="card-body">
              <form>
                <div className="row">
                  <div className="form-group mb-2 col-lg-4">
                    <label><b>Area Of Focus:</b></label>
                    <input
                      placeholder="Area Of Focus"
                      type="text"
                      className="form-control"
                      name="area_focus"
                      value={formData.area_focus}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group mb-2 col-lg-4">
                    <label><b>Sources Of Info:</b></label>
                    <input
                      type="text"
                      placeholder="Sources Of Info"
                      className="form-control"
                      name="source_info"
                      value={formData.source_info}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group mb-2 col-lg-4">
                    <label><b>Deadline:</b></label>
                    <input
                      type="date"
                      value={formData.deadline}
                      onChange={handleInputChange}
                      className="form-control"
                      name="deadline"
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                  <div className="form-group mb-2 col-lg-4">
                    <label><b>Desired Insight:</b></label>
                    <textarea
                      placeholder="Enter Desired Insight"
                      className="form-control"
                      name="desire_insight"
                      value={formData.desire_insight}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end align-content-center">
                  <Button
                    variant="success"
                    disabled={!isSaveButtonEnabled()}
                    className="mb-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </form>

              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="form-group mb-2 text-end  ">
                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                  </div>
                </div>
              </div>
              <div >
                <DataTable
                  columns={columns}
                  data={filteredEntries}
                  persistTableHead={true}
                  pagination
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <Modal show={isPopupOpen} onHide={togglePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Area of Focus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-2">
            <label><b>Area Of Focus:</b></label>
            <input
              placeholder="Area Of Focus"
              type="text"
              className={`form-control ${validationErrors.area_focus ? "is-invalid" : ""}`}
              name="area_focus"
              onChange={handleInputChange}
            />
            {validationErrors.area_focus && <div className="invalid-feedback">{validationErrors.area_focus}</div>}
          </div>
          <div className="form-group mb-2">
            <label><b>Sources Of Info:</b></label>
            <textarea
              placeholder="Sources Of Info"
              className={`form-control ${validationErrors.source_info ? "is-invalid" : ""}`}
              name="source_info"
              onChange={handleInputChange}
            />
            {validationErrors.source_info && <div className="invalid-feedback">{validationErrors.source_info}</div>}
          </div>
          <div className="form-group mb-2">
            <label><b>Desired Insight:</b></label>
            <textarea
              placeholder="desire_insight Insight"
              className={`form-control ${validationErrors.desire_insight ? "is-invalid" : ""}`}
              name="desire_insight"
              onChange={handleInputChange}
            />
            {validationErrors.desire_insight && <div className="invalid-feedback">{validationErrors.desire_insight}</div>}
          </div>
          <div className="form-group mb-2">
            <label><b>Deadline:</b></label>
            <input type="date"
              selected={formData.deadline}
              onChange={handleInputChange}
              className={`form-control ${validationErrors.deadline ? "is-invalid" : ""}`}
              name="deadline"
            />
            {validationErrors.deadline && <div className="invalid-feedback">{validationErrors.deadline}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContinuousLearning;
