import React, { useEffect } from "react";
import { Container, Row, Nav } from "react-bootstrap";
import { FaHome, FaArchive, FaBeer } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { CiDiscount1 } from "react-icons/ci";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isSubscribedAccountant } from "../../utils";

const People = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };

    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/billings");
        window.location.reload();
      }
    }, []);
    
    return (
        <>
            <Container fluid>
                <Row className="mx-lg-3">
                    <Slider {...settings}>
                       

                        <div className="slider-item">
                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} to="/people/face" exact>
                                        <FaHome className="me-1" /> Functionality Accountability Chart
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="slider-item">
                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/fivedisfunctions">
                                        <TbReportSearch className="me-1" /> Five Disfunctions of a Team
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="slider-item">
                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/talentassessment">
                                        <FaArchive className="me-1" /> Talent Assessment Chart
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="slider-item">

                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/jobscorecard">
                                        <FaBeer className="me-1" /> Job Scorecard
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="slider-item">

                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/disc">
                                        <CiDiscount1 className="me-1" /> DiSC Quick Profile Exercise
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>

                        </div>
                        <div className="slider-item">

                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/team">
                                        <i className="fa fa-steam-square"></i>Team Effectiveness Exercise
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>

                        </div>
                       
                        <div className="slider-item">

                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/loveloathe">
                                        <i className="fa fa-picture-o"></i>Love-Hate Exercise
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="slider-item">


                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/oppp">
                                        <i className="fa fa-tags"></i>One-Page Personal Plan (OPPP)
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="slider-item">

                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/growyoyrteam">
                                        <i className="fa fa-weixin"></i>Grow Your Team
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        <div className="slider-item">
                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/organizationalcheckup">
                                        <FaArchive className="me-1" />Organizational Checkup
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        <div className="slider-item">
                            <Nav variant="pills" className="border-tab nav-primary">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} exact to="/people/peopleanalyzer">
                                        <FaHome className="me-1" />People Analyzer
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Slider>
                </Row>
            </Container>
        </>
    );
};

export default People;
