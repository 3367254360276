import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import API from '../../../utils';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MypuffLoader from '../../common/MypuffLoader';
import Autocomplete from 'react-google-autocomplete';
import { debounce } from 'lodash';
import Preferences from './preferences';

const PersonalInformation = () => {
  const uid = localStorage.getItem("user_id");
  const [formData, setFormData] = useState({
    biography: "",
    state: '',
    country: '',
    user_name: '',
    title: '',
    street: '',
    city: '',
    pinCode: '',
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [profile, setProfileImg] = useState(null);
  const [forms, setForms] = useState([{ id: Date.now(), name: '', relationship: '', phoneNumber: '' }]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const storedProfileImg = localStorage.getItem('profile');
    if (storedProfileImg) {
      setProfileImg(storedProfileImg);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  const role = localStorage.getItem("role")

  const fetchData = async () => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    if (role) {
      formDataToSend.append('role', role);

    }

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_user_details}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );
      if (response.data.status === "success") {
        const userData = response.data.user_data;
        const emergencyContacts = response.data.emergencontactdata;

        setProfileImg(userData.profile);
        setFormData({
          user_name: userData.user_name || '',
          title: userData.job_title || '',
          street: userData.street || '',
          city: userData.city || '',
          pinCode: userData.zip_code || '',
        });

        setForms(emergencyContacts.map(contact => ({
          id: contact.id,
          name: contact.name,
          relationship: contact.relationship,
          phoneNumber: contact.number,
        })));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedApiCall = useCallback(debounce(async (updatedData) => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    if (profile && typeof profile === 'object') {
      formDataToSend.append('profile', profile);
    }
    formDataToSend.append('biography', updatedData.biography);
    formDataToSend.append('last_name', updatedData.last_name);
    formDataToSend.append('state', updatedData.state);

    formDataToSend.append('user_name', updatedData.user_name);
    formDataToSend.append('jobtitle', updatedData.title);
    formDataToSend.append('street', updatedData.street);
    formDataToSend.append('city', updatedData.city);
    formDataToSend.append('zipcode', updatedData.pinCode);
    formDataToSend.append('country', updatedData.country);
    forms.forEach((form, index) => {
      formDataToSend.append(`name[${index}]`, form.name);
      formDataToSend.append(`relationship[${index}]`, form.relationship);
      formDataToSend.append(`number[${index}]`, form.phoneNumber);
    });

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_profile}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
      });
      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Profile update failed.");
    }
  }, 1500), [forms, profile, uid]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImg(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePlaceSelected = (place) => {
    let street = '';
    let city = '';
    let postalCode = '';

    place.address_components.forEach((component) => {
      const types = component.types;
      if (types.includes('route')) {
        street += component.long_name + ' ';
      }
      if (types.includes('locality')) {
        city = component.long_name;
      }
      if (types.includes('postal_code')) {
        postalCode = component.long_name;
      }
    });

    const updatedData = {
      ...formData,
      street: street.trim(),
      city,
      pinCode: postalCode,
    };
    setFormData(updatedData);
    debouncedApiCall(updatedData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    debouncedApiCall(updatedData);
  };

  return (
    <>

      <form>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h5 className="card-title mb-0">Personal Information</h5>

          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center my-3">
              <MypuffLoader active={loading} />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-4">
                <div className="col-auto">
                  <div className="profile-container">

                    <img
                      className="profile-image mt-4 mx-3 mx-lg-4 p-l-10 rounded-circle"
                      alt="profile"
                      src={imagePreview || profile}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Profile Image</b></label>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          name="profile"
                          className="form-control"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Name</b></label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter A Name"
                          name="user_name"
                          value={formData.user_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Title</b></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter A Title"
                          value={formData.title}
                          name="title"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Street Address</b></label>
                        <Autocomplete
                          apiKey="AIzaSyBbK3ugSWXRAvw3D_XM-zE215vHJMyhOfw"
                          onPlaceSelected={handlePlaceSelected}
                          options={{
                            types: ['geocode'],
                          }}
                          placeholder="Enter Street Address"
                          className="form-control"
                          value={formData.street}
                          onChange={(e) => handleChange({ target: { name: 'street', value: e.target.value } })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>City</b></label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter City"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label className="form-label"><b>Pin Code</b></label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Pin Code"
                          name="pinCode"
                          value={formData.pinCode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>



      <Preferences />
    </>
  );
};

export default PersonalInformation;
