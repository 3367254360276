import React, { useEffect, useState } from "react";
import axios from "axios";
import API from "../../../../utils";
import AddLicenseModal from "../licenceModal/addLicenseModal";
import UpgradePlanModal from "../licenceModal/upgradePlanModal";

const Licences = (fetchData) => {
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [isFreeTrial, setIsFreeTrial] = useState(true);
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    fetchLicenses();
    fetchCardData();
  }, []);

  const fetchLicenses = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_licence}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setLicenses(response.data.data);
        setIsFreeTrial(response.data.data.length === 0);
      }
    } catch (error) {
      console.error("Error fetching licenses:", error);
    }
  };


  const fetchCardData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", user_id);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_user_card_details}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setCardDetails(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching licenses:", error);
    }
  };
  
  const handleAddLicenseModalClose = () => setShowAddLicenseModal(false);

  const handleUpgradePlanModalShow = () => {
    setShowUpgradePlanModal(true);
  };
  const handleUpgradePlanModalClose = () => setShowUpgradePlanModal(false);

  return (
    <>
      <div className="">
        {licenses.length === 0 ? (
          <div className="no-license">
            {/* <p>
              apply first month is free trial! Upgrade your plan now to start adding
              team members, collaborate seamlessly, and expand your business
              with additional licenses. Don’t miss out!
            </p> */}
            <button
              className="btn btn-secondary"
              onClick={handleUpgradePlanModalShow}
            >
              Upgrade Your Plan
            </button>
          </div>
        ) : (
          <ul>
            {/* {licenses.map((license) => (
              <li key={license.id}>
                <h5>
                  <b>Active Plan</b>
                </h5>
                <p>Transaction ID: {license.txn_id}</p>
                <p>Amount: ${license.amount}</p>
                <p>
                  Created At:{" "}
                  {format(new Date(license.created_at), "MMMM d, yyyy")}
                </p>
                <hr />
              </li>
            ))} */}
          </ul>
        )}
        <div>
          {!isFreeTrial && licenses.length > 0 && (
            <button
              className="btn btn-primary"
              onClick={handleUpgradePlanModalShow}
            >
              Upgrade Your Plan
            </button>
          )}
        </div>
      </div>

      {showAddLicenseModal && (
        <AddLicenseModal
          show={showAddLicenseModal}
          handleClose={handleAddLicenseModalClose}
          fetchData={fetchLicenses}
        />
      )}

      {showUpgradePlanModal && (
        <UpgradePlanModal
          show={showUpgradePlanModal}
          handleClose={handleUpgradePlanModalClose}
          fetchData={fetchLicenses}
          cardDetails={cardDetails}
        // fetchDataAll={fetchData}
        />
      )}
    </>
  );
};

export default Licences;
