import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../../../utils";
import { debounce } from "lodash";

const CashCompensation = () => {
    const uid = localStorage.getItem('user_id');
    const [formData, setFormData] = useState({
        Different: "",
        Samenese: "",
        Carota: "",
        GamifyGains: "",
        SharingCaring: "",
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_cash_compensation}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.data[0];
                setFormData({
                    Different: data.competitors || "",
                    Samenese: data.job_levels || "",
                    Carota: data.longer_motivating || "",
                    GamifyGains: data.quarterly_goals || "",
                    SharingCaring: data.retain_your_team || "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        }
    };

    // Debounced API call for handling changes
    const debouncedApiCall = useCallback(
        debounce(async (updatedData) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('competitors', updatedData.Different);
            formDataToSend.append('job_levels', updatedData.Samenese);
            formDataToSend.append('longer_motivating', updatedData.Carota);
            formDataToSend.append('quarterly_goals', updatedData.GamifyGains);
            formDataToSend.append('retain_your_team', updatedData.SharingCaring);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.cash_compensation_insert}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.data.status !== "success") {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error during request:", error);
                toast.error('Failed to update the data. Please try again later.');
            }
        }, 2000),
        []
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value,
            };
            debouncedApiCall(updatedData); // Trigger API call on change
            return updatedData;
        });
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-12 align-items-center d-flex">
                        <h5>Cash Compensation</h5>
                    </div>
                </div>
            </div>
            <form className="form theme-form">
                <div className="card-body">
                    <div className="col-md-12 mb-3">
                        <span className="mb-3"><b>Be Different</b>: Aligning Compensation with Culture and Strategy</span>
                        <div className="form-group mb-0">
                            <label className="form-label"><b>How could you align to brand promises and pay differently than competitors?</b></label>
                            <textarea
                                className="form-control"
                                name="Different"
                                value={formData.Different}
                                onChange={handleChange}
                                rows="4"
                                placeholder="Enter A Compensation with Culture and Strategy"></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <span className="mb-3"><b>Be Different</b>: Aligning Compensation with Culture and Strategy</span>
                        <div className="form-group mb-0">
                            <label className="form-label"><b>How could you create wider pay bands and job levels?</b></label>
                            <textarea
                                className="form-control"
                                name="Samenese"
                                value={formData.Samenese}
                                onChange={handleChange}
                                rows="4"
                                placeholder="Enter wider pay bands and job levels"></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <span className="mb-3"><b>Easy on the Carota</b>: Using Individual Incentives Effectively</span>
                        <div className="form-group mb-0">
                            <label className="form-label"><b>Where are paying bonuses that are expected and no longer motivating?</b></label>
                            <textarea
                                className="form-control"
                                name="Carota"
                                value={formData.Carota}
                                onChange={handleChange}
                                rows="4"
                                placeholder="Enter individual incentives effectively"></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <span className="mb-3"><b>Gamify Gains</b>: Driving Critical Numbers Through Play</span>
                        <div className="form-group mb-0">
                            <label className="form-label"><b>How could you create more play against quarterly goals?</b></label>
                            <textarea
                                className="form-control"
                                name="GamifyGains"
                                value={formData.GamifyGains}
                                onChange={handleChange}
                                rows="4"
                                placeholder="Driving critical numbers through play"></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <span className="mb-3"><b>Sharing Caring</b>: Getting Employees to Think Like Owners</span>
                        <div className="form-group mb-0">
                            <label className="form-label"><b>How might you structure profit sharing to help retain your team?</b></label>
                            <textarea
                                className="form-control"
                                name="SharingCaring"
                                value={formData.SharingCaring}
                                onChange={handleChange}
                                rows="4"
                                placeholder="Getting employees to think like owners"></textarea>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CashCompensation;
