import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../../utils";
import MyLoader from "../common/myloader";
import PaymentDetailsModal from "./paymentmodel/paymentdetails";

const Inviteteammatesmember = () => {
    const user_id = localStorage.getItem("user_id");
    const [forms, setForms] = useState([{ id: null, first_name: '', last_name: '', email: '', role: '', department: '', access: '', password: '', status: "0" }]);
    const [errors, setErrors] = useState([{}]);
    const [updatedForm, setUpdatedForm] = useState(null);
    const uid = localStorage.getItem('user_id');
    const [loading, setLoading] = useState(false);
    const [licenses, setLicenses] = useState(0);
    const [usedLicenses, setUsedLicenses] = useState(1);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [deleteId, setDeleteId] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const addForm = () => {
        if (forms.length >= licenses) {
            toast.error("You cannot add more users than available licenses.");
            return;
        }

        setForms(prevForms => [
            ...prevForms,
            { id: null, first_name: '', last_name: '', email: '', role: '', department: '', access: '', password: '', status: "0" }
        ]);
        setErrors(prevErrors => [...prevErrors, {}]);
    };

    const validateForm = (form) => {
        const errors = {};

        if (!form.first_name?.trim()) {
            errors.first_name = "First Name is required.";
        }
        if (!form.last_name?.trim()) {
            errors.last_name = "Last Name is required.";
        }
        if (!form.email?.trim()) {
            errors.email = "Email is required.";
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
            errors.email = "Invalid email format.";
        }
        if (!form.role?.trim()) {
            errors.role = "Role is required.";
        }
        if (!form.department?.trim()) {
            errors.department = "Department is required.";
        }
        if (!form.access?.trim()) {
            errors.access = "Access is required.";
        }
        if (!form.password?.trim()) {
            errors.password = "Password is required.";
        } else if (form.password.length < 6) {
            errors.password = "Password must be at least 6 characters long.";
        }

        return errors;
    };

    const handleChange = (index, field, value) => {
        const newForms = [...forms];
        if (field === 'status') {
            newForms[index][field] = value ? "1" : "0";
        } else {
            newForms[index][field] = value;
        }
        setForms(newForms);
        setUpdatedForm({ ...newForms[index], index });
    };

    const fetchData = async () => {
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_teammates_invite_to_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                const data = response.data.data || [];
                const formattedForms = data.map(item => ({
                    id: item.teammate_user_id,
                    first_name: item.first_name || '',
                    last_name: item.last_name || '',
                    email: item.email || '',
                    role: item.role || '',
                    department: item.department || '',
                    access: item.access || '',
                    status: item.status,
                }));
                setForms(formattedForms);
                setUsedLicenses(formattedForms.length);
            }
        } catch (error) {
            console.error('Error during data fetching:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchLicenses = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', user_id);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_user_license_count}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {
                setLicenses(response.data.data.no_of_license);
            } else {
                // toast.error("Failed to fetch licenses.");
            }
        } catch (error) {
            console.error('Error fetching licenses:', error);
            // toast.error("An error occurred while fetching licenses.");
        }
    };

    useEffect(() => {
        fetchData();
        fetchLicenses();
    }, []);

    const handlePaymentSubmit = () => {
        setShowPaymentModal(false);
    };

    const deleteForm = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', deleteId);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.delete_teammates_invite_to_company}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during delete request:", error);
            toast.error('Failed to delete the form. Please try again later.');
        } finally {
            setLoading(false);
            setShowDeleteModal(false);
        }
    };

    const handleSubmit = async (index) => {
        const form = forms[index];
        const formErrors = validateForm(form);
        setErrors(prevErrors => {
            const newErrors = [...prevErrors];
            newErrors[index] = formErrors;
            return newErrors;
        });

        if (Object.keys(formErrors).length > 0) {
            toast.error("Please fix the errors in the form.");
            return;
        }

        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('first_name', form.first_name);
        formDataToSend.append('last_name', form.last_name);
        formDataToSend.append('email', form.email);
        formDataToSend.append('role', form.role || '');
        formDataToSend.append('password', form.password || '');
        formDataToSend.append('department', form.department || '');
        formDataToSend.append('access', form.access || '');
        formDataToSend.append('sendmail', form.status);

        try {
            let response;
            if (form.id) {
                formDataToSend.append('teammate_user_id', form.id);
                response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.update_teammates_invite_to_company}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            } else {
                response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_teammates_invite_to_company}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during request:", error);
            toast.error('Failed to invite teammates. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = (index) => {
        setShowDeleteModal(true);
        setDeleteId(index);
    };

    return (
        <MyLoader active={loading}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6 align-items-center d-flex">
                                    <h5>Add Team members</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <span className="m-2"><b>You are currently using {usedLicenses} of {licenses} available licenses.</b></span>
                            </div>
                            {forms.map((form, index) => (
                                <div className="card-body invertmember-addmore my-2 m-3" key={`form-${index}`}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 text-end">
                                                {form.id && (
                                                    <Button
                                                        variant="danger"
                                                        className="invert-member text-end p-0 m-0"
                                                        onClick={() => handleDelete(form.id)}
                                                    >
                                                        <i className="fa fa-times"></i>
                                                    </Button>
                                                )}
                                            </div>

                                            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Confirm Delete</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Are you sure you want to delete this teammate?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="danger" onClick={() => deleteForm()}>
                                                        Delete
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>First Name</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.first_name ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        value={form.first_name}
                                                        onChange={(e) => handleChange(index, 'first_name', e.target.value)}
                                                    />
                                                    {errors[index]?.first_name && <div className="text-danger">{errors[index]?.first_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Last Name</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.last_name ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={form.last_name}
                                                        onChange={(e) => handleChange(index, 'last_name', e.target.value)}
                                                    />
                                                    {errors[index]?.last_name && <div className="text-danger">{errors[index]?.last_name}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Email</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.email ? 'is-invalid' : ''}`}
                                                        type="text"
                                                        placeholder="Enter Email"
                                                        value={form.email}
                                                        onChange={(e) => handleChange(index, 'email', e.target.value)}
                                                    />
                                                    {errors[index]?.email && <div className="text-danger">{errors[index]?.email}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Password</b></label>
                                                    <input
                                                        className={`form-control ${errors[index]?.password ? 'is-invalid' : ''}`}
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        value={form.password}
                                                        onChange={(e) => handleChange(index, 'password', e.target.value)}
                                                    />
                                                    {errors[index]?.password && <div className="text-danger">{errors[index]?.password}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label><b>Title / Role</b></label>
                                                    <Input
                                                        placeholder="Enter Title/Role"
                                                        className={`js-example-disabled-results ${errors[index]?.role ? 'is-invalid' : ''}`}
                                                        name="role"
                                                        value={form.role}
                                                        onChange={(e) => handleChange(index, 'role', e.target.value)}
                                                    />
                                                    {errors[index]?.role && <div className="text-danger">{errors[index]?.role}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Department</b></label>
                                                    <select
                                                        className={`form-control ${errors[index]?.department ? 'is-invalid' : ''}`}
                                                        value={form.department}
                                                        onChange={(e) => handleChange(index, 'department', e.target.value)}
                                                    >
                                                        <option value="">Select Department</option>
                                                        <option value="sales">Sales</option>
                                                        <option value="finance">Finance</option>
                                                        <option value="marketing">Marketing</option>
                                                        <option value="operations">Operations</option>
                                                        <option value="information-technology">Information Technology</option>
                                                        <option value="human-resources">Human Resources</option>
                                                        <option value="virtual-assistant">Virtual Assistant</option>
                                                    </select>
                                                    {errors[index]?.department && <div className="text-danger">{errors[index]?.department}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-12">
                                                <div className="form-group mb-3">
                                                    <label className="form-label"><b>Access</b></label>
                                                    <select
                                                        className={`form-control ${errors[index]?.access ? 'is-invalid' : ''}`}
                                                        value={form.access}
                                                        onChange={(e) => handleChange(index, 'access', e.target.value)}
                                                    >
                                                        <option value="">Select access</option>
                                                        <option value="owner">Owner</option>
                                                        <option value="admin">Admin</option>
                                                        <option value="team-leader">Team Leader</option>
                                                        <option value="team-member">Team Member</option>
                                                    </select>
                                                    {errors[index]?.access && <div className="text-danger">{errors[index]?.access}</div>}
                                                </div>
                                            </div>
                                            <div className='col-md-4 m-2'>
                                                <label className="d-block" htmlFor={`chk-ani-${index}`}>
                                                    <input
                                                        className="checkbox_animated"
                                                        id={`chk-ani-${index}`}
                                                        type="checkbox"
                                                        checked={form.status === "1"}
                                                        onChange={(e) => handleChange(index, 'status', e.target.checked)}
                                                    />
                                                    {" "} Send invite email
                                                </label>
                                            </div>
                                            <div className="col-lg-12 col-md-12 text-end">
                                                <Button variant="success" onClick={() => handleSubmit(index)}>
                                                    {form.id ? "Update Details" : "Save"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <span className="mx-3">Adding users to paid roles beyond your current license count will increase your subscription cost. The card on file will be immediately charged the prorated difference. <b>Have multiple users? Add them now to prevent multiple charges on your credit card.</b></span>
                        <div className="card-footer text-end">
                            <Button type="button" onClick={addForm} className="btn btn-primary">
                                {forms.length === 0 ? "Add People" : "Add More People"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <PaymentDetailsModal
                show={showPaymentModal}
                handleClose={() => setShowPaymentModal(false)}
                handleSubmit={handlePaymentSubmit}
                forms={forms}
                index={selectedIndex}
            />
        </MyLoader>
    );
};

export default Inviteteammatesmember;
