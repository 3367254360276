import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewGoalModal from "./modals/viewGoalModal";
import EditGoalModal from "./modals/editGoalModal";
import DeleteGoalModal from "./modals/deleteGoalModal";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils";
import debounce from "lodash/debounce";

const Parkinglotitems = () => {
  const uid = localStorage.getItem("user_id");
  const [searchQuery, setSearchQuery] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [gratitudeEntries, setGratitudeEntries] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    status: "",
    owner: "",
    // type: "",
    Contactdate: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    owner: "",
    Contactdate: "",
    // type: "",
    status: "",
  });

  const fetchData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", uid);
      formDataToSend.append("category_id", "4");

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_strategic_goal_data}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setHideCompletedTasks(response.data.info[0].hide_is_check);
        let tasks = response.data.info;

        if (hideCompletedTasks) {
          tasks = tasks.filter((task) => task.hide_is_check !== "1");
        }

        const sortedTasks = tasks.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setGratitudeEntries(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Fetch gratitude error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("category_id", "4");
    formDataToSend.append("name_of_goal", formData.name);
    formDataToSend.append("name_of_owner", formData.owner);
    formDataToSend.append("status", formData.status);
    formDataToSend.append("contact_date", formData.Contactdate);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.add_strategic_goal}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Parking Lot Items Added Successfully");
        fetchData();
        setFormData({
          name: "",
          owner: "",
          status: "",
          Contactdate: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };


  const handleDelete = (id) => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name) {
      errors.name = "";
      isValid = false;
    }

    if (!formData.owner) {
      errors.owner = "";
      isValid = false;
    }

    if (!formData.Contactdate) {
      errors.Contactdate = "";
      isValid = false;
    }

    if (!formData.status) {
      errors.status = "";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen);
  };

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white",
  };
  const handleCheckboxChange = async (taskid, isChecked) => {
    const newTasks = gratitudeEntries.map((task) =>
      task.id === taskid
        ? { ...task, is_complete: isChecked ? "1" : "0" }
        : task
    );

    setGratitudeEntries(newTasks);

    const formDataToSend = new FormData();
    formDataToSend.append("strategic_goal_id", taskid);
    formDataToSend.append("is_checked", isChecked ? "1" : "0");

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.check_strategic_goal}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during checkbox status update:", error);
      toast.error("An error occurred while updating the checkbox status.");
    }
  };

  const columns = [
    {
      name: "Completed",
      selector: (row) => row.is_complete,
      cell: (row) => (
        <input
          type="checkbox"
          id={`chk-ani${row.id}`}
          className="checkbox_animated"
          checked={row.status === "Complete"}
          onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
        />
      ),
      sortable: true,
      width: "20%",
    },

    {
      name: "Name Of Parking Lot Item",
      selector: (row) => row.name_of_goal,
      cell: (row, index) => (
        <div className="stragic-golas-table w-100">
          <input
            type="text"
            value={row.name_of_goal}
            className="form-control w-100"
            onChange={(e) =>
              handleEditInputChange(e, index, "name_of_goal", row.id)
            }
          />
        </div>
      ),
      sortable: true,
      width: "80%",
    },
  ];

  const [updatedTask, setUpdatedTask] = useState(null);
  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append("strategic_goal_id", task.goalid);
      formDataToSend.append("filed", task.field);
      formDataToSend.append("text", task.value);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.update_strategic_goal_data}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchData();
          toast.success("Parking Lot Items Updating Successfully");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, goalid) => {
    const { value } = e.target;
    const updatedOutcomes = [...gratitudeEntries];
    updatedOutcomes[index][field] = value;
    setGratitudeEntries(updatedOutcomes);

    setUpdatedTask({ field, value, goalid });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntries = gratitudeEntries.filter(
    (entry) =>
      (entry.name_of_owner || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()) ||
      (entry.status || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()) ||
      (entry.name_of_goal || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()) ||
      (entry.contact_date || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase())
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    let errors = { ...validationErrors };

    switch (name) {
      case "name":
        errors.name = value.trim() ? "" : "This Field is required";
        break;
      case "owner":
        errors.owner = value.trim() ? "" : "This Field is required";
        break;

      default:
        break;
    }

    setValidationErrors(errors);
  };

  const isSaveButtonEnabled = () => formData.name.trim() !== "";

  const handleEditItem = () => {
    if (validateForm()) {
      const updatedEntries = gratitudeEntries.map((entry) => {
        if (entry.id === formData.id) {
          return { ...formData };
        }
        return entry;
      });
      setGratitudeEntries(updatedEntries);
      setShowEditModal(!showEditModal);
    }
  };

  const [hideCompletedTasks, setHideCompletedTasks] = useState(false);

  const handleCheckboxChanges = (e) => {
    const newValue = e.target.checked ? "1" : "0";
    CheckboxChangeSubmit(newValue);
  };

  const CheckboxChangeSubmit = async (hide_is_check) => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", uid);
    formDataToSend.append("category_id", 4);
    formDataToSend.append("hide_is_check", hide_is_check);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.complete_hide_strategic_goals}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
        window.location.reload(); // Reload the page
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <DeleteGoalModal
          show={isDeleteModalOpen}
          onHide={handleDelete}
          handleDelete={() => handleDelete(selectedItem.id)}
        />

        <EditGoalModal
          show={showEditModal}
          onHide={() => setShowEditModal(!showEditModal)}
          formData={formData}
          validationErrors={validationErrors}
          handleInputChange={handleInputChange}
          handleEditItem={handleEditItem}
        />

        {isViewModalOpen && (
          <div className="card-body btn-showcase">
            <ViewGoalModal
              show={isViewModalOpen}
              onHide={toggleViewModal}
              selectedItem={selectedItem}
              thStyle={thStyle}
            />
          </div>
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Parking Lot Items</h5>
                  </div>
                </div>
              </div>
              <form className="m-3">
                <div className="row">
                  <div className="form-group  mb-3 col-lg-4 col-md-6">
                    <label>
                      <b>Name Of Parking Lot Item</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                    ></input>
                  </div>
                  {/* <div className="mb-3 form-group col-lg-4 col-md-6">
                    <label><b>Name Of Owner</b></label>
                    <input type='text' name="owner" className="form-control"
                      value={formData.owner} onChange={handleInputChange} placeholder='Owner'></input>

                  </div> */}
                  {/* <div className="form-group mb-3 col-lg-4 col-md-6">
                    <label><b>Status</b></label>
                    <select className="form-control"
                      onChange={handleInputChange} name="status" value={formData.status}>
                      <option value="">Select Status</option>
                      <option value="New">New</option>
                      <option value="Pending">Pending</option>
                      <option value="Complete">Complete</option>
                    </select>
                  </div> */}
                  {/* <div className=" mb-3 form-group col-lg-4 col-md-6">
                    <label><b>Due Date</b></label>
                    <input type='date'
                      min={new Date().toISOString().split('T')[0]}
                      className="form-control"
                      name="Contactdate"
                      value={formData.Contactdate}
                      onChange={handleInputChange}
                      placeholder='Contactdate'></input>
                  </div> */}
                  <div className="d-flex justify-content-end align-content-center mb-2">
                    <Button
                      variant="success"
                      disabled={!isSaveButtonEnabled()}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
              <div className="card-body">
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="form-check mb-3">
                      <input
                        type="checkbox"
                        className="checkbox_animated"
                        id="hideCompleted"
                        checked={hideCompletedTasks === "1"}
                        onChange={handleCheckboxChanges}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="hideCompleted"
                      >
                        Hide Completed Tasks
                      </label>
                    </div>
                    <div className="form-group text-end mb-3 ">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <DataTable
                    columns={columns}
                    data={filteredEntries}
                    pagination
                    persistTableHead={true}
                    className="keyrelationship-table"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parkinglotitems;
