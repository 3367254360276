import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Tab, TabContent, TabPane, Tabs } from "react-bootstrap";
import { Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from "react-router-dom";
import Accountdeltail from "./tabcontentent/accountDetails";
import Preferences from "./tabcontentent/preferences";
import Companyinformation from "./tabcontentent/companyinformation";
import Notificationsuserprofile from "./tabcontentent/notifications";
import Integrations from "./tabcontentent/Integrations";
import Teams from "./tabcontentent/teams";
import Oureminology from "./tabcontentent/ourerminology";
import Meeting from "./tabcontentent/meeting";
import Billings from "./tabcontentent/billing";
import { FaPlus } from "react-icons/fa";
import ContactuseprofilForms from "./contact";
import Metrics from "./metrics";
import PersonalInformation from "./tabcontentent/personalInformation";
import Social from "./Userinformation/Social";
import { isSubscribedAccountant } from '../../utils';

const UserEdit = () => {
  const [VerticleTab, setVerticleTab] = useState('1');
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/users/billings':
        setVerticleTab('9');
        break;
      default:
        setVerticleTab('1');
        break;
    }
  }, [location.pathname]);


  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const navigate = useNavigate()
  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/billings");
      window.location.reload();
    }
  }, []);

  const role=localStorage.getItem("role")

  return (
    <>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">

            <Col className="user-edit-sublink d-none d-md-flex" sm="2" xs="12">
            <Nav className=" nav-useredit flex-column w-100 nav-pills ">
                <div className="heading-useredit">
                  <h6 className="my-4">User Settings</h6>
                </div>
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')}>Profile</Link>
                </NavItem>
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')}>Security</Link>
                </NavItem>
                {/* <NavItem className="my-2 ">
                  <Link className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>Preferences</Link>
                </NavItem> */}
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')}>Notifications</Link>
                </NavItem>
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')}>Integrations</Link>
                </NavItem>
                {role !== "3" && (
                    <>
                <div className="heading-useredit">
                  <h6 className="my-4">Company Settings</h6>
                </div>

           

             
    
        <NavItem className="my-2">
          <Link
            className={VerticleTab === '6' ? 'active' : ''}
            onClick={() => setVerticleTab('6')}
          >
            Configurations
          </Link>
        </NavItem>
                  
    
  

                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '7' ? 'active' : ''} onClick={() => setVerticleTab('7')}>Team Members</Link>
                </NavItem>

                {/* <NavItem className="my-2 ">
                  <Link className={VerticleTab === '8' ? 'active' : ''} onClick={() => setVerticleTab('8')}>Our Terminology</Link>
                </NavItem> */}
{/* 
                <NavItem className="my-2 ">
                  <Link className={VerticleTab === '9' ? 'active' : ''} onClick={() => setVerticleTab('9')}>Meeting Agendas</Link>
                </NavItem> */}


              
    
       
        <NavItem className="my-2">
          <Link
            className={VerticleTab === '10' ? 'active' : ''}
            onClick={() => setVerticleTab('10')}
          >
            Billing
          </Link>
        </NavItem>
     

      </>
    )}
              </Nav>
            </Col>

            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="my-3 d-md-none">
              <DropdownToggle>
                <FaPlus />
              </DropdownToggle>
              <DropdownMenu className="user-edit-sublink ">
              <DropdownItem>
                  <Nav className="nav nav-useredit flex-column w-100 nav-pills ">
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '1' ? 'active' : ''} onClick={() => setVerticleTab('1')}>Profile</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '2' ? 'active' : ''} onClick={() => setVerticleTab('2')}>Account</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '3' ? 'active' : ''} onClick={() => setVerticleTab('3')}>Preferences</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '4' ? 'active' : ''} onClick={() => setVerticleTab('4')}>Notifications</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '5' ? 'active' : ''} onClick={() => setVerticleTab('5')}>Integrations</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '6' ? 'active' : ''} onClick={() => setVerticleTab('6')}>Configurations</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '7' ? 'active' : ''} onClick={() => setVerticleTab('7')}>Team</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '8' ? 'active' : ''} onClick={() => setVerticleTab('8')}>Our Terminology</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '9' ? 'active' : ''} onClick={() => setVerticleTab('9')}>Meeting</Link>
                    </NavItem>
                    <NavItem className="my-2 ">
                      <Link className={VerticleTab === '10' ? 'active' : ''} onClick={() => setVerticleTab('10')}>Billing</Link>
                    </NavItem>
                  </Nav>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Col sm="10" xs="12">
              <TabContent >
                <TabPane tabId="1" active={VerticleTab === '1'}>
                  <div className="">
                    <Tabs
                      defaultActiveKey="Personal"
                      id="responsive-tab-example"
                      className="border-tab nav-primary col-md-12 col-sm-6 pt-3 mb-3"
                    >
                      <Tab eventKey="Personal" title="Personal">
                        <>
                          <PersonalInformation />
                        </>
                      </Tab>
                      <Tab eventKey="Personality Test Results" title="Personality Test Results">
                        <Metrics />
                      </Tab>
                      <Tab eventKey="Contact" title="Contact">
                        <ContactuseprofilForms />
                      </Tab>
                      <Tab eventKey="Social" title="Social">
                        <Social />
                      </Tab>
                    </Tabs>
                  </div>
                </TabPane>

                <TabPane tabId="2" active={VerticleTab === '2'}>
                  <Accountdeltail />
                </TabPane>
                <TabPane tabId="3" active={VerticleTab === '3'}>
                  <Preferences />
                </TabPane>
                <TabPane tabId="4" active={VerticleTab === '4'}>
                  <Notificationsuserprofile />
                </TabPane>
                <TabPane tabId="5" active={VerticleTab === '5'}>
                  <Integrations />
                </TabPane>
                <TabPane tabId="6" active={VerticleTab === '6'}>
                  <Companyinformation />
                </TabPane>
                <TabPane tabId="7" active={VerticleTab === '7'}>
                  <Teams />
                </TabPane>
                <TabPane tabId="8" active={VerticleTab === '8'}>
                  <Oureminology />
                </TabPane>
                <TabPane tabId="9" active={VerticleTab === '9'}>
                  <Meeting />
                </TabPane>
                
                <TabPane tabId="10" active={VerticleTab === '10'}>
                  <Billings />
                </TabPane>
              </TabContent>
            </Col >
          </div >
        </div >
      </div >
    </>
  );
};

export default UserEdit;
