import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';

import DataTable from 'react-data-table-component';
import API from "../../../utils";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash/debounce';

const LoveLoathe = () => {
  const uid = localStorage.getItem("user_id");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryhateEntries, setSearchQueryHateEntries] = useState("");
  const [loveEntries, setLoveEntries] = useState([]);
  const [hateEntries, setHateEntries] = useState([]);

  const fetchloveData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.love_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setLoveEntries(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch data error', error);
    }
  };

  const fetchloathData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.loathe_get}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const sortedTasks = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setHateEntries(sortedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch data error', error);
    }
  };
  useEffect(() => {
    fetchloveData();
    fetchloathData();
  }, []);

  const [formData, setFormData] = useState({
    taskType: "",
    core: "",
    reason: "",
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('taskType', formData.taskType);
    formDataToSend.append('behavior_style', formData.core);
    formDataToSend.append('reason', formData.reason);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.love_loathe_insert}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        if (formData.taskType === "Love") {
          setLoveEntries([...loveEntries, formData]);
        } else if (formData.taskType === "Loathe") {
          setHateEntries([...hateEntries, formData]);
        }
        setFormData({
          taskType: "",
          core: "",
          reason: "",
        });
        fetchloveData();
        fetchloathData();

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Profile update failed.");
    }
  };



  const [updatedTask, setUpdatedTask] = useState(null);

  const debouncedUpdateTask = useCallback(
    debounce(async (task) => {
      const formDataToSend = new FormData();
      formDataToSend.append('id', task.goalid);
      formDataToSend.append('filed', task.field);
      formDataToSend.append('text', task.value);


      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.love_loathe_Update}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchloveData();
          fetchloathData();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while updating data.");
      }
    }, 1000),
    []
  );

  const handleEditInputChange = (e, index, field, goalid) => {
    const { value } = e.target;
    const updatedOutcomes = [...loveEntries];
    updatedOutcomes[index][field] = value;
    setLoveEntries(updatedOutcomes);

    setUpdatedTask({ field, value, goalid });
  };

  useEffect(() => {
    if (updatedTask) {
      debouncedUpdateTask(updatedTask);
    }
  }, [updatedTask, debouncedUpdateTask]);
  const columns = [

    // {
    //   name: 'Date',
    //   selector: row => row.created_at,
    //   sortable: true,
    // },
    {
      name: 'Name Of Task/Things',
      selector: (row, index) => (
        <div className="businessroles-table w-100">
          <input
            type="text"
            value={row.behavior_style}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "behavior_style", row.id)}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row, index) => (
        <div className="businessroles-table w-100">
          <input
            type="text"
            value={row.reason}
            className="form-control"
            onChange={(e) => handleEditInputChange(e, index, "reason", row.id)}
          />
        </div>
      ),
      sortable: true,
    },

  ];

  const handleSearchChangeloveEntries = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredEntriesloveEntries = loveEntries.filter(entry =>
    (entry.behavior_style ? entry.behavior_style.toLowerCase() : "").includes(searchQuery.toLowerCase()) ||
    (entry.reason ? entry.reason.toLowerCase() : "").includes(searchQuery.toLowerCase())
  );

  const handleSearchChangehateEntries = (e) => {
    setSearchQueryHateEntries(e.target.value);
  };

  const filteredEntrieshateEntries = hateEntries.filter(entry =>
    (entry.behavior_style ? entry.behavior_style.toLowerCase() : "").includes(searchQueryhateEntries.toLowerCase()) ||
    (entry.reason ? entry.reason.toLowerCase() : "").includes(searchQueryhateEntries.toLowerCase())
  );


  const handleEditInputChange1 = (e, index, field, goalid) => {
    const { value } = e.target;
    const updatedOutcomes = [...hateEntries];
    updatedOutcomes[index][field] = value;
    setHateEntries(updatedOutcomes);

    setUpdatedTask({ field, value, goalid });
  };

  const columns1 = [

    // {
    //   name: 'Date',
    //   selector: row => row.created_at,
    //   sortable: true,
    // },
    {
      name: 'Name Of Task/Things',
      selector: (row, index) => (
        <div className="businessroles-table w-100">
          <input
            type="text"
            value={row.behavior_style}
            className="form-control"
            onChange={(e) => handleEditInputChange1(e, index, "behavior_style", row.id)}
          />
        </div>
      ),

      sortable: true,
    },
    {
      name: 'Reason',
      selector: (row, index) => (
        <div className="businessroles-table w-100">
          <input
            type="text"
            value={row.reason}
            className="form-control"
            onChange={(e) => handleEditInputChange1(e, index, "reason", row.id)}
          />
        </div>
      ),

      sortable: true,
    },

  ];

  const isSaveButtonEnabled = () =>
    formData.taskType.trim() !== ''


  return (
    <div>
      <div className="container-fluid">
        <div className=' mb-4 d-flex justify-content-end'>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>Love-Hate Exercise:</h5>

                  </div>
                </div>
              </div>

              <div className="card-body">
                <from>
                  <div className='row'>
                    <div className="form-group mt-3 col-lg-6 col-md-6">
                      <label><b>Select Task Type</b></label>
                      <select
                        onChange={handleInputChange}
                        name="taskType"
                        className="form-control"
                        value={formData.taskType}
                      >
                        <option value="">Select Task Type</option>
                        <option value="Love">Love</option>
                        <option value="Loathe">Loathe</option>
                      </select>

                    </div>

                    <div className="form-group mt-3 col-lg-6 col-md-6">
                      <label><b>Task/Thing name</b></label>
                      <input
                        className="form-control"
                        name="core"
                        onChange={handleInputChange}
                        value={formData.core}

                      />

                    </div>

                    <div className="form-group mt-3 col-lg-6 col-md-6">
                      <label><b>Team Member</b></label>
                      <textarea
                        type="textarea"
                        className="form-control"
                        rows="1"
                        name="reason"
                        onChange={handleInputChange}
                        value={formData.reason}
                      />

                    </div>
                  </div>
                  <div className="mb-2 mt-2 d-flex justify-content-end align-content-center">
                    <Button variant="success" onClick={handleSave} disabled={!isSaveButtonEnabled()} >Save</Button>
                  </div>
                </from>
                <div className="row">
                  <div className="col-md-9 align-items-center">
                    <span className='love-hate'>Task Loved by Team Members</span>
                  </div>
                  <div className="col-md-3 text-end">
                    <div className="form-group text-end mb-3 ">
                      <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChangeloveEntries} />
                    </div>
                  </div>
                  <DataTable className='mt-3 rolmodel-table' persistTableHead={true} columns={columns} data={filteredEntriesloveEntries} pagination />
                </div>

                <div className='mt-4'>

                  <div className='row'>
                    <div className="col-md-9 align-items-center">
                      <span className='love-hate'>Tasks Hated by Team Members</span>
                    </div>
                    <div className="col-md-3 text-end">
                      <div className="form-group text-end mb-3">
                        <input className="form-control" type="text" placeholder="Search..." value={searchQueryhateEntries} onChange={handleSearchChangehateEntries} />
                      </div>
                    </div>
                  </div>

                  <DataTable className='mt-3 rolmodel-table' persistTableHead={true} columns={columns1} data={filteredEntrieshateEntries} pagination

                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};

export default LoveLoathe;
