import React, { useEffect, useState } from "react";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Measurables = () => {
    const uid = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        Goalsingular: "",
        Goalplural: "",
        measurPlural: "",
        measurSingle: "",

    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('measurables_singuler', formData.measurSingle);
        formDataToSend.append('measurables_prular', formData.measurPlural);
        formDataToSend.append('goal_singuler', formData.Goalsingular);
        formDataToSend.append('goal_prular', formData.Goalplural);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_terminology_Measurables}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during task submission:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_my__Measurables_data}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const data = response.data.info;
                setFormData({
                    measurSingle: data.measurables_singuler || "",
                    measurPlural: data.measurables_prular || "",
                    Goalsingular: data.goal_singuler || "",
                    Goalplural: data.goal_prular || "",
                });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during task fetching:', error);
        }
    };
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title mb-0"><i className="icofont icofont-chart-histogram mx-2"></i>Measurables</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Measurabile (singular)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Measurabile (singular)"
                                    name="measurSingle"
                                    value={formData.measurSingle}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Measurables (plural)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Scorecard"
                                    name="measurPlural"
                                    value={formData.measurPlural}
                                    onChange={handleChange}
                                />

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Goal (singular)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Goal (singular)"
                                    name="Goalsingular"
                                    value={formData.Goalsingular}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group mb-2">
                                <label className="col-sm-12 col-form-label"><b>Gools (plural)</b></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Gools (plural)"
                                    name="Goalplural"
                                    value={formData.Goalplural}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-content-center">
                            <button type="submit" className="btn btn-success" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Measurables;