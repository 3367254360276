import React, { useEffect, Fragment, useState } from 'react';
import { ShoppingBag } from 'react-feather';
import { FaUser } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import { Notification } from '../../../constant';
import API from '../../../utils';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [loading, setLoading] = useState(false);
    const uid = localStorage.getItem('user_id');

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.deshboard_notification}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data && response.data.status === 'success') {
                const { today_meetings = [], todays_tasks = [] } = response.data;

                const combinedNotifications = [
                    ...(Array.isArray(today_meetings) ? today_meetings.map(meeting => ({
                        ...meeting,
                        id: meeting.meeting_id,
                        type: 'meeting',
                        title: meeting.meeting_name,
                        message: `Meeting scheduled for ${meeting.meeting_date}`
                    })) : []),
                    ...(Array.isArray(todays_tasks) ? todays_tasks.map(task => ({
                        ...task,
                        id: task.task_id,
                        type: 'task',
                        title: task.title,
                        message: `Task due on ${task.due_date}`
                    })) : [])
                ];

                setNotifications(combinedNotifications);
            } else {
                const errorMessage = response.data ? response.data.message : 'Unknown error';
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Fetch notifications error', error);
        }
    };



    const fetchNotificationDetails = async (notification) => {
        setLoading(true);
        try {
            const formDataToSend = new FormData();
            if (notification.type === 'task') {
                formDataToSend.append('task_id', notification.id);
            } else if (notification.type === 'meeting') {
                formDataToSend.append('meeting_id', notification.id);
            }

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.view_notification}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data && response.data.status === 'success') {
                const { task, meeting } = response.data;
                if (task) {
                    setSelectedNotification({
                        type: 'task',
                        ...task
                    });
                } else if (meeting) {
                    setSelectedNotification({
                        type: 'meeting',
                        ...meeting
                    });
                } else {
                    setSelectedNotification(null);
                }
                fetchData();
            } else {
                const errorMessage = response.data ? response.data.message : 'Unknown error';
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error('Fetch notification details error', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNotificationClick = (notification) => {
        fetchNotificationDetails(notification);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedNotification(null);
        window.location.reload();
    };

    const displayedNotifications = showAll ? notifications : notifications.slice(0, 5);

    const thStyle = {
        border: "1px solid gray",
        textAlign: "left",
        background: "black",
        color: "white"
    };

    const handleShowAllToggle = () => {
        setShowAll(!showAll);
    };

    return (
        <Fragment>
            <div>

                <ul className="notification-dropdown onhover-show-div p-0">
                    <li>{Notification}</li>
                    {displayedNotifications.length > 0 ? (
                        displayedNotifications.map((notification, index) => (
                            <li key={index} onClick={() => handleNotificationClick(notification)}>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <h6 className="mt-0">
                                            <span>
                                                {notification.type === 'task' ? <FaUser /> : <ShoppingBag />}
                                            </span>
                                            {notification.title}
                                        </h6>
                                        <p className="mb-0">{notification.message}</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li>No Notifications</li>
                    )}
                    {notifications.length > 5 && (
                        <li className="txt-dark">
                            <p onClick={handleShowAllToggle}>
                                {showAll ? 'Show Less' : 'See All'}
                            </p>
                        </li>
                    )}
                </ul>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header >
                    <Modal.Title>
                        {selectedNotification && selectedNotification.type === 'task' ? 'Task Details' : 'Meeting Details'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            {selectedNotification && selectedNotification.type === 'task' && (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={thStyle} colSpan={2}>Task Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Title</td>
                                            <td>{selectedNotification.title}</td>
                                        </tr>
                                        <tr>
                                            <td>Description</td>
                                            <td>{selectedNotification.description}</td>
                                        </tr>
                                        <tr>
                                            <td>Due Date</td>
                                            <td>{selectedNotification.due_date}</td>
                                        </tr>
                                        <tr>
                                            <td>Criticality</td>
                                            <td>{selectedNotification.criticality}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            {selectedNotification && selectedNotification.type === 'meeting' && (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={thStyle} colSpan={2}>Meeting Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Meeting Name</td>
                                            <td>{selectedNotification.meeting_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>{selectedNotification.meeting_date}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{selectedNotification.address}</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>{selectedNotification.status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Notifications;
